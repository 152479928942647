import React, { useMemo, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useLocation } from "react-router-dom";
import useTagList from "../../hooks/useTagList";
import { useUIContext } from "../../context/UIContext";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";

export default function TagSetting() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const { setIsLoadingModal } = useUIContext();

  const {
    tagListQuery: { data: tagList },
    createOrUpdateTagList,
  } = useTagList(conditions);

  const [inputTag, setInputTag] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputTag((inputTag) => ({ ...inputTag, [name]: value }));
    // console.log("inputTag", inputTag);
  };

  const addTag = (entity) => {
    if (entity && inputTag[entity] && inputTag[entity].trim().length > 0) {
      // 현재 태그 리스트에서 entity_type과 schoolId가 일치하는 항목 찾기
      const existingTagListIndex = tagList.findIndex(
        (tagItem) =>
          tagItem.entity_type === entity && tagItem.schoolid === schoolId
      );

      let updatedTags = [];
      if (existingTagListIndex !== -1) {
        // 태그 리스트에 이미 존재하는 태그인지 확인
        const existingTags = tagList[existingTagListIndex].tags;
        if (existingTags.includes(inputTag[entity])) {
          console.error("Tag already exists. Skipping addition.");
          return; // 중복되는 태그가 있으면 여기서 함수 종료
        }
        // 새 태그를 기존 태그 리스트에 추가
        updatedTags = [...existingTags, inputTag[entity]];
      } else {
        // 일치하는 entity_type이 없는 경우 새로운 태그로 시작
        updatedTags = [inputTag[entity]];
      }

      // 태그 리스트 업데이트 준비
      const tagToUpdate = {
        schoolid: schoolId,
        entity_type: entity,
        tags: updatedTags,
      };
      setIsLoadingModal(true);
      // 백엔드로 업데이트 요청
      createOrUpdateTagList.mutate(tagToUpdate, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          // 성공적으로 업데이트되면 input 필드 초기화
          setInputTag({ ...inputTag, [entity]: "" });
          // 태그 리스트 상태 업데이트 (옵션)
        },
        onError: (error) => {
          setIsLoadingModal(false);
          console.error(error);
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    }
  };

  const removeTag = (entityType, tagToRemove) => {
    // 현재 엔티티 타입과 일치하며 schoolId가 같은 태그 목록을 찾습니다.
    const existingTagList = tagList.find(
      (tagItem) =>
        tagItem.entity_type === entityType && tagItem.schoolid === schoolId
    );

    if (existingTagList) {
      // 클릭한 태그를 제외한 새로운 태그 목록을 생성합니다.
      const updatedTags = existingTagList.tags.filter(
        (tag) => tag !== tagToRemove
      );

      // 업데이트할 태그 데이터 객체를 준비합니다.
      const tagToUpdate = {
        schoolid: schoolId,
        entity_type: entityType,
        tags: updatedTags,
      };

      setIsLoadingModal(true);
      // 서버에 업데이트 요청을 보냅니다.
      createOrUpdateTagList.mutate(tagToUpdate, {
        onSuccess: () => {
          console.log("Tag successfully updated.");
          setIsLoadingModal(false);
        },
        onError: (error) => {
          console.error("Error updating tag:", error);
          setIsLoadingModal(false);
          alert("Error occurred. Please try again.");
        },
      });
    }
  };

  const renderTags = useMemo(() => {
    return (entityType) => {
      const tagItems = tagList?.filter(
        (tagItem) =>
          tagItem.entity_type === entityType && tagItem.schoolid === schoolId
      )[0]?.tags;

      return tagItems?.map((tag, idx) => (
        <span key={idx} style={{ marginRight: "8px" }}>
          {tag}
          <MdOutlineCancel
            role="button"
            onClick={() => removeTag(entityType, tag)}
          />
        </span>
      ));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList, schoolId]); // 의존성 배열에 tagList와 schoolId를 포함

  return (
    <div className="basicWidth">
      <div className="container p-4">
        <h3 className="fw-bold">
          태그설정 <HelpHyperLink link={"https://youtu.be/ZgKP-UOGDIo"} />
        </h3>
        <p>
          태그 지정이 필요한 페이지의 태그를 지정합니다.
          <br />
          지정하지 않을시 기본 값은 "영적, "지적", 사회적", 신체적" 입니다.
        </p>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>페이지</th>
              <th style={{ width: "20%" }}>태그입력</th>
              <th style={{ width: "10%" }}></th>
              <th style={{ width: "50%" }}>현재태그</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td valign="middle">수업</td>
              <td>
                <input
                  className="form-control"
                  name="lessons"
                  value={inputTag?.lessons || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addTag("lessons"); // Enter 키를 눌렀을 때 handleSubmit 호출
                    }
                  }}
                />
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => addTag("lessons")}
                >
                  추가
                </button>
              </td>
              <td valign="middle">{renderTags("lessons")} </td>
            </tr>
            <tr>
              <td valign="middle">교육활동</td>
              <td>
                <input
                  className="form-control"
                  name="educational_activities"
                  value={inputTag?.educational_activities || ""}
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addTag("educational_activities"); // Enter 키를 눌렀을 때 handleSubmit 호출
                    }
                  }}
                />
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => addTag("educational_activities")}
                >
                  추가
                </button>
              </td>
              <td valign="middle">{renderTags("educational_activities")} </td>
            </tr>
          </tbody>
        </table>
        <p>
          태그는 학교의 교육목표와 핵심가치, 역량 등이 학교 교육과정에서
          운영됨을 나타냅니다.
          <br />
          관리자 설정의 의해 교육과정 보고서, 학생 성장 보고서 등으로 활용될 수
          있습니다.
        </p>
      </div>
    </div>
  );
}
