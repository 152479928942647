export default class AllFormsService {
  constructor(http) {
    this.http = http;
  }

  async findAllAllForms(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/allforms/readall?${queryParams}`, {
      method: "GET",
    });
  }

  // async createAllForms(data) {
  //   return this.http.fetch(`/api/allforms/create`, {
  //     method: "POST",
  //     body: JSON.stringify(data),
  //   });
  // }

  // async updateAllForms(data) {
  //   return this.http.fetch(`/api/allforms/update`, {
  //     method: "PUT",
  //     body: JSON.stringify(data),
  //   });
  // }

  // async deleteAllForms(data) {
  //   return this.http.fetch(`/api/allforms/delete`, {
  //     method: "DELETE",
  //     body: JSON.stringify(data),
  //   });
  // }
}
