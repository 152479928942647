import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SchoolYearPeriod from "./components/SchoolYearPeriod";
import UniDivider from "../../unicomponents/UniDivider";
import TermPeriod from "./components/TermPeriod";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";

export default function PeriodSetting() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  //필터를 위한 모음
  const [schoolYearInfo, setSchoolYearInfo] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: null,
    hierarchyTwoId: null,
    organPathForSort: "/",
  });

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    //초기에 schoolYear 정보가 아직 없을 때, 최신 순서로 schoolYear를 만들어 준다.
    //사실 완전 최신이 아닌 것 같다. 그냥 정렬한 거지. 나중에 누군가 school_year을 2025 이런 식으로 규칙적으로 안 적으면 순서 꼬임
    if (schoolYearInfo.schoolYear === null) {
      if (settingData && settingData.length > 0) {
        if (schoolYearInfo.schoolYear === null) {
          const initialData = settingData.sort(
            (a, b) => b.school_year - a.school_year
          )[0];

          initialSchoolYear = initialData.school_year;
          initialSchoolYearId = initialData.id;
          setSchoolYearInfo((schoolYearInfo) => ({
            ...schoolYearInfo,
            schoolYear: initialSchoolYear,
            schoolYearId: initialSchoolYearId,
          }));
        }
      }
    }
  }, [settingData, schoolYearInfo.schoolYear]);

  const handleSchoolYearDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const organPath = "/" + value;

    if (name === "0") {
      setSchoolYearInfo((schoolYearInfo) => ({
        ...schoolYearInfo,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: null,
        hierarchyTwoId: null,
        organPathForSort: organPath,
      }));
    }

    if (name === "1") {
      setSchoolYearInfo((schoolYearInfo) => ({
        ...schoolYearInfo,
        hierarchyOneId: value === 0 || value === "0" ? null : value,
        hierarchyTwoId: null,
        organPathForSort: organPath,
      }));
    }

    if (name === "2") {
      setSchoolYearInfo((schoolYearInfo) => ({
        ...schoolYearInfo,
        hierarchyTwoId: value === 0 || value === "0" ? null : value,
        organPathForSort: organPath,
      }));
    }
  };
  return (
    <div className="basicWidth mt-4">
      <div className="container">
        <SchoolYearPeriod
          settingData={settingData}
          handleSchoolYearDropdownChange={handleSchoolYearDropdownChange}
          schoolYearInfo={schoolYearInfo}
          setSchoolYearInfo={setSchoolYearInfo}
          schoolId={schoolId}
          conditions={conditions}
        />
        <UniDivider />
        <TermPeriod
          settingData={settingData}
          handleSchoolYearDropdownChange={handleSchoolYearDropdownChange}
          schoolYearInfo={schoolYearInfo}
          setSchoolYearInfo={setSchoolYearInfo}
          schoolId={schoolId}
          conditions={conditions}
        />
      </div>
    </div>
  );
}
