export default class FormSessionsService {
  constructor(http) {
    this.http = http;
  }

  async findAllFormSessions(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/formsessions/readall?${queryParams}`, {
      method: "GET",
    });
  }

  // async createFormSessions(data) {
  //   return this.http.fetch(`/api/allformcomponents/create`, {
  //     method: "POST",
  //     body: JSON.stringify(data),
  //   });
  // }

  // async updateFormSessions(data) {
  //   return this.http.fetch(`/api/allformcomponents/update`, {
  //     method: "PUT",
  //     body: JSON.stringify(data),
  //   });
  // }

  // async deleteFormSessions(data) {
  //   return this.http.fetch(`/api/allformcomponents/delete`, {
  //     method: "DELETE",
  //     body: JSON.stringify(data),
  //   });
  // }
}
