import React, { useEffect, useState } from "react";
import styles from "../css/InsertApprovalMembers.module.css";
import { MdClose } from "react-icons/md";
import useUsers from "../../../../hooks/useUsers";
import useApprovalGroupMembers from "../../../../hooks/useApprovalGroupMembers";
import { useUIContext } from "../../../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";

export default function InsertApprovalMembers({
  isOpen,
  toggleModal,
  groupId,
  schoolId,
  groupName,
}) {
  const [dataToCreate, setDataToCreate] = useState({
    schoolid: schoolId,
    group_id: groupId,
    username: "",
    stage: "",
    can_finalize: 0,
    position: "",
  });

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [groupMembersRows, setGroupMembersRows] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);

  const { handleToastCenterTop, setIsLoadingModal } = useUIContext();
  const queryClient = useQueryClient();

  const {
    usersQuery: { data: userData },
  } = useUsers({ schoolid: schoolId, role: "teacher", status: 1 });

  const {
    approvalGroupMembersQuery: { data: approvalGroupMembersData },
    createApprovalGroupMembers,
    updateApprovalGroupMembers,
    deleteApprovalGroupMembers,
  } = useApprovalGroupMembers({ schoolid: schoolId, group_id: groupId });

  useEffect(() => {
    setGroupMembersRows(approvalGroupMembersData);
  }, [approvalGroupMembersData]);

  if (!isOpen) return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataToCreate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    if (value === "") {
      setFilteredUsers([]);
    } else {
      const filtered = userData.filter((user) => {
        return user.fullname.includes(value);
      });

      setFilteredUsers(filtered);
      if (filtered.length > 0) {
        setDataToCreate((prev) => ({
          ...prev,
          username: filtered[0].username,
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (dataToCreate.stage < 2) {
      handleToastCenterTop(
        "순서를 2이상으로 입력해 주세요. 1번은 담당자 입니다."
      );
      return;
    }

    const isStageExists = groupMembersRows.some(
      (row) => row.stage.toString() === dataToCreate.stage.toString()
    );

    // console.log("groupMembersRows", groupMembersRows);
    // console.log("dataToCreate", dataToCreate);

    if (isStageExists) {
      handleToastCenterTop("이미 해당 순서(단계)가 존재합니다.");
      return;
    }

    dataToCreate.position = dataToCreate.position.trim();
    dataToCreate.group_id = groupId;

    setIsLoadingModal(true);
    createApprovalGroupMembers.mutate(dataToCreate, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded(true);
        setTimeout(() => {
          setIsAdded(false);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleTrChange = (rowIndex, event) => {
    const { value } = event.target;
    const field = event.target.name;
    const updatedRows = groupMembersRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);
    setGroupMembersRows(updatedRows);
  };

  const handleSave = (index) => {
    let dataSet = groupMembersRows[index];

    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      handleToastCenterTop("변경된 사항이 없습니다.");
      console.log("건너뛰기");
      return;
    }

    if (dataSet.stage < 2) {
      handleToastCenterTop("순서는 2 이상이어야 합니다. 1번은 담당자입니다.");
      return;
    }

    if (!dataSet.stage) {
      handleToastCenterTop("순서에 데이터가 있어야 합니다.");
      return;
    }

    // 자기 자신을 제외하고, 다른 항목들 중에서 동일한 stage가 존재하는지 확인
    const isStageExists = groupMembersRows
      .filter((row, i) => i !== index) // 현재 데이터를 제외한 나머지 데이터만 검토
      .some((row) => row.stage.toString() === dataSet.stage.toString());

    if (isStageExists) {
      handleToastCenterTop("이미 해당 순서(단계)가 존재합니다.");
      return;
    }

    // description을 trim
    dataSet.position = dataSet.position.trim();

    // console.log("dataSet", dataSet);
    setIsLoadingModal(true);
    updateApprovalGroupMembers.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setGroupMembersRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    // console.log("delete id", id);
    setIsLoadingModal(true);
    deleteApprovalGroupMembers.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["approvalGroupMembers"]);
        setGroupMembersRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const onCloseModal = () => {
    setDataToCreate({});
    setFilteredUsers([]);
    setSearchKey("");
    setGroupMembersRows([]);
    setConfirmDelete([]);
    toggleModal();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div
          className="p-2 d-flex justify-content-between mb-3"
          style={{ fontSize: "20px", borderBottom: "1px solid" }}
        >
          결재그룹: {groupName}
          <MdClose size={27} type="button" onClick={onCloseModal} />
        </div>
        <div className={styles.modalContent}>
          <form onSubmit={handleSubmit}>
            <div className="row ms-1">
              <div style={{ minWidth: "80px", width: "80px" }}>
                <label htmlFor="stage" className="form-label ms-2 fw-bold">
                  순서
                </label>
                <input
                  type="number"
                  name="stage"
                  className="form-control"
                  required
                  value={dataToCreate.stage || ""}
                  onChange={handleChange}
                />
              </div>

              <div style={{ width: "140px", minWidth: "140px" }}>
                <label htmlFor="fullName" className="form-label ms-2 fw-bold">
                  교직원 이름
                </label>
                <input
                  type="text"
                  name="fullName"
                  className="form-control"
                  required
                  value={searchKey || ""}
                  onChange={handleSearch}
                />
              </div>

              <div style={{ width: "220px", minWidth: "220px" }}>
                <label
                  htmlFor="description"
                  className="form-label ms-2 fw-bold"
                >
                  아이디
                </label>
                <select
                  name="username"
                  className="form-control"
                  onChange={handleChange}
                  required
                  value={dataToCreate.username || ""}
                >
                  {filteredUsers.map((user) => (
                    <option key={user.id} value={user.username}>
                      {user.username}
                    </option>
                  ))}
                  <option value=""></option>
                </select>
              </div>

              <div style={{ width: "130px", minWidth: "130px" }}>
                <label htmlFor="position" className="form-label ms-2 fw-bold">
                  직책
                </label>
                <input
                  type="text"
                  name="position"
                  className="form-control"
                  value={dataToCreate.position || ""}
                  onChange={handleChange}
                />
              </div>

              <div style={{ width: "100px", minWidth: "100px" }}>
                <label
                  htmlFor="description"
                  className="form-label ms-2 fw-bold"
                >
                  전결
                </label>
                <select
                  name="username"
                  className="form-control"
                  onChange={handleChange}
                  required
                  value={dataToCreate.can_finalize || false}
                >
                  <option value={false}>불가</option>
                  <option value={true}>가능</option>
                </select>
              </div>
              <div style={{ width: "90px", minWidth: "90px" }}>
                <div className="mb-2">
                  <br></br>
                </div>
                <button name="submit" className="btn btn-primary">
                  {isAdded ? "완료" : "생성"}
                </button>
              </div>
            </div>
          </form>
          <div className="row m-2 mt-4">
            <table className="table table-bordered">
              <thead className="text-center ">
                <tr className="table-secondary">
                  <th style={{ width: "80px" }}>순서</th>
                  <th style={{ minwidth: "70px" }}>이름</th>
                  <th style={{ minwidth: "120px" }}>아이디</th>
                  <th style={{ width: "120px" }}>직책</th>
                  <th style={{ minwidth: "70px" }}>전결</th>
                  <th style={{ minwidth: "100px" }}>관리</th>
                </tr>
              </thead>
              <tbody>
                {groupMembersRows &&
                  groupMembersRows.map((row, index) => (
                    <tr key={row.id}>
                      <td>
                        <input
                          className="input-like-span"
                          required
                          type="number"
                          name="stage"
                          value={row?.stage}
                          onChange={(e) => handleTrChange(index, e)}
                        />
                      </td>
                      <td>{row?.user?.fullname && row?.user?.fullname}</td>
                      <td>{row?.username && row?.username}</td>
                      <td>
                        <input
                          className="input-like-span"
                          type="text"
                          name="position"
                          value={row?.position}
                          onChange={(e) => handleTrChange(index, e)}
                        />
                      </td>
                      <td>
                        <select
                          className="select-like-span"
                          required
                          name="can_finalize"
                          value={row?.can_finalize}
                          onChange={(e) => handleTrChange(index, e)}
                        >
                          <option value={false}>불가</option>
                          <option value={true}>가능</option>
                        </select>
                      </td>
                      <td className="text-center">
                        <button
                          type="button"
                          onClick={() => handleSave(index)}
                          className="btn btn-sm p-1 me-1 btn-primary"
                        >
                          {savedRows.includes(row.id) ? "저장완료" : "저장"}
                        </button>
                        {confirmDelete.includes(row.id) ? (
                          <>
                            <button
                              type="button"
                              onClick={() => handleDelete(row)}
                              className="btn btn-sm p-1 btn-danger"
                            >
                              확인
                            </button>
                            <button
                              type="button"
                              onClick={() => handleCancelDelete()}
                              className="btn btn-sm p-1 btn-secondary"
                            >
                              취소
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            onClick={() => handleConfirmDelete(row)}
                            className="btn btn-sm p-1 btn-warning"
                          >
                            삭제
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
