import React, { useEffect, useState } from "react";
import DropdownUniqueUsersInDisplayData from "../../../../unicomponents/Dropdowns/DropdownUniqueUsersInDisplayData";
import DropdownTermDate from "../../../../unicomponents/Dropdowns/DropdownTermDate";
import DropdownHierarchyThree from "../../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownHierarchyTwo from "../../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyOne from "../../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownSchoolYear from "../../../../unicomponents/Dropdowns/DropdownSchoolYear";
import useLessons from "../../../../hooks/useLessons";
import useTagList from "../../../../hooks/useTagList";
import { MdOutlineCancel } from "react-icons/md";
import { sumAllNumbersOfObject } from "../../../../util/dealingArrObj";
import { useUIContext } from "../../../../context/UIContext";
import { useAuthContext } from "../../../../context/AuthContext";
import { setTermIdByToday } from "../../../../util/dateRelated";
import { useQueryClient } from "@tanstack/react-query";

export default function CreateUpdateLessonModal({
  settingData,
  termPeriodData,
  userData,
  schoolId,
  lessonSetting,
  setModalOpen,
  configMap,
  creUpdateLesson,
  setCreUpdateLesson,
  createOrUpdate,
  termIdModal,
  setTermIdModal,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tag, setTag] = useState("");
  const [selectedUserModal, setSelectedUserModal] = useState("");
  const [evalAreaWeights, setEvalAreaWeights] = useState([]);
  // const [termIdModal, setTermIdModal] = useState("");

  const { createLesson, updateLesson, deleteLesson } = useLessons({
    schoolid: schoolId,
  });

  const {
    tagListByEntityQuery: { isLoading, data: tagList },
  } = useTagList({
    schoolid: schoolId,
    entity_type: "lessons",
  });

  const { handleToastCenterTop } = useUIContext();

  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (lessonSetting) {
      // console.log("lessonSetting", lessonSetting);
      const eval_area_weights = lessonSetting.filter(
        (setting) => setting.item_code === "evalArea100"
      )[0]?.detailed_setting;
      if (eval_area_weights) {
        setEvalAreaWeights(eval_area_weights);
      }
    }
  }, [configMap, lessonSetting]);

  useEffect(() => {
    if (!creUpdateLesson.evaluation_type && lessonSetting) {
      const thisSetting = lessonSetting.find(
        (r) => r.item_code === "evalMethod"
      );
      if (thisSetting && thisSetting.detailed_setting) {
        const firstOption = thisSetting.detailed_setting[0]; // 첫 번째 설정
        setCreUpdateLesson((prevLesson) => ({
          ...prevLesson,
          evaluation_type: firstOption,
        }));
      }
    }
  }, [creUpdateLesson.evaluation_type, lessonSetting, setCreUpdateLesson]);

  useEffect(() => {
    if (userData && user) {
      if (user.role === "teacher") {
        if (creUpdateLesson?.teachers?.length < 1) {
          setCreUpdateLesson((creUpdateLesson) => ({
            ...creUpdateLesson,
            teachers: [{ tname: user.fullname, username: user.user }],
          }));
          setSelectedUserModal(user.user);
          return;
        }
      }
      setSelectedUserModal(creUpdateLesson?.teachers[0]?.username || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCreUpdateLesson, user, userData]);

  useEffect(() => {
    if (!termIdModal && termIdModal.length === 0) {
      if (termPeriodData && creUpdateLesson?.school_year_id) {
        setTermIdByToday(
          termPeriodData,
          setTermIdModal,
          creUpdateLesson?.school_year_id
        );
      }
    }
    setCreUpdateLesson((prev) => ({
      ...prev,
      term_id: termIdModal,
    }));
  }, [
    termPeriodData,
    termIdModal,
    setCreUpdateLesson,
    creUpdateLesson?.school_year_id,
    setTermIdModal,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      creUpdateLesson.evaluation_type === "100점제" &&
      creUpdateLesson?.eval_area_weights === null
    ) {
      handleToastCenterTop("100점제를 사용할 때는 가중치를 입력해야 합니다.");
      return;
    }

    if (creUpdateLesson?.eval_area_weights) {
      if (
        sumAllNumbersOfObject(creUpdateLesson.eval_area_weights).toString() !==
          "100" &&
        creUpdateLesson.evaluation_type === "100점제"
      ) {
        handleToastCenterTop("가중치 합계는 100이어야 합니다.");
        return;
      }
    }

    if (creUpdateLesson?.teachers && creUpdateLesson?.teachers?.length < 1) {
      handleToastCenterTop("교사를 선택하여 배정하세요.");
      return;
    }

    setIsSaving(true);

    if (createOrUpdate === "create") {
      createLesson.mutate(creUpdateLesson, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsSaving(false);
          setModalOpen(false);
          setCreUpdateLesson({
            eval_area_weights: null,
            evaluation_type: "",
            extra_attributes: null,
            hierarchy_one: "",
            hierarchy_two: "",
            hierarchy_three: "",
            lesson_hours: "",
            school_year_id: "",
            schoolid: schoolId,
            subject_name: "",
            tags: [],
            teachers: [],
            term_id: "",
          });
        },
        onError: (error) => {
          setIsSaving(false);
          if (
            error.message ===
            "과정, 학년, 반, 수업이름, 교사가 동일한 수업이 이미 존재합니다."
          ) {
            handleToastCenterTop(error.message, 4000);
            return;
          } else {
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(
              "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                error.message
            );
          }
        },
      });
    } else if (createOrUpdate === "update") {
      updateLesson.mutate(creUpdateLesson, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsSaving(false);
          setModalOpen(false);
          setCreUpdateLesson({
            eval_area_weights: null,
            evaluation_type: "",
            extra_attributes: null,
            hierarchy_one: "",
            hierarchy_two: "",
            hierarchy_three: "",
            lesson_hours: "",
            school_year_id: "",
            schoolid: schoolId,
            subject_name: "",
            tags: [],
            teachers: [],
            term_id: "",
          });
        },
        onError: (error) => {
          setIsSaving(false);
          if (
            error.message ===
            "과정, 학년, 반, 수업이름, 교사가 동일한 수업이 이미 존재합니다."
          ) {
            handleToastCenterTop(error.message, 4000);
            return;
          } else {
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(
              "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                error.message
            );
          }
        },
      });
    } else {
      console.log("update, create 둘 다 아니면 뭐지?");
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
    setCreUpdateLesson({
      eval_area_weights: null,
      evaluation_type: "",
      extra_attributes: null,
      hierarchy_one: "",
      hierarchy_two: "",
      hierarchy_three: "",
      lesson_hours: "",
      school_year_id: "",
      schoolid: schoolId,
      subject_name: "",
      tags: [],
      teachers: [],
      term_id: "",
    });
  };

  const handleDelete = (e) => {
    const { name } = e.target;
    const { id } = creUpdateLesson;
    if (name === "delete") {
      setConfirmDelete(true);
    } else {
      setIsDeleting(true);
      deleteLesson.mutate(id, {
        onSuccess: (successMessage) => {
          setCreUpdateLesson({
            eval_area_weights: null,
            evaluation_type: "",
            extra_attributes: null,
            hierarchy_one: "",
            hierarchy_two: "",
            hierarchy_three: "",
            lesson_hours: "",
            school_year_id: "",
            schoolid: schoolId,
            subject_name: "",
            tags: [],
            teachers: [],
            term_id: "",
          });
          setIsDeleting(false);
          setConfirmDelete(false);
          setModalOpen(false);
          queryClient.invalidateQueries(["lessons"]);
        },
        onError: (error) => {
          setIsDeleting(false);
          setConfirmDelete(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(error.message);
        },
      });
    }
  };

  const handleModalChange = (e) => {
    const { name, value, id } = e.target;
    let formattedValue = value;
    if (name === "eval_area_weights" || name === "extra_attributes") {
      formattedValue = {
        ...creUpdateLesson[name],
        [id]: value,
      };
    }

    if (name === "tags") {
      setTag(value);
      const newTag = { [value]: "3" };
      const isTagSelected = creUpdateLesson?.tags?.some((tag) =>
        tag.hasOwnProperty(value)
      );
      if (!isTagSelected && value !== "") {
        formattedValue = [...creUpdateLesson.tags, newTag];
      } else {
        return;
      }
    }

    //이제 선생님 해야 한다.

    if (name === "teachers") {
      const selectedOptionText = e.target.selectedOptions[0].text;
      setSelectedUserModal(value);
      const teachersArray = Array.isArray(creUpdateLesson.teachers)
        ? creUpdateLesson.teachers
        : [];
      const exists = teachersArray.some((r) => r.username === value);

      if (!exists && value) {
        const newTeacher = { username: value, tname: selectedOptionText };

        // Create a new array with the new teacher included
        formattedValue = [...teachersArray, newTeacher];
      } else {
        return;
      }
    }

    setCreUpdateLesson((creUpdateLesson) => ({
      ...creUpdateLesson,
      [name]: formattedValue,
    }));
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    // console.log("creUpdateLesson", creUpdateLesson);

    if (name === "0") {
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        school_year: selectedOptionText,
        school_year_id: value,
        hierarchy_one: "",
      }));

      setTermIdByToday(termPeriodData, setTermIdModal, value);
    }

    if (name === "1") {
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        hierarchy_one: value,
        hierarchy_two: "",
        hierarchy_three: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermIdModal,
        creUpdateLesson.school_year_id,
        value
      );
    }

    if (name === "2") {
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        hierarchy_two: value,
        hierarchy_three: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermIdModal,
        creUpdateLesson.school_year_id,
        creUpdateLesson.hierarchy_one,
        value
      );
    }

    if (name === "3") {
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        hierarchy_three: value,
      }));
    }

    if (name === "termDate") {
      setTermIdModal(value);
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        term_id: value,
      }));
    }

    // 아... 역시 교사를 가용한 모든 교사로 바꾸어야겠군 -> 바꾸었음
  };

  const handleClick = (value, name) => {
    if (name === "tags") {
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        tags: creUpdateLesson?.tags.filter((tag) => !tag.hasOwnProperty(value)),
      }));
    }

    if (name === "teachers") {
      setCreUpdateLesson((creUpdateLesson) => ({
        ...creUpdateLesson,
        teachers: creUpdateLesson.teachers.filter(
          (user) => user.username !== value
        ),
      }));
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "800px", overflowY: "auto" }}
        >
          <div className="modal-header mb-3">
            <h3>{createOrUpdate === "create" ? "수업생성" : "수업수정"}</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <table className="table text-center align-middle table-borderless">
                <tbody>
                  <tr>
                    <td style={{ width: "25%" }}>
                      <DropdownSchoolYear
                        settingData={settingData}
                        handleDropdownChange={handleDropdownChange}
                        schoolYearId={creUpdateLesson.school_year_id}
                        allYears={false}
                      />
                    </td>
                    <td style={{ width: "25%" }}>
                      <DropdownHierarchyOne
                        settingData={settingData}
                        handleDropdownChange={handleDropdownChange}
                        schoolYear={creUpdateLesson.school_year}
                        hierarchyOneId={creUpdateLesson.hierarchy_one}
                        hierarchyOneTitle="통합"
                        extraOption={true}
                        extraOptionTitle="과정"
                        required={true}
                      />
                    </td>
                    <td style={{ width: "25%" }}>
                      <DropdownHierarchyTwo
                        settingData={settingData}
                        handleDropdownChange={handleDropdownChange}
                        schoolYear={creUpdateLesson.school_year}
                        hierarchyOneId={creUpdateLesson.hierarchy_one}
                        hierarchyTwoId={creUpdateLesson.hierarchy_two}
                        hierarchyTwoTitle="통합"
                        extraOption={true}
                        extraOptionTitle="학년"
                        required={true}
                      />
                    </td>
                    <td style={{ width: "25%" }}>
                      <DropdownHierarchyThree
                        settingData={settingData}
                        handleDropdownChange={handleDropdownChange}
                        schoolYear={creUpdateLesson.school_year}
                        hierarchyTwoId={creUpdateLesson.hierarchy_two}
                        hierarchyThreeId={creUpdateLesson.hierarchy_three}
                        hierarchyThreeTitle="통합"
                        extraOption={true}
                        extraOptionTitle="반"
                        required={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <DropdownTermDate
                        termPeriodData={termPeriodData}
                        handleDropdownChange={handleDropdownChange}
                        termId={termIdModal}
                        schoolYearId={creUpdateLesson.school_year_id}
                        hierarchyOneId={creUpdateLesson.hierarchy_one}
                        hierarchyTwoId={creUpdateLesson.hierarchy_two}
                        required={true}
                      />
                    </td>
                    <td>
                      <DropdownUniqueUsersInDisplayData
                        displayData={userData}
                        displayKey={"fullname"}
                        valueKey={"username"}
                        uniqueKey={"username"}
                        sortKey={"fullname"}
                        isDesc={false}
                        handleDropdownChange={handleModalChange}
                        selectedUser={selectedUserModal}
                        selectName="teachers"
                        dropdownTitle="교사선택"
                        required={true}
                      />
                    </td>
                    <td colSpan={2} className="text-start">
                      {Array.isArray(creUpdateLesson?.teachers) &&
                        creUpdateLesson?.teachers?.map((user, index) => (
                          <span key={index}>
                            {user.tname}
                            <MdOutlineCancel
                              role="button"
                              onClick={() => {
                                handleClick(user.username, "teachers");
                              }}
                            />
                          </span>
                        ))}
                    </td>
                  </tr>
                  <tr>
                    <td>수업명</td>
                    <td colSpan={3}>
                      <input
                        type="text"
                        name="subject_name"
                        className="form-control"
                        value={creUpdateLesson?.subject_name || ""}
                        onChange={handleModalChange}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>시수</td>
                    <td>
                      <input
                        type="number"
                        name="lesson_hours"
                        className="form-control"
                        value={creUpdateLesson?.lesson_hours || ""}
                        onChange={handleModalChange}
                        required
                      />
                    </td>
                    <td>평가제</td>
                    <td>
                      <select
                        className="form-control"
                        name="evaluation_type"
                        value={creUpdateLesson?.evaluation_type || ""}
                        required
                        onChange={handleModalChange}
                      >
                        {lessonSetting?.filter(
                          (r) => r.item_code === "evalMethod"
                        ).length > 0 &&
                          lessonSetting
                            ?.filter((r) => r.item_code === "evalMethod")[0]
                            ?.detailed_setting?.map((setting, index) => (
                              <option key={index} value={setting}>
                                {setting}
                              </option>
                            ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select
                        className="form-control"
                        name="tags"
                        value={tag}
                        onChange={handleModalChange}
                      >
                        <option value="">태그선택</option>
                        {tagList &&
                          tagList[0]?.tags.map((tag, index) => (
                            <option key={index} value={tag}>
                              {tag}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td colSpan={3} className="text-start">
                      {Array.isArray(creUpdateLesson?.tags) &&
                        creUpdateLesson?.tags?.map((tagObj, index) => {
                          const [tagKey] = Object.keys(tagObj); // 객체에서 키 추출
                          return (
                            <span key={index}>
                              {tagKey}
                              <MdOutlineCancel
                                role="button"
                                onClick={() => {
                                  handleClick(tagKey, "tags"); // 태그 삭제 함수 호출
                                }}
                              />{" "}
                            </span>
                          );
                        })}
                    </td>
                  </tr>
                </tbody>
              </table>
              {(creUpdateLesson?.evaluation_type === "100점제" ||
                creUpdateLesson?.evaluation_type === "커스텀") && (
                <table className="table text-center align-middle mt-1 mb-3">
                  <tbody>
                    <tr>
                      <th style={{ width: "20%" }}>평가구분</th>
                      {evalAreaWeights &&
                        evalAreaWeights.map((weight) => (
                          <th key={weight}>{weight}</th>
                        ))}
                      <th style={{ width: "15%" }}>합계</th>
                    </tr>
                    <tr>
                      <td>가중치(%)</td>
                      {evalAreaWeights &&
                        evalAreaWeights.map((weight, index) => (
                          <td key={index}>
                            <input
                              className="form-control m-0 p-1"
                              type="number"
                              name="eval_area_weights"
                              onChange={handleModalChange}
                              id={weight}
                              value={
                                creUpdateLesson?.eval_area_weights?.[weight] ??
                                ""
                              }
                            />
                          </td>
                        ))}
                      <td>
                        {creUpdateLesson?.eval_area_weights
                          ? sumAllNumbersOfObject(
                              creUpdateLesson.eval_area_weights
                            )
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              <table className="table text-center align-middle table-borderless">
                <tbody>
                  <tr>
                    {configMap.subjectSort !== "미사용" && (
                      <React.Fragment>
                        <td style={{ width: "25%" }}>수업구분</td>
                        <td style={{ width: "25%" }}>
                          {lessonSetting && creUpdateLesson && (
                            <RenderExtraAttributesSelect
                              lessonSetting={lessonSetting}
                              itemCode="subjectSort"
                              creUpdateLesson={creUpdateLesson}
                              setCreUpdateLesson={setCreUpdateLesson}
                              handleModalChange={handleModalChange}
                            />
                          )}
                        </td>
                      </React.Fragment>
                    )}

                    {configMap.subjectCategory !== "미사용" && (
                      <React.Fragment>
                        <td style={{ width: "25%" }}>과목분류</td>
                        <td style={{ width: "25%" }}>
                          <RenderExtraAttributesSelect
                            lessonSetting={lessonSetting}
                            itemCode="subjectCategory"
                            creUpdateLesson={creUpdateLesson}
                            setCreUpdateLesson={setCreUpdateLesson}
                            handleModalChange={handleModalChange}
                          />
                        </td>
                      </React.Fragment>
                    )}
                  </tr>

                  <tr>
                    {configMap.subjectCode !== "미사용" && (
                      <React.Fragment>
                        <td style={{ width: "25%" }}>수업코드</td>
                        <td style={{ width: "25%" }}>
                          <RenderExtraAttributesSelect
                            lessonSetting={lessonSetting}
                            itemCode="subjectCode"
                            creUpdateLesson={creUpdateLesson}
                            setCreUpdateLesson={setCreUpdateLesson}
                            handleModalChange={handleModalChange}
                          />
                        </td>
                      </React.Fragment>
                    )}

                    {configMap.subjectOrder !== "미사용" && (
                      <React.Fragment>
                        <td style={{ width: "25%" }}>과목순서</td>
                        <td style={{ width: "25%" }}>
                          {lessonSetting?.find(
                            (r) => r.item_code === "subjectOrder"
                          ) ? (
                            <input
                              className="form-control"
                              type="number"
                              name="extra_attributes"
                              value={
                                creUpdateLesson?.extra_attributes
                                  ?.subjectOrder || ""
                              } // 상태 값이 안전하게 접근
                              id="subjectOrder"
                              required={
                                lessonSetting?.find(
                                  (r) => r.item_code === "subjectOrder"
                                ).saved_setting === "필수"
                                  ? true
                                  : false
                              }
                              onChange={handleModalChange}
                            />
                          ) : (
                            <div>관리자 문의</div>
                          )}{" "}
                        </td>
                      </React.Fragment>
                    )}
                  </tr>

                  {configMap.subjectContent !== "미사용" && (
                    <React.Fragment>
                      <tr>
                        <td>
                          과목
                          <br />
                          공통내용
                        </td>
                        <td colSpan={3}>
                          <textarea
                            type="text"
                            name="extra_attributes"
                            className="form-control"
                            id="subjectContent"
                            onChange={handleModalChange}
                            rows={4}
                            required={
                              lessonSetting?.find(
                                (r) => r.item_code === "subjectContent"
                              )?.saved_setting === "필수"
                                ? true
                                : false
                            }
                            value={
                              creUpdateLesson?.extra_attributes
                                ?.subjectContent || ""
                            }
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              {isDeleting ? (
                <button type="button" className="btn btn-danger" disabled>
                  삭제중..
                </button>
              ) : (
                <button
                  type="button"
                  className={
                    confirmDelete ? "btn btn-danger" : "btn btn-warning"
                  }
                  name={confirmDelete ? "confirm" : "delete"}
                  onClick={handleDelete}
                >
                  {confirmDelete ? "삭제실행" : "삭제"}
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                취소
              </button>
              {isSaving ? (
                <button className="btn btn-primary" disabled>
                  저장중
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  저장하기
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

function RenderExtraAttributesSelect({
  lessonSetting,
  itemCode,
  creUpdateLesson,
  setCreUpdateLesson,
  handleModalChange,
}) {
  const thisSetting = lessonSetting?.find((r) => r.item_code === itemCode);

  const isRequired = thisSetting?.saved_setting === "필수";

  useEffect(() => {
    if (
      isRequired &&
      (!creUpdateLesson.extra_attributes ||
        !creUpdateLesson.extra_attributes[itemCode])
    ) {
      const firstOption = thisSetting?.detailed_setting[0]; // 첫 번째 설정
      setCreUpdateLesson((prevLesson) => ({
        ...prevLesson,
        extra_attributes: {
          ...prevLesson.extra_attributes,
          [itemCode]: firstOption, // 첫 번째 옵션을 기본값으로 설정
        },
      }));
    }
  }, [
    isRequired,
    itemCode,
    thisSetting?.detailed_setting,
    creUpdateLesson.extra_attributes,
    setCreUpdateLesson,
  ]);

  if (!thisSetting) {
    return <div>관리자문의</div>; // 설정이 없을 경우 메시지 출력
  }

  const selectValue = creUpdateLesson?.extra_attributes?.[itemCode] || ""; // Optional chaining 사용

  return (
    <React.Fragment>
      <select
        className="form-control"
        name="extra_attributes"
        value={selectValue} // 상태 값이 안전하게 접근
        id={itemCode}
        required={isRequired}
        onChange={handleModalChange}
      >
        {!isRequired && <option value=""></option>}
        {thisSetting.detailed_setting.map((setting) => (
          <option key={setting} value={setting}>
            {setting}
          </option>
        ))}
      </select>
    </React.Fragment>
  );
}

/* 예전 내가 혼자 만들었던 것 기록용 (저 위에 것은 챗지피티가 컴포넌트로 만들라고 해서 따라 한 것... 내부함수로 간단히 하고 싶었는데 컴포넌트가 더 좋다고 함)


                          <select
                            className="form-control"
                            name="extra_attributes"
                            value={creUpdateLesson?.extra_attributes || ""}
                            id="subjectCategory"
                            required
                            onChange={handleModalChange}
                          >
                            {lessonSetting?.filter(
                              (r) => r.item_code === "subjectCategory"
                            ).length > 0 &&
                              lessonSetting
                                ?.filter(
                                  (r) => r.item_code === "subjectCategory"
                                )[0]
                                .detailed_setting.map((setting, index) => (
                                  <option key={index} value={setting}>
                                    {setting}
                                  </option>
                                ))}
                          </select>
*/
