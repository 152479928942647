import React, { useState } from "react";
import { convertToYearMonthDayFormat } from "../../../util/formatdate";
import { useLocation } from "react-router-dom";
import useUsers from "../../../hooks/useUsers";
import { validateIfExistInObjsOfArr } from "../../../util/validate";
import GenerateStuNoModal from "./GenerateStuNoModal";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

export default function AddStudent({
  setIsUploading,
  isUploading,
  setSuccess,
  rows,
}) {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [userInfo, setUserInfo] = useState({
    username: "",
    schoolid: schoolId,
    role: "student",
    status: 1,
    idatschool: "",
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { createUser } = useUsers();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "birthday") {
      formattedValue = convertToYearMonthDayFormat(value);
    } else if (name === "username") {
      formattedValue = value + `@${schoolId}`;
    }
    setUserInfo((userInfo) => ({ ...userInfo, [name]: formattedValue }));
    // console.log(userInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = /^[A-Za-z0-9~`!@#$%^&*()_+={}[\]|\\:;"'<,>.?/-]*$/;

    if (!regex.test(userInfo.password)) {
      setErrorMessage("비밀번호는 영문, 숫자, 특수기호만 입력 가능합니다.");
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    const usernameRegex = /^[a-z0-9~`!@#$%^&*()_+={}|\\:;"'<,>.?/-]*$/;

    if (!usernameRegex.test(userInfo.username)) {
      setErrorMessage(
        "사용자 아이디는 영문소문자, 숫자, 특수기호만 입력 가능합니다."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    setIsUploading(true);

    if (validateIfExistInObjsOfArr(rows, "idatschool", userInfo.idatschool)) {
      setSuccess(
        userInfo.idatschool + "학번은 이미 존재합니다. 다른 학번으로 해주세요."
      );
      setTimeout(() => {
        setSuccess(null);
      }, 4000);
      setIsUploading(false);
    } else if (
      validateIfExistInObjsOfArr(rows, "username", userInfo.username)
    ) {
      setSuccess(
        userInfo.username +
          "해당 아이디는 이미 존재합니다. 다른 아이디로 해주세요."
      );
      setTimeout(() => {
        setSuccess(null);
      }, 4000);
      setIsUploading(false);
    } else {
      createUser.mutate(userInfo, {
        onSuccess: (username) => {
          console.log("submitted");

          setSuccess(
            username.username + "아이디의 학생이 성공적으로 등록되었습니다."
          );
          setTimeout(() => {
            setSuccess(null);
          }, 4000);
          setIsUploading(false);
        },
        onError: (error) => {
          console.log("submitted");

          setSuccess(error.message);
          setTimeout(() => {
            setSuccess(null);
          }, 4000);
          setIsUploading(false);
        },
      });
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen && (
        <GenerateStuNoModal
          setModalOpen={setModalOpen}
          rows={rows}
          setUserInfo={setUserInfo}
        />
      )}

      <form className="row g-2" onSubmit={handleSubmit}>
        <div className="col-auto">
          <label htmlFor="fullname" className="form-label ms-2 fw-bold">
            학생이름<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control m-2"
            name="fullname"
            required
            onChange={handleChange}
          />
        </div>

        <div className="col-auto">
          <label htmlFor="gender" className="form-label ms-2 fw-bold">
            성별<span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control m-2"
            name="gender"
            required
            onChange={handleChange}
          >
            <option value="">선택</option>
            <option value="남">남</option>
            <option value="여">여</option>
          </select>
        </div>

        <div className="col-auto">
          <label htmlFor="birthday" className="form-label ms-2 fw-bold">
            생년월일
          </label>
          <input
            type="date"
            className="form-control m-2"
            name="birthday"
            onChange={handleChange}
          />
        </div>

        <div className="col-auto">
          <label htmlFor="username" className="form-label ms-2 fw-bold">
            사용자아이디<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group mb-3 mt-2 ps-2">
            <input
              type="text"
              className="form-control border border-end-0 text-end pe-0 me-0"
              name="username"
              required
              onChange={handleChange}
              aria-describedby="basic-addon2"
              style={{ width: "140px" }}
            />
            <span
              className="input-group-text bg-transparent ps-0"
              id="basic-addon2"
            >
              @{schoolId}
            </span>
          </div>
        </div>

        <div className="col-auto">
          <label htmlFor="password" className="form-label ms-2 fw-bold">
            비밀번호<span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-group mb-3 mt-2">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              required
              onChange={handleChange}
            />
            <button
              type="button" // This prevents form submission
              className="btn btn-outline-secondary"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <MdOutlineVisibilityOff size={20} />
              ) : (
                <MdOutlineVisibility size={20} />
              )}
            </button>
          </div>
        </div>

        <div className="col-auto">
          <label htmlFor="idatschool" className="form-label ms-2 fw-bold">
            학번
            <button
              className="btn btn-secondary btn-sm pb-0 pt-0 ms-2"
              type="button"
              onClick={handleModal}
            >
              생성
            </button>
          </label>
          <input
            type="text"
            className="form-control m-2"
            name="idatschool"
            value={userInfo && userInfo.idatschool}
            onChange={handleChange}
          />
        </div>

        <div className="col-auto">
          <label htmlFor="addStudentBtn" className="form-label ms-2 fw-bold">
            학생
          </label>
          <div>
            <button
              className="btn btn-primary text-nowrap m-2"
              name="addStudentBtn"
              type="submit"
              disabled={isUploading}
            >
              {isUploading ? "업로드중..." : "추가"}
            </button>
          </div>
        </div>
        {errorMessage && (
          <p className="text-danger fw-bold ms-5">{errorMessage}</p>
        )}
      </form>
    </>
  );
}
