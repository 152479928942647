import React, { useMemo } from "react";
import { createFilteredAndSortedOptions } from "../../util/dealingArrObj";

export default function DropdownHierarchyOne({
  settingData,
  handleDropdownChange,
  schoolYear,
  hierarchyOneId,
  hierarchyOneTitle = "과정",
  extraOption = false,
  extraOptionTitle = "",
  required = false,
  disabled = false,
}) {
  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData: settingData,
      displayKey: "group_name",
      valueKey: "id",
      filters: { hierarchy_level: 1, school_year: schoolYear },
      idKey: "id",
      sortKey: "order",
      isDesc: false,
      dataName: "organization_path",
    });
  }, [settingData, schoolYear]);

  return (
    <select
      className="form-control"
      name="1"
      onChange={handleDropdownChange}
      value={hierarchyOneId || ""}
      required={required}
      disabled={disabled}
    >
      {extraOption && <option value="">{extraOptionTitle}</option>}
      <option value="0">{hierarchyOneTitle}</option>
      {settingData?.length > 0 && options}
    </select>
  );
}
