export default class ApprovalRequestsService {
  constructor(http) {
    this.http = http;
  }

  async findAllApprovalRequests(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/approvalrequests/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async getApprovalRequestsByUserId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/approvalrequests/readbyid?${queryParams}`, {
      method: "GET",
    });
  }

  async createOrUpdateApprovalRequestsBulk(dataArray) {
    return this.http.fetch(`/api/approvalrequests/createorupdatebulk`, {
      method: "POST",
      body: JSON.stringify(dataArray),
    });
  }

  async deleteApprovalRequests(data) {
    return this.http.fetch(`/api/approvalrequests/delete`, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  async deleteVariousStudentRecordOne(id) {
    return this.http.fetch(`/api/approvalrequests/deleteone`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
