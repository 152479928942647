export default class EducationalActivitiesService {
  constructor(http) {
    this.http = http;
  }

  async findAllEducationalActivities(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/educationalactivities/readall?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async createEducationalActivities(activity) {
    return this.http.fetch(`/api/educationalactivities/create`, {
      method: "POST",
      body: JSON.stringify(activity),
    });
  }

  async updateEducationalActivities(activity) {
    return this.http.fetch(`/api/educationalactivities/update`, {
      method: "PUT",
      body: JSON.stringify(activity),
    });
  }

  async deleteEducationalActivities(id) {
    return this.http.fetch(`/api/educationalactivities/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }

  async findAllEducationalActivitiesTypes(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/educationalactivitiestypes/readall?${queryParams}`,
      {
        method: "GET",
      }
    );
  }
}
