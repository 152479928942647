export default class LessonStudentsService {
  constructor(http) {
    this.http = http;
  }

  // async findAllLessonStudents(conditions = {}) {
  //   const queryParams = Object.entries(conditions)
  //     .map(
  //       ([key, value]) =>
  //         `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  //     )
  //     .join("&");
  //   return this.http.fetch(`/api/lessonstudents/readall?${queryParams}`, {
  //     method: "GET",
  //   });
  // }

  async getLessonStudentsByLessonId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/lessonstudents/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async getLessonStudentsByStudentId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/lessonstudents/readbyuserid?${queryParams}`, {
      method: "GET",
    });
  }

  async getAllLessonStudentsByStudentId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/lessonstudents/readallbyuserid?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async createLessonStudents(data) {
    return this.http.fetch(`/api/lessonstudents/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async createLessonStudentsBulk(data) {
    return this.http.fetch(`/api/lessonstudents/createbulk`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateLessonStudents(data) {
    return this.http.fetch(`/api/lessonstudents/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async updateLessonStudentsBulk(dataArray) {
    return this.http.fetch(`/api/lessonstudents/updatebulk`, {
      method: "PUT",
      body: JSON.stringify(dataArray),
    });
  }

  async deleteLessonStudents(id) {
    return this.http.fetch(`/api/lessonstudents/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
