export default function formatDate(dateString) {
  //이건 지역 상관없이, 한국 시간 기준으로 저장되는 것이다.
  // console.log("dateString", dateString);
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export function convertToYearMonthDayFormat(dateString) {
  //이건 자신이 선택한 날짜를 2023-01-21 이런 형식으로 젖아되도록 하는 것이다.
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function dateToYearMonthDayFormat(date) {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 월은 0부터 시작하므로 1을 더하고, 항상 두 자리로 표시합니다.
  const day = ("0" + date.getDate()).slice(-2); // 일도 항상 두 자리로 표시합니다.
  return `${year}-${month}-${day}`;
}
