export default class GroupsMembersService {
  constructor(http) {
    this.http = http;
  }

  async findAllGroupsMembers(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/groupsmembers/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createGroupsMembers(data) {
    return this.http.fetch(`/api/groupsmembers/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async createGroupsMembersBulk(array) {
    return this.http.fetch(`/api/groupsmembers/createbulk`, {
      method: "POST",
      body: JSON.stringify(array),
    });
  }

  async updateGroupsMembers(data) {
    return this.http.fetch(`/api/groupsmembers/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async deleteGroupsMembers(id) {
    return this.http.fetch(`/api/groupsmembers/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
