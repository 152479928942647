export default class EduActiStudentsService {
  constructor(http) {
    this.http = http;
  }

  async findAllEduActiStudents(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/eduactistudents/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async getEduActiStudentsByUserId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/eduactistudents/readbyid?${queryParams}`, {
      method: "GET",
    });
  }

  async getAllEduActiStudentsByUserIdWithStartDate(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/eduactistudents/readallbyid?${queryParams}`, {
      method: "GET",
    });
  }

  async createEduActiStudents(activity) {
    return this.http.fetch(`/api/eduactistudents/create`, {
      method: "POST",
      body: JSON.stringify(activity),
    });
  }

  async createEduActiStudentsBulk(activities) {
    return this.http.fetch(`/api/eduactistudents/createbulk`, {
      method: "POST",
      body: JSON.stringify(activities),
    });
  }

  async updateEduActiStudents(activity) {
    return this.http.fetch(`/api/eduactistudents/update`, {
      method: "PUT",
      body: JSON.stringify(activity),
    });
  }

  async deleteEduActiStudents(id) {
    return this.http.fetch(`/api/eduactistudents/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
