export default class DocumentFormsService {
  constructor(http) {
    this.http = http;
  }

  // async findAllDocumentForms(conditions = {}) {
  //   const queryParams = Object.entries(conditions)
  //     .map(
  //       ([key, value]) =>
  //         `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  //     )
  //     .join("&");
  //   return this.http.fetch(
  //     `/api/documentforms/readallwithgroup?${queryParams}`,
  //     {
  //       method: "GET",
  //     }
  //   );
  // }

  async getDocumentForms(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/documentforms/readallwithgroup?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async createOrUpdateDocumentForms(data) {
    return this.http.fetch(`/api/documentforms/createorupdate`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async createOrUpdateDocumentFormsBulk(dataArray) {
    return this.http.fetch(`/api/documentforms/createorupdatebulk`, {
      method: "POST",
      body: JSON.stringify(dataArray),
    });
  }

  async deleteDocumentForms(data) {
    return this.http.fetch(`/api/documentforms/delete`, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  async deleteVariousStudentRecordOne(id) {
    return this.http.fetch(`/api/documentforms/deleteone`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
