import React, { useState } from "react";
import { convertToYearMonthDayFormat } from "../../../util/formatdate";
import { useLocation } from "react-router-dom";
import useUsers from "../../../hooks/useUsers";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

export default function AddTeacher({
  setIsUploading,
  isUploading,
  setSuccess,
}) {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [userInfo, setUserInfo] = useState({
    username: "",
    schoolid: schoolId,
    role: "teacher",
    status: 1,
  });

  const { createUser } = useUsers();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "birthday") {
      formattedValue = convertToYearMonthDayFormat(value);
    } else if (name === "username") {
      formattedValue = value + `@${schoolId}`;
    }
    setUserInfo((userInfo) => ({ ...userInfo, [name]: formattedValue }));
    // console.log(userInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const regex = /^[A-Za-z0-9~`!@#$%^&*()_+={}[\]|\\:;"'<,>.?/-]*$/;

    if (!regex.test(userInfo.password)) {
      setErrorMessage("영문, 숫자, 특수기호만 입력 가능합니다.");
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    const usernameRegex = /^[a-z0-9~`!@#$%^&*()_+={}|\\:;"'<,>.?/-]*$/;

    if (!usernameRegex.test(userInfo.username)) {
      setErrorMessage(
        "사용자 아이디는 영문소문자, 숫자, 특수기호만 입력 가능합니다."
      );
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    setIsUploading(true);
    console.log("submitted");

    createUser.mutate(userInfo, {
      onSuccess: (username) => {
        setSuccess(
          username.username + "아이디의 교사가 성공적으로 등록되었습니다."
        );
        setTimeout(() => {
          setSuccess(null);
        }, 4000);
        setIsUploading(false);
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        alert(error.message);
        setSuccess(null);
      },
    });
  };

  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <form className="row g-2" onSubmit={handleSubmit}>
      <div className="col-auto">
        <label htmlFor="fullname" className="form-label ms-2 fw-bold">
          교사이름
        </label>
        <input
          type="text"
          className="form-control m-2"
          name="fullname"
          required
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <label htmlFor="gender" className="form-label ms-2 fw-bold">
          성별
        </label>
        <select
          className="form-control m-2"
          name="gender"
          required
          onChange={handleChange}
        >
          <option value="">선택</option>
          <option value="남">남</option>
          <option value="여">여</option>
        </select>
      </div>

      <div className="col-auto">
        <label htmlFor="birthday" className="form-label ms-2 fw-bold">
          생년월일
        </label>
        <input
          type="date"
          className="form-control m-2"
          name="birthday"
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <label htmlFor="username" className="form-label ms-2 fw-bold">
          사용자아이디
        </label>
        <div className="input-group mb-3 mt-2 ps-2">
          <input
            type="text"
            className="form-control border border-end-0 text-end pe-0 me-0"
            name="username"
            required
            onChange={handleChange}
            aria-describedby="basic-addon2"
            style={{ width: "140px" }}
          />
          <span
            className="input-group-text bg-transparent ps-0"
            id="basic-addon2"
          >
            @{schoolId}
          </span>
        </div>
      </div>

      <div className="col-auto">
        <label htmlFor="password" className="form-label ms-2 fw-bold">
          비밀번호
        </label>
        <div className="input-group mb-3 mt-2">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            name="password"
            required
            onChange={handleChange}
          />
          <button
            type="button" // This prevents form submission
            className="btn btn-outline-secondary"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <MdOutlineVisibilityOff size={20} />
            ) : (
              <MdOutlineVisibility size={20} />
            )}
          </button>
        </div>
        {errorMessage && <p className="text-danger fw-bold">{errorMessage}</p>}
      </div>

      <div className="col-auto">
        <label htmlFor="addTeacherBtn" className="form-label ms-2 fw-bold">
          교직원
        </label>
        <div>
          <button
            className="btn btn-primary text-nowrap m-2"
            name="addTeacherBtn"
            type="submit"
            disabled={isUploading}
          >
            {isUploading ? "업로드중..." : "생성"}
          </button>
        </div>
      </div>
      {errorMessage && (
        <p className="text-danger fw-bold ms-5">{errorMessage}</p>
      )}
    </form>
  );
}
