export default class TagListService {
  constructor(http) {
    this.http = http;
  }

  async findAllTagListByEntity(conditions = {}) {
    // console.log("conditions", conditions);
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/taglist/readallbyentity?${queryParams}`, {
      method: "GET",
    });
  }

  async findAllTagList(conditions = {}) {
    // console.log("conditions", conditions);
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/taglist/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createOrUpdateTagList(tag) {
    return this.http.fetch(`/api/taglist/createorupdate`, {
      method: "POST",
      body: JSON.stringify(tag),
    });
  }

  // async updateTagList(activity) {
  //   return this.http.fetch(`/api/taglist/update`, {
  //     method: "PUT",
  //     body: JSON.stringify(activity),
  //   });
  // }

  // async deleteTagList(id) {
  //   return this.http.fetch(`/api/taglist/delete`, {
  //     method: "DELETE",
  //     body: JSON.stringify({ id }),
  //   });
  // }
}
