import React from "react";
export default function Dashboard() {
  const backgroundStyle = {
    backgroundImage: `url(/images/dashboard1.jpg)`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    width: "99vw",
    height: "100vh", // 높이를 화면 전체로 설정
  };
  return <div style={backgroundStyle}></div>;
}
