import React from "react";

/* 
RegistrationVariousRecordInputTable 와의 큰 차이점 중 하나는...
SaveInputsTable의 설정과 연동한다는 점이고,
이 테이블에 나오는 tr은 모두 생성된 실 데이터를 가져오는 것이라서 모두 id가 있으면 id 기반으로 한다.

*/

/*
various_record_inputs 받아서 th, td 만들고
various_student_record 받아서 td 안에 채워넣고
꼭 위에 2개 아니라도 같은 형식이면 가능
handleTdChange 함수 받아서, 데이터 state에 저장했다가
handleSaveBtn 함수 받아서, 누르면 서버로 저장하고
handleDeleteBtn 함수 받아서, 누르면 서버에서 지우고
일단 이정도면 기본이 될 거고, 나중에 통계내거나 글자수를 하거나 그런 것은 필요하면 추가하자.
*/

export default function SaveInputsTable({
  inputData,
  userInputDataList = [],
  handleSaveState,
  handleSaveClick,
  handleDelete,
  handleConfirmDelete,
  handleCancelDelete,
  confirmDelete,
  savedRows,
  addfontSize = "",
  addMinWidth = "",
  groupOp = false,
  groupName = "그룹",
}) {
  const calculateStyle = (baseStyle, addValue) => {
    const baseSize = parseFloat(baseStyle) || 0;
    const addSize = parseFloat(addValue) || 0;
    return `${baseSize + addSize}px`;
  };

  function renderInput(r, row, value, handleSaveState) {
    switch (r.inputs) {
      case "select":
        return (
          <select
            className="select-like-span"
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
          >
            {r.options &&
              r.options.map((option, index) => (
                <option key={`${index}-${row.id}`} value={option?.value || ""}>
                  {option?.label || ""}
                </option>
              ))}
          </select>
        );
      case "textarea":
        return (
          <textarea
            className="input-like-span"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            rows={r?.options[0]?.row || 5}
            style={{ width: `${r?.options?.[0]?.width || 200}px` }}
          />
        );
      case "empty":
        return value ? value : "";
      case "text":
        return (
          <input
            className="input-like-span"
            type="text"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
          />
        );
      case "number":
        return (
          <input
            className="input-like-span"
            type="number"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            style={{ width: `${r?.options?.[0]?.width || 60}px` }}
          />
        );
      case "date":
        return (
          <input
            className="input-like-span"
            type="date"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            style={{ width: `${r?.options?.[0]?.width || 120}px` }}
          />
        );
      default:
        return (
          <input
            className="input-like-span"
            type={r.inputs || "text"}
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, row.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
          />
        );
    }
  }

  return (
    <table
      className="table table-bordered text-center"
      style={{ fontSize: "13px" }}
    >
      <thead>
        <tr className="table-secondary p-1">
          <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
            과정
          </th>
          <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
            학년
          </th>
          <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
            반
          </th>
          <th className="p-1 align-middle" style={{ minWidth: "60px" }}>
            이름
          </th>
          {groupOp && (
            <th
              className="p-1 align-middle"
              style={{
                minWidth: "60px",
                width: "100px",
              }}
            >
              {groupName}
            </th>
          )}
          {inputData &&
            inputData.map((r) => (
              <th
                className="align-middle p-0"
                style={{
                  minWidth: calculateStyle("60px", addMinWidth),
                  fontSize: calculateStyle("11px", addfontSize),
                }}
                key={`th-${r.id}`}
              >
                {r.title} <br />
              </th>
            ))}
          <th className="align-middle p-1" style={{ minWidth: "72px" }}></th>
        </tr>
      </thead>
      <tbody style={{ fontSize: "13px" }} className="align-middle">
        {userInputDataList?.map((row) => (
          <tr key={row.id}>
            <td className="p-1">{row.hierarchyOne}</td>
            <td className="p-1">{row.hierarchyTwo}</td>
            <td className="p-1">{row.hierarchyThree}</td>
            <td className="p-1">{row.fullname}</td>
            {groupOp && <td className="p-1">{row.group}</td>}
            {inputData &&
              inputData.map((r) => {
                // const studentData = userInputDataList.find(
                //   (s) => s.username === row.username
                // );
                // const value = studentData?.value_object?.[r.name] ?? "";

                const value = row?.value_object?.[r.name] ?? "";

                return (
                  <td key={`th-${row.id}-${r.id}`} className="p-1">
                    {renderInput(r, row, value, handleSaveState)}
                  </td>
                );
              })}
            <td className="p-1">
              <button
                className="btn btn-sm p-0 pe-1 ps-1 btn-primary"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  handleSaveClick(row);
                }}
              >
                {savedRows.includes(row.id) ? "완료" : "저장"}
              </button>
              {confirmDelete.includes(row.id) ? (
                <>
                  <button
                    type="button"
                    style={{ fontSize: "12px" }}
                    onClick={() => handleDelete(row)}
                    className="btn btn-sm p-0 pe-1 ps-1 btn-danger"
                  >
                    확인
                  </button>
                  <button
                    type="button"
                    style={{ fontSize: "12px" }}
                    onClick={() => handleCancelDelete()}
                    className="btn btn-sm p-0 pe-1 ps-1 btn-secondary"
                  >
                    취소
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  style={{ fontSize: "12px" }}
                  onClick={() => handleConfirmDelete(row)}
                  className="btn btn-sm p-0 pe-1 ps-1 btn-warning"
                >
                  삭제
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
