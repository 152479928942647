import React, { useEffect, useState } from "react";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import { useUIContext } from "../../../context/UIContext";
import useSchoolYearPeriod from "../../../hooks/useSchoolYearPeriod";
import { useQueryClient } from "@tanstack/react-query";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";

export default function SchoolYearPeriod({
  settingData,
  handleSchoolYearDropdownChange,
  schoolYearInfo,
  setSchoolYearInfo,
  schoolId,
  conditions,
}) {
  const { setIsLoadingModal } = useUIContext();

  const {
    schoolYearPeriodQuery: { data: schoolYearPeriodData },
    createSchoolYearPeriod,
    updateSchoolYearPeriod,
    deleteSchoolYearPeriod,
  } = useSchoolYearPeriod(conditions);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (schoolYearPeriodData && schoolYearPeriodData.length > 0) {
      const filtered = schoolYearPeriodData.filter(
        (r) =>
          r.school_year_id.toString() === schoolYearInfo.schoolYearId.toString()
      );
      setDatesRows(filtered);
      setOrganSettingInfo(
        filtered.map((row) => ({
          schoolYear: null,
          schoolYearId: row.school_year_id,
          hierarchyOneId: row.applied_hierarchy_one,
          hierarchyTwoId: row.applied_hierarchy_two,
          organPathForSort: "/",
        }))
      );
    }
  }, [schoolYearInfo.schoolYearId, schoolYearPeriodData]);

  const [datesRows, setDatesRows] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [addTr, setAddTr] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState([]);
  const [organSettingInfo, setOrganSettingInfo] = useState([]);

  const [createData, setCreateData] = useState({
    schoolid: "",
    school_year_id: "",
    start_date: "",
    end_date: "",
    class_days: "",
    applied_hierarchy_one: null,
    applied_hierarchy_two: null,
  });

  const handleDropdownChange = (rowIndex, e) => {
    const { name, value } = e.target;

    // console.log("rowIndex, e", rowIndex, name, value);

    if (name === "1") {
      setOrganSettingInfo((organSettingInfo) =>
        organSettingInfo.map((setting, index) =>
          index === rowIndex
            ? {
                ...setting,
                hierarchyOneId: value === 0 || value === "0" ? null : value,
                hierarchyTwoId: "",
              }
            : setting
        )
      );

      const updatedRows = datesRows.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              applied_hierarchy_one:
                value === 0 || value === "0" ? null : value,
              applied_hierarchy_two: null,
              isModified: true,
            }
          : row
      );
      // console.log("updatedRows tr", updatedRows);
      setDatesRows(updatedRows);
    }

    if (name === "2") {
      setOrganSettingInfo((organSettingInfo) =>
        organSettingInfo.map((setting, index) =>
          index === rowIndex
            ? {
                ...setting,
                hierarchyTwoId: value === 0 || value === "0" ? null : value,
              }
            : setting
        )
      );

      const updatedRows = datesRows.map((row, index) =>
        index === rowIndex
          ? {
              ...row,
              applied_hierarchy_two:
                value === 0 || value === "0" ? null : value,
              isModified: true,
            }
          : row
      );
      // console.log("updatedRows tr", updatedRows);
      setDatesRows(updatedRows);
    }
  };

  const handleSubmit = (index) => {
    let dataSet = datesRows[index];
    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      return;
    }

    dataSet = {
      ...dataSet,
      applied_hierarchy_one: /^\d+$/.test(dataSet.applied_hierarchy_one)
        ? parseInt(dataSet.applied_hierarchy_one, 10)
        : null,
      applied_hierarchy_two: /^\d+$/.test(dataSet.applied_hierarchy_two)
        ? parseInt(dataSet.applied_hierarchy_two, 10)
        : null,
    };

    if (!dataSet.start_date || isNaN(new Date(dataSet.start_date).getTime())) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert("유효하지 않은 시작날짜입니다.");
      return;
    }

    if (!dataSet.end_date || isNaN(new Date(dataSet.end_date).getTime())) {
      alert("유효하지 않은 종료날짜입니다.");
      return;
    }

    const isDuplicate = schoolYearPeriodData.some((data) => {
      return (
        data.school_year_id === dataSet.school_year_id &&
        data.applied_hierarchy_one === dataSet.applied_hierarchy_one &&
        data.applied_hierarchy_two === dataSet.applied_hierarchy_two &&
        data.id !== dataSet.id // 고유 ID를 사용하여 현재 데이터와 기존 데이터를 비교
      );
    });

    if (isDuplicate) {
      alert("이미 존재하는 날짜 설정입니다.2");
      return;
    }

    setIsLoadingModal(true);
    updateSchoolYearPeriod.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setDatesRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  // 일반적인 row 데이터 변경을 처리하는 함수
  const handleTrChange = (rowIndex, event) => {
    const { value } = event.target;
    const field = event.target.name;
    const updatedRows = datesRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);
    setDatesRows(updatedRows);
  };

  const handleAddTr = () => {
    setAddTr((pre) => !pre);
    setCreateData({
      schoolid: "",
      school_year_id: "",
      start_date: "",
      end_date: "",
      class_days: "",
      applied_hierarchy_one: null,
      applied_hierarchy_two: null,
    });

    setSchoolYearInfo((schoolYearInfo) => ({
      ...schoolYearInfo,
      hierarchyOneId: null,
      hierarchyTwoId: null,
    }));
  };

  const handleCreateTr = (e) => {
    const { value, name } = e.target;

    setCreateData((createData) => ({ ...createData, [name]: value }));
  };

  const submitCreateTr = () => {
    const dataSet = {
      ...createData,
      schoolid: schoolId,
      school_year_id: schoolYearInfo.schoolYearId,
      applied_hierarchy_one: /^\d+$/.test(schoolYearInfo.hierarchyOneId)
        ? parseInt(schoolYearInfo.hierarchyOneId, 10)
        : null,
      applied_hierarchy_two: /^\d+$/.test(schoolYearInfo.hierarchyTwoId)
        ? parseInt(schoolYearInfo.hierarchyTwoId, 10)
        : null,
    };

    if (!dataSet.start_date || isNaN(new Date(dataSet.start_date).getTime())) {
      // 여기서 dataSet.start_date가 유효하지 않은 날짜일 경우의 로직을 처리합니다.
      alert("유효하지 않은 시작날짜입니다.");
      return;
    }

    if (!dataSet.end_date || isNaN(new Date(dataSet.end_date).getTime())) {
      alert("유효하지 않은 종료날짜입니다.");
      return;
    }

    const duplicate = schoolYearPeriodData.find(
      (r) =>
        r.school_year_id === dataSet.school_year_id &&
        r.applied_hierarchy_one === dataSet.applied_hierarchy_one &&
        r.applied_hierarchy_two === dataSet.applied_hierarchy_two
    );

    if (duplicate) {
      alert("이미 존재하는 날짜 설정입니다.");
      return;
    }

    setIsLoadingModal(true);
    createSchoolYearPeriod.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setAddTr(false);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    // console.log("delete id", id);
    setIsLoadingModal(true);
    deleteSchoolYearPeriod.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["schoolYearPeriod"]);
        setDatesRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-4">
          <h3>
            학년도 날짜설정{" "}
            <HelpHyperLink link={"https://youtu.be/ng_qpvQVVvw"} />
          </h3>
        </div>
        <div className="col-6">
          <div className="d-flex">
            <div className="align-self-center me-2">학년도 선택</div>
            <div>
              <DropdownSchoolYear
                settingData={settingData}
                handleDropdownChange={handleSchoolYearDropdownChange}
                schoolYearId={schoolYearInfo.schoolYearId}
                allYears={false}
              />
            </div>
          </div>
        </div>
      </div>
      <table className="table table-bordered text-center">
        <thead>
          <tr className="table-secondary">
            <th style={{ width: "15%" }}>학년도</th>
            <th style={{ width: "15%" }}>시작날짜</th>
            <th style={{ width: "15%" }}>종료날짜</th>
            <th style={{ width: "10%" }}>
              수업일수
              <br />
              (선택)
            </th>
            <th style={{ width: "15%" }}>
              적용과정
              <br />
              (계층1)
            </th>
            <th style={{ width: "15%" }}>
              적용학년
              <br />
              (계층2)
            </th>
            <th style={{ width: "15%" }}>
              {addTr ? (
                <button className="btn btn-warning" onClick={handleAddTr}>
                  취소
                </button>
              ) : (
                <button className="btn btn-primary" onClick={handleAddTr}>
                  추가
                </button>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {addTr && (
            <tr>
              <td>{schoolYearInfo?.schoolYear}</td>
              <td>
                <input
                  className="input-like-span"
                  required
                  type="date"
                  name="start_date"
                  value={createData?.start_date}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <input
                  className="input-like-span"
                  required
                  type="date"
                  name="end_date"
                  value={createData?.end_date}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <input
                  className="input-like-span"
                  type="number"
                  name="class_days"
                  value={createData?.class_days}
                  onChange={(e) => handleCreateTr(e)}
                />
              </td>
              <td>
                <DropdownHierarchyOne
                  settingData={settingData}
                  handleDropdownChange={handleSchoolYearDropdownChange}
                  schoolYear={schoolYearInfo.schoolYear}
                  hierarchyOneId={schoolYearInfo.hierarchyOneId}
                  hierarchyOneTitle={"전체"}
                />
              </td>
              <td>
                <DropdownHierarchyTwo
                  settingData={settingData}
                  handleDropdownChange={handleSchoolYearDropdownChange}
                  schoolYear={schoolYearInfo.schoolYear}
                  hierarchyOneId={schoolYearInfo.hierarchyOneId}
                  hierarchyTwoId={schoolYearInfo.hierarchyTwoId}
                  hierarchyTwoTitle={"전체"}
                />
              </td>
              <td>
                <button
                  type="button"
                  onClick={submitCreateTr}
                  className="btn btn-sm p-1 me-1 btn-primary"
                >
                  추가
                </button>
              </td>
            </tr>
          )}
          {datesRows &&
            datesRows.map((row, index) => (
              <tr key={row.id}>
                <td>{row?.school_year}</td>
                <td>
                  <input
                    className="input-like-span"
                    required
                    type="date"
                    name="start_date"
                    value={row?.start_date}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    className="input-like-span"
                    required
                    type="date"
                    name="end_date"
                    value={row?.end_date}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <input
                    className="input-like-span"
                    type="number"
                    name="class_days"
                    value={row?.class_days}
                    onChange={(e) => handleTrChange(index, e)}
                  />
                </td>
                <td>
                  <DropdownHierarchyOne
                    settingData={settingData}
                    handleDropdownChange={(e) => handleDropdownChange(index, e)}
                    schoolYear={schoolYearInfo?.schoolYear}
                    hierarchyOneId={organSettingInfo[index]?.hierarchyOneId}
                    hierarchyOneTitle={"전체"}
                  />
                </td>
                <td>
                  <DropdownHierarchyTwo
                    settingData={settingData}
                    handleDropdownChange={(e) => handleDropdownChange(index, e)}
                    schoolYear={schoolYearInfo?.schoolYear}
                    hierarchyOneId={organSettingInfo[index]?.hierarchyOneId}
                    hierarchyTwoId={organSettingInfo[index]?.hierarchyTwoId}
                    hierarchyTwoTitle={"전체"}
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => handleSubmit(index)}
                    className="btn btn-sm p-1 me-1 btn-primary"
                  >
                    {savedRows.includes(row.id) ? "저장완료" : "저장"}
                  </button>
                  {confirmDelete.includes(row.id) ? (
                    <>
                      <button
                        type="button"
                        onClick={() => handleDelete(row)}
                        className="btn btn-sm p-1 btn-danger"
                      >
                        확인
                      </button>
                      <button
                        type="button"
                        onClick={() => handleCancelDelete()}
                        className="btn btn-sm p-1 btn-secondary"
                      >
                        취소
                      </button>
                    </>
                  ) : (
                    <button
                      type="button"
                      onClick={() => handleConfirmDelete(row)}
                      className="btn btn-sm p-1 btn-warning"
                    >
                      삭제
                    </button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
