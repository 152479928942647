import { useQuery } from "@tanstack/react-query";
import VariousRecordInputsService from "../service/variousRecordInputs";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const variousRecordInputsService = new VariousRecordInputsService(httpClient);

export default function useVariousRecordInputs(conditions) {
  // const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 variousRecordInputs join해서 가져온다.
  const variousRecordInputsQuery = useQuery(
    ["variousRecordInputs", conditions],
    () => variousRecordInputsService.findAllVariousRecordInputs(conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.record_list_id,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("variousRecordInputs Query Data:", data);
      // },
    }
  );

  // const createVariousRecordInputs = useMutation(
  //   (data) => variousRecordInputsService.createVariousRecordInputs(data),
  //   {
  //     onSuccess: () => queryClient.invalidateQueries(["variousRecordInputs"]),
  //     onError: (error) => {
  //       console.error("Error creating:", error);
  //     },
  //   }
  // );

  // const updateVariousRecordInputs = useMutation(
  //   (data) => variousRecordInputsService.updateVariousRecordInputs(data),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["variousRecordInputs"]);
  //     },
  //     onError: (error) => {
  //       // Handle the failure by displaying an error message or performing other actions
  //       console.error("Error updating variousRecordInputs:", error);
  //       alert(error);
  //       // Add your error handling logic here
  //     },
  //   }
  // );

  // const deleteVariousRecordInputs = useMutation(
  //   (data) => variousRecordInputsService.deleteVariousRecordInputs(data),
  //   {
  //     onSuccess: () => {
  //       // Refetch the query first
  //       queryClient.invalidateQueries(["variousRecordInputs"]);
  //     },
  //   }
  // );

  return {
    variousRecordInputsQuery,
    // createVariousRecordInputs,
    // updateVariousRecordInputs,
    // deleteVariousRecordInputs,
  };
}
