import React, { useState } from "react";
import useDeveloper from "../../../hooks/useDeveloper";

export default function AddAuthority({
  setIsUploading,
  isUploading,
  setSuccess,
  menuNamesData,
}) {
  const [authorityInfo, setAuthorityInfo] = useState({});

  const { createAuthorities } = useDeveloper();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAuthorityInfo((authorityInfo) => ({ ...authorityInfo, [name]: value }));

    console.log(authorityInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUploading(true);

    createAuthorities.mutate(authorityInfo, {
      onSuccess: (authorityInfo) => {
        setSuccess(authorityInfo + "가 성공적으로 생성되었습니다.");
        setTimeout(() => {
          setSuccess(null);
        }, 4000);
        setIsUploading(false);
      },
    });
  };

  return (
    <form className="row g-2" onSubmit={handleSubmit}>
      <div className="col-auto">
        <select
          className="form-control m-2"
          name="menunames_id"
          required
          onChange={handleChange}
        >
          <option value="">페이지선택</option>
          {menuNamesData &&
            menuNamesData.map((menu, index) => (
              <option key={menu.id} value={menu.id}>
                {menu.categoryname}
              </option>
            ))}
        </select>
      </div>

      <div className="col-auto">
        <select
          className="form-control m-2"
          name="authority"
          required
          onChange={handleChange}
        >
          <option value="">권한단계</option>
          <option value="접근">접근</option>
          <option value="읽기">읽기</option>
          <option value="쓰기">쓰기</option>
          <option value="수정">수정</option>
          <option value="삭제">삭제</option>
        </select>
      </div>

      <div className="col-auto">
        <input
          type="text"
          className="form-control m-2"
          placeholder="권한설명"
          name="description"
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <select
          className="form-control m-2"
          name="schools_status"
          required
          onChange={handleChange}
        >
          <option value="">패키지단계</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>

      <div className="col-auto">
        <select
          className="form-control m-2"
          name="schools_usergroup"
          required
          onChange={handleChange}
        >
          <option value="">유저그룹</option>
          <option value="교사">교사</option>
          <option value="학생">학생</option>
          <option value="부모">부모</option>
          <option value="관리자">관리자</option>
        </select>
      </div>

      <div className="col-auto">
        <input
          type="text"
          className="form-control m-2"
          placeholder="맞춤형학교"
          name="schools_id"
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <button
          className="btn btn-primary text-nowrap m-2"
          type="submit"
          disabled={isUploading}
        >
          {isUploading ? "업로드중..." : "생성하기"}
        </button>
      </div>
    </form>
  );
}
