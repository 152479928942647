import React, { useMemo } from "react";
import { createFilteredAndSortedOptions } from "../../util/dealingArrObj";

export default function DropdownHierarchyThree({
  settingData,
  handleDropdownChange,
  schoolYear,
  hierarchyTwoId,
  hierarchyThreeId,
  parentId = true,
  hierarchyThreeTitle = "반",
  extraOption = false,
  extraOptionTitle = "",
  required = false,
  disabled = false,
}) {
  const options = useMemo(() => {
    const filters = {
      hierarchy_level: 3,
      school_year: schoolYear,
      ...(parentId && { parent_id: hierarchyTwoId }), // 조건부 스프레드
    };

    const sortKey = parentId ? "order" : "group_name";

    return createFilteredAndSortedOptions({
      arrayData: settingData,
      displayKey: "group_name",
      valueKey: "id",
      filters,
      idKey: "id",
      sortKey,
      isDesc: false,
      dataName: "organization_path",
    });
  }, [schoolYear, parentId, hierarchyTwoId, settingData]);

  return (
    <select
      className="form-control"
      name="3"
      onChange={handleDropdownChange}
      value={hierarchyThreeId || ""}
      required={required}
      disabled={disabled}
    >
      {extraOption && <option value="">{extraOptionTitle}</option>}
      <option value="0">{hierarchyThreeTitle}</option>
      {settingData?.length > 0 && options}
    </select>
  );
}
