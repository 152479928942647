// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import { AuthContextProvider } from "../../context/AuthContext";
import LoginBoard from "./components/LoginBoard";
import LoginMain from "./components/LoginMain";
// import { useNavigate } from "react-router-dom";
// import { onUserStateChange } from "../../api/firebase";

function Login() {
  // useEffect(() => {
  //   onUserStateChange((user) => {
  //     console.log(user);
  //     setGoogleUser(user);
  //   });
  // }, []);

  // const { currentUser } = useContext(AuthContextProvider);
  // const navigate = useNavigate();
  // console.log(currentUser);
  // useEffect(() => {
  //   if (currentUser) {
  //     if (
  //       currentUser.username &&
  //       currentUser.schoolid &&
  //       currentUser.role &&
  //       currentUser.userid
  //     ) {
  //       navigate("/teachercareer");
  //     }
  //   }
  // }, [currentUser, navigate]);

  return (
    <div>
      <div className="d-flex justify-content-space-between flex-wrap login align-items-center">
        <div className="col-sm-7">
          <LoginBoard />
        </div>
        <div className="col-sm-5">
          <LoginMain />
        </div>
      </div>
    </div>
  );
}

export default Login;
