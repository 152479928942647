import React, { useEffect, useState } from "react";
import CreateNewYear from "./components/CreateNewYear";
import OrganizationTable from "./components/OrganizationTable";
import { useLocation } from "react-router-dom";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import LoadingModal from "../../unicomponents/LoadingModal";
import AddUnitModal from "./components/AddUnitModal";
import useDeveloper from "../../hooks/useDeveloper";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import SetLevelName from "./components/SetLevelName";
import AddHrTeacherModal from "./components/AddHrTeacherModal";

function OrganizationSetting() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { schoolid: schoolId };

  const [isUpLoading, setIsUpLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hrTeacherModalOpen, setHrTeacherModalOpen] = useState(false);
  const [unitInfo, setUnitInfo] = useState({});
  const [schoolYear, setSchoolYear] = useState(null);
  const [filteredSetting, setFilteredSetting] = useState({});
  const [hideIcons, setHideIcons] = useState(false);

  const {
    settingQuery: { error, data: settingData },
    createOrganizationUnit,
    updateOrganizationUnit,
    deleteOrganizationUnit,
  } = useOrganizationSetting(conditions);

  const {
    schoolQueryBySchoolId: { data: schoolData },
  } = useDeveloper(conditions);

  const {
    levelNameQuery: { data: levelNameData },
  } = useOrganizationSetting();

  // 아아... 오랜 시간 동안 한참 헤매다가 드디어 해결했다... ㅠ.ㅠ 이것 꼭 기억하자.
  // 페이지 로드 후 initialSchoolYear를 1번만 지정하고 그것으로 필터한 정보를 테이블로 보내려고 했다.
  // 그런데 useQuery로 하니... useEffect에서 하는 1번 패치 후 dependency를 []로 해서 멈춤을 사용하기가 어려웠다.
  // 그래서 useQuery 최초 부르는 곳으로 가서 작업해야 하나 엄청 이것 저것 시도했지만 실패.
  // 데이터를 계속 리패치 하니, initialSchoolYear도 수시로 리패치 된다.
  // 이래서 온갖 방법을 시도해 보면 ai에게 물어보며 했다가... 아래처럼 조건문으로 해결함. 이건 괜찮은 거겠지?
  useEffect(() => {
    const copiedData = settingData.slice();
    // console.log("copiedData", copiedData);
    if (schoolYear !== null) {
      const filtered = copiedData.filter((r) => r.school_year === schoolYear);
      setFilteredSetting(filtered);

      // console.log("schoolYear", schoolYear);
      // console.log("filtered", filtered);
    } else {
      let initialSchoolYear = null;
      if (copiedData && copiedData.length > 0) {
        initialSchoolYear = copiedData.sort(
          (a, b) => b.school_year - a.school_year
        )[0].school_year;
      }

      setSchoolYear(initialSchoolYear);
      const filtered = copiedData.filter(
        (r) => r.school_year === initialSchoolYear
      );

      // console.log("initialSchoolYear2", initialSchoolYear);
      // console.log("filtered2", filtered);
      setFilteredSetting(filtered);
    }
  }, [settingData, schoolYear]);

  const handleSchoolYearChange = (e) => {
    const { value } = e.target;
    // console.log("value", value);
    setHideIcons(false);
    setSchoolYear(value);
    // console.log("value", value);
    // console.log("schoolYear", schoolYear);
  };

  return (
    <div>
      {isUpLoading && <LoadingModal />}
      {error && error.toString()}
      <div className="ms-3 me-3">
        <div className="container-fluid">
          <div className="row mb-4">
            {modalOpen && (
              <AddUnitModal
                setModalOpen={setModalOpen}
                unit={unitInfo}
                settingData={settingData}
                levelNameData={levelNameData}
                conditions={conditions}
              />
            )}
            {hrTeacherModalOpen && (
              <AddHrTeacherModal
                setHrTeacherModalOpen={setHrTeacherModalOpen}
                unit={unitInfo}
                settingData={settingData}
                levelNameData={levelNameData}
                conditions={conditions}
                setIsUpLoading={setIsUpLoading}
              />
            )}

            <div className="d-flex">
              <h3 className="fw-bold mt-3">학년도 재적 설정</h3>
              <HelpHyperLink link={"https://youtu.be/JPkVdiK8NLE"} />
            </div>
            <CreateNewYear
              settingData={settingData}
              handleSchoolYearChange={handleSchoolYearChange}
              setIsUpLoading={setIsUpLoading}
              schoolId={schoolId}
              createOrganizationUnit={createOrganizationUnit}
              schoolData={schoolData}
              schoolYear={schoolYear}
              updateOrganizationUnit={updateOrganizationUnit}
              deleteOrganizationUnit={deleteOrganizationUnit}
              setSchoolYear={setSchoolYear}
              setHideIcons={setHideIcons}
              filteredSetting={filteredSetting}
            />
          </div>
          <SetLevelName
            schoolId={schoolId}
            schoolYear={schoolYear}
            levelNameData={levelNameData}
            setIsUpLoading={setIsUpLoading}
          />
          <OrganizationTable
            settingData={filteredSetting}
            setIsUpLoading={setIsUpLoading}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            unitInfo={unitInfo}
            setUnitInfo={setUnitInfo}
            schoolYear={schoolYear}
            hideIcons={hideIcons}
            setHrTeacherModalOpen={setHrTeacherModalOpen}
          />
        </div>
      </div>
    </div>
  );
}
export default OrganizationSetting;
