export async function uploadImage(file, folderSchool, folderUsername, prefix) {
  const data = new FormData();

  // 파일명에 접두사를 추가하여 새로운 File 객체를 생성
  const newFile = new File([file], prefix + file.name, { type: file.type });

  data.append("file", newFile);
  data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET);
  data.append("folder", `eduseed/${folderSchool}/${folderUsername}`);

  return fetch(process.env.REACT_APP_CLOUDINARY_URL, {
    method: "POST",
    body: data,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      //   console.log("data", data);
      return data.url;
    });
}

//signed로 해야지 publicId로 파일을 덮어쓸 수 있다고 한다. 이건 다음에 개발하자.
// export async function uploadImage(file, foldername, publicId) {
//     const data = new FormData();

//     data.append("file", file);
//     data.append("upload_preset", process.env.REACT_APP_CLOUDINARY_PRESET);
//     data.append("folder", `eduseed/${foldername}`);

//     if (publicId) {
//       data.append("public_id", publicId);
//       data.append("overwrite", true);
//     }

//     return fetch(process.env.REACT_APP_CLOUDINARY_URL, {
//       method: "POST",
//       body: data,
//     })
//       .then((res) => {
//         return res.json();
//       })
//       .then((data) => {
//         console.log("data", data);
//         return data.url;
//       });
//   }
