import React, { useEffect, useState } from "react";
import useUsers from "../../hooks/useUsers";
import { useLocation } from "react-router-dom";
import UniTable from "../../unicomponents/UniTable";
import { MdCheck, MdSave } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import { convertToYearMonthDayFormat } from "../../util/formatdate";
import AddStudent from "./components/AddStudent";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";

export default function BasicInfo() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { schoolid: schoolId, role: "student" };
  const [rows, setRows] = useState([]);
  const {
    usersQuery: { isLoading, data: userData },
    updateUser,
    deleteUser,
  } = useUsers(conditions);

  useEffect(() => {
    const copiedData = { ...userData };
    const arrayData = Object.keys(copiedData).map((key) => ({
      id: parseInt(key),
      ...copiedData[key],
    }));
    setRows(arrayData);
  }, [userData]);

  // console.log("userData", userData);

  const [isUploading, setIsUploading] = useState(false);
  const [success, setSuccess] = useState("");
  const [successSave, setSuccessSave] = useState();
  const [isSaved, setIsSaved] = useState({});

  const [rowModesModel, setRowModesModel] = useState({});

  const updateData = (updatedRow) => {
    setIsUploading(true);

    let updatedUserData = { ...updatedRow };
    if (updatedRow.birthday) {
      let formattedValue = convertToYearMonthDayFormat(updatedRow.birthday);
      updatedUserData = { ...updatedUserData, birthday: formattedValue };
    }

    updateUser.mutate(updatedUserData, {
      onSuccess: (successMessage) => {
        setSuccessSave(successMessage);
        setIsSaved((prevState) => ({ ...prevState, [updatedRow.id]: true }));
        setTimeout(() => {
          setSuccessSave(null);
          setIsSaved((prevState) => ({ ...prevState, [updatedRow.id]: false }));
        }, 4000);
        setIsUploading(false);
      },
      onError: (error) => {
        setIsUploading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleSaveClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });
    // console.log("updatedData", id);
  };

  const handleDeleteClick = (id) => {
    // Confirmation prompt
    if (
      window.confirm("정말 이 유저를 삭제하시겠습니까? 신중하게 생각하세요")
    ) {
      setIsUploading(true);
      deleteUser.mutate(id, {
        onSuccess: (successMessage) => {
          setRows(rows.filter((row) => row.id !== id));
          setIsUploading(false);
        },
        onError: (error) => {
          setIsUploading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert("에러: " + error.message);
        },
      });
    } else {
      // User clicked 'Cancel', do nothing
      console.log("취소되었습니다.");
    }
  };

  const columns = [
    {
      field: "username",
      headerAlign: "center",
      headerName: "사용자아이디",
      align: "left",
      width: 250,
    },
    {
      field: "password",
      headerAlign: "center",
      headerName: "Password",
      width: 150,
      align: "center",
      type: "password",
      valueGetter: (params) => {
        return params.value ? "*******" : "*******";
      },
      editable: true,
    },
    {
      field: "fullname",
      headerAlign: "center",
      headerName: "이름",
      align: "center",
      width: 100,
      editable: true,
    },
    {
      field: "gender",
      headerAlign: "center",
      headerName: "성별",
      align: "center",
      width: 100,
      type: "singleSelect",
      valueOptions: ["남", "여"],
      editable: true,
    },
    {
      field: "birthday",
      headerAlign: "center",
      headerName: "생년월일",
      align: "center",
      width: 200,
      type: "date",
      valueGetter: (params) => {
        return params.value ? new Date(params.value) : null;
      },
      editable: true,
    },
    {
      field: "idatschool",
      headerAlign: "center",
      headerName: "학번",
      align: "center",
      width: 150,
      editable: true,
    },
    {
      field: "status",
      headerAlign: "center",
      headerName: "상태",
      align: "center",
      width: 100,
      type: "singleSelect",
      getOptionValue: (value) => value.status,
      getOptionLabel: (value) => value.statusName,
      valueOptions: [
        { status: 1, statusName: "활성" },
        { status: 0, statusName: "비활성" },
      ],
      valueFormatter: (params) => (params.value === 1 ? "활성" : "비활성"),
      editable: true,
    },
    {
      field: "saveDelete",
      headerAlign: "center",
      headerName: "저장/삭제",
      align: "center",
      width: 100,
      type: "actions",
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={isSaved[id] ? <MdCheck size={20} /> : <MdSave size={20} />}
          label="저장"
          className="saveDataBtn"
          onClick={() => handleSaveClick(id)}
        />,
        <GridActionsCellItem
          icon={<MdDelete size={20} />}
          label="삭제"
          onClick={() => handleDeleteClick(id)}
        />,
      ],
    },
  ];

  return (
    <>
      {isLoading && <p>Loading...</p>}
      <div className="ms-3 pe-3" style={{ width: "96vw" }}>
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="d-flex">
              <h3 className="fw-bold mt-3">학생 계정 관리</h3>
              <HelpHyperLink link={"https://youtu.be/Ro3PpsdRAik"} />
            </div>
            <AddStudent
              isUploading={isUploading}
              setSuccess={setSuccess}
              setIsUploading={setIsUploading}
              rows={rows}
            />
            {success && <p>✅{success}</p>}
          </div>
          <div style={{ width: "94vw", overflowX: "auto", maxWidth: "1536px" }}>
            {successSave && <p>✅{successSave}</p>}
            {userData?.length > 0 && (
              <UniTable
                columns={columns}
                rows={rows}
                exportedFileName="학생명단"
                rowModesModel={rowModesModel}
                setRowModesModel={setRowModesModel}
                setRows={setRows}
                updateData={updateData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
