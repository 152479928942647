import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useUsers from "../../hooks/useUsers";

//부모 메뉴 이름 1개, 하위 메뉴들 리스트(하위 메뉴들은 이미 parentMenu에 필터된 것)
export default function MyProfile({ subMenus, user }) {
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  const {
    userTeacherInfoQuery: { data: userData },
  } = useUsers();

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await logout();
      navigate("/login");
    } catch (err) {
      console.log(err.response.data);
      if (err.response.data.error) {
        console.log(err.response.data.error);
      } else {
        console.log(
          "알 수 없는 오류가 발생했습니다. 새로고침 후 다시 시도하고, 오류가 반복되면 관리자에게 문의하세요."
        );
      }
    }
  };

  let userRole;
  if (user) {
    if (user.role === "admin") {
      userRole = "teacher";
    } else {
      userRole = user.role;
    }
  }

  return (
    <div className="dropdown-center">
      <img
        src={
          userData && userData.profile_link !== null
            ? userData.profile_link
            : "/images/default_profile.png"
        }
        className="navbarImg dropdown-toggle"
        alt="내사진"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        height="45"
        width="45"
        style={{ objectFit: "cover", borderRadius: 9999 }}
      />
      <ul className="dropdown-menu dropdown-menu-end">
        {subMenus &&
          subMenus
            .filter(
              (menu) =>
                menu.components === "Appbar" &&
                menu.parentcategory === "profile"
            )
            .sort(function (a, b) {
              return a.categoryorder - b.categoryorder;
            })
            .map((value, key) => (
              <li key={value.id}>
                {value.link === "logout" ? (
                  <button className="dropdown-item" onClick={handleClick}>
                    {value.categoryname}
                  </button>
                ) : value.link !== "none" ? (
                  <Link
                    className="dropdown-item"
                    component={Link}
                    to={
                      user?.schoolid
                        ? `/${user.schoolid}/${userRole}${value.link}`
                        : "/login"
                    }
                  >
                    {value.categoryname}
                  </Link>
                ) : (
                  <Link className="dropdown-item">{value.categoryname}</Link>
                )}
              </li>
            ))}
      </ul>
    </div>
  );
}
