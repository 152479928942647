export default class LessonsService {
  constructor(http) {
    this.http = http;
  }

  async findAllLessons(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/lessons/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createLesson(data) {
    return this.http.fetch(`/api/lessons/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateLesson(data) {
    return this.http.fetch(`/api/lessons/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async updateLessonTags(data) {
    return this.http.fetch(`/api/lessons/${data.id}/tags`, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
  }

  async deleteLesson(id) {
    return this.http.fetch(`/api/lessons/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
