import React from "react";
import { Link } from "react-router-dom";

//부모 메뉴 이름 1개, 하위 메뉴들 리스트(하위 메뉴들은 이미 parentMenu에 필터된 것)
export default function MenuDropdown({ parentMenu, subMenus, user }) {
  let userRole;
  if (user) {
    if (user.role === "admin") {
      userRole = "teacher";
    } else {
      userRole = user.role;
    }
  }

  return (
    <div className="dropdown" key={parentMenu.id}>
      <button
        className="btn btn-sm dropdown-toggle"
        type="button"
        key={parentMenu.id}
        component={Link}
        to={parentMenu.link}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {parentMenu.categoryname}
      </button>
      <ul className="dropdown-menu">
        {subMenus
          .sort(function (a, b) {
            return a.categoryorder - b.categoryorder;
          })
          .map((value) => {
            if (value.parentcategory.toString() === parentMenu.id.toString()) {
              if (value.link !== "none") {
                return (
                  <li key={value.id}>
                    <Link
                      className="dropdown-item"
                      to={
                        user?.schoolid
                          ? `/${user.schoolid}/${userRole}${value.link}`
                          : "/login"
                      }
                    >
                      {value.categoryname}
                    </Link>
                  </li>
                );
              } else {
                return (
                  <li key={value.id}>
                    <Link className="dropdown-item">{value.categoryname}</Link>
                  </li>
                );
              }
            }
            return null;
          })}
      </ul>
    </div>
  );
}
