import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/login");
  }, 4000);
  return (
    <div>
      <h2>Not Found (없는 경로입니다. 3초 후 대시보드로 돌아갑니다.)</h2>
    </div>
  );
}
