import React, { useMemo } from "react";
import { createFilteredAndSortedUniqueOptions } from "../../util/dealingArrObj";

/*
arrayData에 있는 키여야 한다! 그리고 이 arrayData는 registration에서 학년도와 username을 기준으로 organizatipn_path를 갖고 있어야 한다.
arrayData: 화면에 보에는 데이터. 보통 rows,
     displayKey: 보통 "fullname",
     valueKey: 보통 "username",
     filters: 객체로 들어와야 한다. registration 같은 경우엔 보통 { school_year: schoolYearId 또는 schoolYear, organization_path: organPath },
     uniqueKey: 보통 "username", 이걸 기준으로 unique 할 것임
     sortKey: 보통 "fullname", 또는 studentno 일 수도 있다. order 라든지
     isDesc: false,


     ... 만들다 보니 이거 user만 아니라 범용적으로 사용할 수 있겠는데?
     하지만 또 너무 범용적이면 헷갈리까 이건 user 중심으로 쓰자.

*/
export default function DropdownUniqueUsersInDisplayData({
  displayData,
  displayKey,
  valueKey,
  filters = {},
  uniqueKey,
  sortKey,
  isDesc = false,
  handleDropdownChange,
  selectedUser,
  selectName = "users",
  dropdownTitle = "유저선택",
  required = false,
  styleClass = "form-control",
  highLight = false,
  disabled = false,
  isTitle = true,
}) {
  // console.log("filters", filters);
  const options = useMemo(() => {
    return createFilteredAndSortedUniqueOptions({
      arrayData: displayData,
      displayKey: displayKey,
      valueKey: valueKey,
      filters: filters,
      uniqueKey: uniqueKey,
      sortKey: sortKey,
      isDesc: isDesc,
    });
  }, [displayData, displayKey, valueKey, filters, uniqueKey, sortKey, isDesc]);

  // console.log("options", options);

  const combinedClassName = `${styleClass} ${
    highLight ? "highlight-validate" : ""
  }`;

  return (
    <select
      className={combinedClassName}
      name={selectName}
      onChange={handleDropdownChange}
      value={selectedUser || ""}
      required={required}
      disabled={disabled}
    >
      {isTitle && <option value="">{dropdownTitle}</option>}
      {displayData?.length > 0 && options}
    </select>
  );
}
