import React from "react";
import NamusupCustomTable from "./customTables/NamusupCustomTable";
import LessonStudentsTable from "./LessonStudentsTable";

export default function CustomTableSwitch({
  evaluationType,
  lessonStudentsData,
  lessonSetting,
  lessonInfo,
  setLessonStudentsData,
  updateLessonStudents,
  updateLessonStudentsBulk,
  deleteLessonStudents,
  evalItemsData,
  schoolId,
}) {
  const renderComponent = () => {
    switch (schoolId) {
      case "namusup":
        return (
          <NamusupCustomTable
            lessonStudentsData={lessonStudentsData}
            setLessonStudentsData={setLessonStudentsData}
            evaluationType={evaluationType}
            lessonSetting={lessonSetting}
            updateLessonStudents={updateLessonStudents}
            updateLessonStudentsBulk={updateLessonStudentsBulk}
            deleteLessonStudents={deleteLessonStudents}
            lessonInfo={lessonInfo}
          />
        );
      default:
        return (
          <LessonStudentsTable
            lessonStudentsData={lessonStudentsData}
            setLessonStudentsData={setLessonStudentsData}
            evaluationType={evaluationType}
            lessonSetting={lessonSetting}
            updateLessonStudents={updateLessonStudents}
            updateLessonStudentsBulk={updateLessonStudentsBulk}
            deleteLessonStudents={deleteLessonStudents}
            lessonInfo={lessonInfo}
          />
        );
    }
  };

  return <>{renderComponent()}</>;
}
