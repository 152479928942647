import React, { useEffect, useState } from "react";
import useTermPeriod from "../../../../hooks/useTermPeriod";
import styles from "../css/AttendanceStatisticsTable.module.css";

export default function AttendanceStatisticsTable({
  rows,
  attendanceRecord,
  organSettingInfo,
  selectedMonth,
  conditions,
}) {
  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod(conditions);

  const [thisTerm, setThisTerm] = useState([]);

  useEffect(() => {
    if (termPeriodData && organSettingInfo.schoolYearId) {
      // const filtered = termPeriodData.filter(
      //   (r) =>
      //     r.school_year_id.toString() ===
      //     organSettingInfo.schoolYearId.toString()
      // );
      // console.log("filtered", filtered);

      let filteredPeriod = [];
      let hierarchyOnePeriod = [];
      let hierarchyTwoPeriod = [];

      //hierarchy와 일치하는 것이 있으면 그것만 나오고, 일치하는 것이 없으면 전체만 나오도록...

      filteredPeriod = termPeriodData.filter(
        (r) =>
          r.school_year_id.toString() ===
          organSettingInfo.schoolYearId.toString()
      );

      hierarchyOnePeriod = filteredPeriod.filter(
        (r) =>
          r.applied_hierarchy_one !== null &&
          r.applied_hierarchy_one === Number(organSettingInfo.hierarchyOneId)
      );

      if (hierarchyOnePeriod.length > 0) {
        filteredPeriod = hierarchyOnePeriod;
      } else {
        filteredPeriod = filteredPeriod.filter(
          (r) => r.applied_hierarchy_one === null
        );
      }

      // console.log("중간 filteredPeriod", filteredPeriod);
      if (organSettingInfo.hierarchyTwoId) {
        hierarchyTwoPeriod = filteredPeriod.filter(
          (r) =>
            r.applied_hierarchy_two !== null &&
            r.applied_hierarchy_two === Number(organSettingInfo.hierarchyTwoId)
        );

        if (hierarchyTwoPeriod.length > 0) {
          filteredPeriod = hierarchyTwoPeriod;
        } else {
          filteredPeriod = filteredPeriod.filter(
            (r) => r.applied_hierarchy_two === null
          );
        }
      }
      if (filteredPeriod.length > 0) {
        filteredPeriod = filteredPeriod.sort((a, b) => {
          // hierarchy_two_name이 "전체"일 경우 우선 정렬
          if (
            a.hierarchy_two_name === "전체" &&
            b.hierarchy_two_name !== "전체"
          ) {
            return -1; // a가 b보다 앞에 오도록
          } else if (
            a.hierarchy_two_name !== "전체" &&
            b.hierarchy_two_name === "전체"
          ) {
            return 1; // b가 a보다 앞에 오도록
          }

          // hierarchy_one_name이 "전체"일 경우 우선 정렬
          if (
            a.hierarchy_one_name === "전체" &&
            b.hierarchy_one_name !== "전체"
          ) {
            return -1;
          } else if (
            a.hierarchy_one_name !== "전체" &&
            b.hierarchy_one_name === "전체"
          ) {
            return 1;
          }

          return 0; // 그 외의 경우 순서 유지
        });
      }

      // const thisTermDates =
      //   filteredPeriod.length > 0 ? filteredPeriod[0] : null;

      // console.log("filteredPeriod this term", filteredPeriod);

      setThisTerm(filteredPeriod);
    }
  }, [
    organSettingInfo.hierarchyOneId,
    organSettingInfo.hierarchyTwoId,
    organSettingInfo.schoolYearId,
    termPeriodData,
  ]);

  //   const schoolYear = "2024";
  //   const selectedMonth = "3";

  const getColorForStatus = (status) => {
    if (status) {
      if (status.includes("출")) {
        return "#B3E2FD"; // Purple for status containing "지"
      } else if (
        status.includes("결") &&
        !status.includes("출") &&
        !status.includes("과")
      ) {
        return "#FFD4E1"; // Purple for status containing "지"
      } else if (
        (status.includes("지") || status.includes("조")) &&
        !status.includes("출") &&
        !status.includes("과")
      ) {
        return "#FFFFE0"; // Purple for status containing "지"
      }
    } else {
      return "#FFFFFF";
    }
  };

  const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

  return (
    <div className="overflow-auto mb-4">
      <table
        className={`tableStyle table table-bordered table-sm text-center ${styles.tableStyle}`}
      >
        <thead>
          <tr>
            {/* Generate table headers for all days of the month */}
            <th style={{ minWidth: "45px" }}>{organSettingInfo.schoolYear}</th>
            {thisTerm &&
              thisTerm?.map((term) => (
                <th key={term.id} style={{ minWidth: "45px" }}>
                  {term.term_name + term.term_category}
                </th>
              ))}
            <th style={{ minWidth: "30px" }}>{selectedMonth}월</th>
            <th style={{ minWidth: "60px" }}>이름</th>
            {[
              ...Array(
                daysInMonth(organSettingInfo.schoolYear, selectedMonth)
              ).keys(),
            ].map((day) => (
              <th style={{ minWidth: "30px" }} key={day}>
                {day + 1}
              </th> // Day of the month
            ))}
          </tr>
        </thead>
        <tbody>
          {rows &&
            attendanceRecord &&
            rows.map((student, index) => {
              // console.log("attendanceRecord", attendanceRecord);

              const filteredFirst = attendanceRecord.filter((r) => {
                return r.username === student.username;
              });

              // console.log("filtered1", filtered);
              const filteredByMonth = filteredFirst.filter((r) => {
                const date = new Date(r.date); // r.date 문자열을 Date 객체로 변환
                const month = date.getMonth(); // getMonth()는 0부터 시작하는 월을 반환
                return (
                  month === Number(selectedMonth) - 1 &&
                  r.attendance_status !== ""
                );
              });

              const yearCount = filteredFirst.filter(
                (r) =>
                  r.attendance_status !== "질결" &&
                  r.attendance_status !== "무결" &&
                  r.attendance_status !== "기결" &&
                  r.attendance_status !== ""
              );

              // console.log("attendanceRecord", attendanceRecord);

              // console.log("yearCount", filtered);
              // console.log("filteredByMonth", filteredByMonth);
              return (
                <tr key={index}>
                  <th>{yearCount.length}</th>
                  {thisTerm.map((term) => {
                    const termCount = filteredFirst.filter(
                      (r) =>
                        r.attendance_status !== "질결" &&
                        r.attendance_status !== "무결" &&
                        r.attendance_status !== "기결" &&
                        r.attendance_status !== "" &&
                        r.date >= term.start_date &&
                        r.date <= term.end_date
                    );

                    return <th key={term.id}>{termCount.length}</th>;
                  })}
                  <th>
                    {
                      filteredByMonth.filter((r) => {
                        return (
                          r.attendance_status !== "질결" &&
                          r.attendance_status !== "무결" &&
                          r.attendance_status !== "기결" &&
                          r.attendance_status !== ""
                        );
                      }).length
                    }
                  </th>
                  <td className="align-middle">{student.fullname}</td>

                  {[
                    ...Array(
                      daysInMonth(organSettingInfo.schoolYear, selectedMonth)
                    ).keys(),
                  ].map((day) => {
                    const filtered = filteredByMonth.find((r) => {
                      const date = new Date(r.date).getDate();
                      // console.log("date", date);
                      // console.log("day", day);
                      return date === day + 1;
                    });

                    // console.log("filteredByMonth", filteredByMonth);
                    // console.log("filtered2", filtered);
                    // console.log("day", day);
                    // console.log("day + 1", day + 1);

                    return (
                      <td
                        key={`${filtered?.id}-${day}`}
                        title={filtered?.memo || ""}
                        name={day + 1}
                        id={filtered?.date}
                        style={{
                          backgroundColor: getColorForStatus(
                            filtered?.attendance_status
                          ),
                        }}
                      >
                        {filtered?.attendance_status || ""}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
