export default class SchoolYearPeriodService {
  constructor(http) {
    this.http = http;
  }

  async findAllSchoolYearPeriod(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/schoolyeardates/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createSchoolYearPeriod(data) {
    return this.http.fetch(`/api/schoolyeardates/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateSchoolYearPeriod(data) {
    return this.http.fetch(`/api/schoolyeardates/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async deleteSchoolYearPeriod(id) {
    return this.http.fetch(`/api/schoolyeardates/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
