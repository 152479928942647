import React from "react";
import styles from "../css/DataMigrationModal.module.css";
import { MdClose } from "react-icons/md";

export default function DataMigrationModal({
  isOpen,
  toggleModal,
  jsonData,
  onConfirm,
  isMigrated,
}) {
  if (!isOpen) return null;

  const headers = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div
          className="d-flex justify-content-between"
          style={{ fontSize: "20px", borderBottom: "1px solid" }}
        >
          <h4>최종 확인</h4>
          <MdClose size={27} type="button" onClick={toggleModal} />
        </div>
        <div className={styles.modalContent}>
          <div className="d-flex justify-content-between">
            <p>다음 데이터를 이관하시겠습니까?</p>
            <div>
              <button
                className="btn btn-sm btn-secondary me-2"
                onClick={toggleModal}
              >
                취소
              </button>
              <button className="btn btn-sm btn-primary" onClick={onConfirm}>
                {isMigrated ? "이관 완료" : "최종 실행"}
              </button>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="table-secondary">
                <tr>
                  {headers.map((header) => (
                    <th key={header}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {jsonData.map((row, index) => (
                  <tr key={index}>
                    {headers.map((header) => (
                      <td key={header}>{row[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              className="btn btn-sm btn-secondary me-2"
              onClick={toggleModal}
            >
              취소
            </button>
            <button className="btn btn-sm btn-primary" onClick={onConfirm}>
              {isMigrated ? "이관 완료" : "최종 실행"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
