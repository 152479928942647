import React, { useMemo } from "react";
import UniTable from "../../../../unicomponents/UniTable";

export default function LessonsTable({
  rows,
  handleUpdateClick,
  handleSetEvalItem,
  lessonSetting,
  configMap,
}) {
  /*이제 해야 하는 것은 
1. 수업설정 불러와서 수업 설정에 따라서 column이 나오고 안 나오고 하는 것
2. 저장되어 있는 것에 따라서 가중치가 여러개 나오는 것
3. 수업테스트 누르면 update 되는 모달 
4. create도 연계
5. dropdown에 따라 필터 되는 것 확인 / 본인 과목만 우선 나오게 필터
7. 평가설정 만들기
*/

  // 설정 데이터를 맵으로 변환하여 빠르게 조회할 수 있게 함

  const columns = useMemo(() => {
    const cols = [
      {
        field: "termName",
        headerAlign: "center",
        headerName: "학기",
        align: "center",
        width: 50,
      },
      {
        field: "hierarchyOne",
        headerAlign: "center",
        headerName: "과정",
        align: "center",
        width: 50,
      },
      {
        field: "hierarchyTwo",
        headerAlign: "center",
        headerName: "학년",
        align: "center",
        width: 50,
      },
      {
        field: "hierarchyThree",
        headerAlign: "center",
        headerName: "반",
        align: "center",
        width: 50,
      },
      {
        field: "subject_name",
        headerAlign: "center",
        headerName: "과목명",
        align: "center",
        width: 120,
        renderCell: (params) => (
          <button
            className="btn btn-sm btn-outline-dark p-1 m-1"
            style={{
              "--bs-btn-font-size": ".80rem",
            }}
            onClick={() => handleUpdateClick(params?.row)}
          >
            {params?.value}
          </button>
        ),
      },
      {
        field: "teachers",
        headerAlign: "center",
        headerName: "교사",
        align: "center",
        width: 100,
        renderCell: (params) => {
          const teachers = params.row.teachers;
          return Array.isArray(teachers)
            ? teachers.map((r) => r.tname.toString()).join(",")
            : "";
        },
      },
      {
        field: "lesson_hours",
        headerAlign: "center",
        headerName: "시수",
        align: "center",
        width: 50,
      },
      {
        field: "evaluation_type",
        headerAlign: "center",
        headerName: "평가제",
        align: "center",
        width: 100,
      },
      {
        field: "tags",
        headerAlign: "center",
        headerName: "태그",
        align: "left",
        width: 100,
        renderCell: (params) => {
          const tags = params.row.tags;
          return Array.isArray(tags)
            ? tags
                .map((tagObj) => {
                  const [tagKey] = Object.keys(tagObj);
                  return tagKey.toString();
                })
                .join(",")
            : "";
        },
      },
    ];
    // 각 필드별로 '미사용'이 아닌 경우에만 열 배열에 추가
    if (configMap.subjectSort !== "미사용") {
      cols.push({
        field: "subjectSort",
        headerAlign: "center",
        headerName: "구분",
        align: "center",
        width: 80,
        renderCell: (params) => {
          const extra_attributes = params.row.extra_attributes;
          return extra_attributes?.subjectSort && extra_attributes.subjectSort;
        },
      });
    }
    if (configMap.subjectCategory !== "미사용") {
      cols.push({
        field: "subjectCategory",
        headerAlign: "center",
        headerName: "과목분류",
        align: "center",
        width: 80,
        renderCell: (params) => {
          const extra_attributes = params.row.extra_attributes;
          return (
            extra_attributes?.subjectCategory &&
            extra_attributes.subjectCategory
          );
        },
      });
    }

    if (configMap.subjectCode !== "미사용") {
      cols.push({
        field: "subjectCode",
        headerAlign: "center",
        headerName: "수업코드",
        align: "center",
        renderCell: (params) => {
          const extra_attributes = params.row.extra_attributes;
          return extra_attributes?.subjectCode && extra_attributes.subjectCode;
        },
      });
    }

    if (configMap.subjectOrder !== "미사용") {
      cols.push({
        field: "subjectOrder",
        headerAlign: "center",
        headerName: "순서",
        align: "center",
        width: 50,
        renderCell: (params) => {
          const extra_attributes = params.row.extra_attributes;
          return (
            extra_attributes?.subjectOrder && extra_attributes.subjectOrder
          );
        },
      });
    }

    //여기는 evalArea100 안에 있는 객체 수 만큼 column 만들어야 한다.

    const eval_area_weights = lessonSetting?.filter(
      (setting) => setting.item_code === "evalArea100"
    )[0]?.detailed_setting;
    if (eval_area_weights) {
      eval_area_weights.forEach(function (weight) {
        cols.push({
          field: weight,
          headerAlign: "center",
          headerName: weight,
          align: "center",
          width: 60,
          renderCell: (params) => {
            return params?.row?.eval_area_weights?.[weight] ?? "";
          },
        });
      });
    }

    const evalMethodForItems = lessonSetting?.filter(
      (setting) => setting.item_code === "evalMethod"
    )[0]?.detailed_setting;

    if (evalMethodForItems?.includes("평가항목별")) {
      cols.push({
        field: "evalMethod",
        headerAlign: "center",
        headerName: "평가항목",
        align: "center",
        renderCell: (params) => (
          <button
            className="btn btn-sm btn-outline-secondary p-1 m-1"
            style={{
              "--bs-btn-font-size": ".80rem",
            }}
            onClick={() => handleSetEvalItem(params?.row)}
          >
            항목설정
          </button>
        ),
      });
    }

    return cols;
  }, [
    configMap.subjectSort,
    configMap.subjectCategory,
    configMap.subjectCode,
    configMap.subjectOrder,
    lessonSetting,
    handleUpdateClick,
    handleSetEvalItem,
  ]);

  //   const columns = useMemo(
  //     () => [
  //       {
  //         field: "termName",
  //         headerAlign: "center",
  //         headerName: "학기",
  //         align: "center",
  //         width: 50,
  //       },
  //       {
  //         field: "hierarchyOne",
  //         headerAlign: "center",
  //         headerName: "과정",
  //         align: "center",
  //         width: 50,
  //       },
  //       {
  //         field: "hierarchyTwo",
  //         headerAlign: "center",
  //         headerName: "학년",
  //         align: "center",
  //         width: 50,
  //       },
  //       {
  //         field: "hierarchyThree",
  //         headerAlign: "center",
  //         headerName: "반",
  //         align: "center",
  //         width: 50,
  //       },
  //       {
  //         field: "subject_name",
  //         headerAlign: "center",
  //         headerName: "과목명",
  //         align: "center",
  //         width: 120,
  //         renderCell: (params) => (
  //           <button
  //             className="btn btn-sm btn-outline-dark p-1 m-1"
  //             style={{
  //               "--bs-btn-font-size": ".80rem",
  //             }}
  //             onClick={() => handleUpdateClick(params?.row)}
  //           >
  //             {params?.value}
  //           </button>
  //         ),
  //       },
  //       {
  //         field: "teachers",
  //         headerAlign: "center",
  //         headerName: "교사",
  //         align: "center",
  //         width: 100,
  //         renderCell: (params) => {
  //           const teachers = params.row.teachers;
  //           return Array.isArray(teachers)
  //             ? teachers.map((r) => r.tname.toString()).join(",")
  //             : "";
  //         },
  //       },
  //       {
  //         field: "lesson_hours",
  //         headerAlign: "center",
  //         headerName: "시수",
  //         align: "center",
  //         width: 50,
  //       },
  //       {
  //         field: "evaluation_type",
  //         headerAlign: "center",
  //         headerName: "평가제",
  //         align: "center",
  //         width: 100,
  //       },
  //       {
  //         field: "tags",
  //         headerAlign: "center",
  //         headerName: "태그",
  //         align: "left",
  //         width: 100,
  //         renderCell: (params) => {
  //           const tags = params.row.tags;
  //           return Array.isArray(tags)
  //             ? tags.map((r) => r.toString()).join(",")
  //             : "";
  //         },
  //       },
  //       {
  //         field: "subjectSort",
  //         headerAlign: "center",
  //         headerName: "구분",
  //         align: "center",
  //         width: 80,
  //         renderCell: (params) => {
  //           const extra_attributes = params.row.extra_attributes;
  //           return extra_attributes?.subjectSort && extra_attributes.subjectSort;
  //         },
  //       },
  //       {
  //         field: "subjectCategory",
  //         headerAlign: "center",
  //         headerName: "과목분류",
  //         align: "center",
  //         width: 80,
  //         renderCell: (params) => {
  //           const extra_attributes = params.row.extra_attributes;
  //           return (
  //             extra_attributes?.subjectCategory &&
  //             extra_attributes.subjectCategory
  //           );
  //         },
  //       },
  //       {
  //         field: "subjectCode",
  //         headerAlign: "center",
  //         headerName: "수업코드",
  //         align: "center",
  //         renderCell: (params) => {
  //           const extra_attributes = params.row.extra_attributes;
  //           return extra_attributes?.subjectCode && extra_attributes.subjectCode;
  //         },
  //       },
  //       {
  //         field: "subjectOrder",
  //         headerAlign: "center",
  //         headerName: "순서",
  //         align: "center",
  //         width: 50,
  //         renderCell: (params) => {
  //           const extra_attributes = params.row.extra_attributes;
  //           return (
  //             extra_attributes?.subjectOrder && extra_attributes.subjectOrder
  //           );
  //         },
  //       },
  //       //여기는 evalArea100 안에 있는 객체 수 만큼 column 만들어야 한다.
  //       {
  //         field: "evalArea100",
  //         headerAlign: "center",
  //         headerName: "가중치",
  //         align: "center",
  //         width: 60,
  //         renderCell: (params) => params?.subjectCode,
  //       },
  //       {
  //         field: "evalMethod",
  //         headerAlign: "center",
  //         headerName: "평가항목",
  //         align: "center",
  //         renderCell: (params) => (
  //           <button
  //             className="btn btn-sm btn-outline-secondary p-1 m-1"
  //             style={{
  //               "--bs-btn-font-size": ".80rem",
  //             }}
  //             onClick={() => handleSetEvalItem(params?.row)}
  //           >
  //             평가설정
  //           </button>
  //         ),
  //       },
  //     ],
  //     [handleSetEvalItem, handleUpdateClick]
  //   );

  return (
    <div>
      {rows?.length > 0 && (
        <UniTable columns={columns} rows={rows} exportedFileName="수업정보" />
      )}
    </div>
  );
}
