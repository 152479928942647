import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";

export default function SetLevelName({
  schoolId,
  schoolYear,
  levelNameData,
  setIsUpLoading,
}) {
  const [isModified, setIsModified] = useState(false);
  const [levelOneName, setLevelOneName] = useState({
    schoolid: schoolId,
    school_year: schoolYear,
    hierarchy_level: 1,
    level_name: "",
  });
  const [levelTwoName, setLevelTwoName] = useState({
    schoolid: schoolId,
    school_year: schoolYear,
    hierarchy_level: 2,
    level_name: "",
  });
  const [levelThreeName, setLevelThreeName] = useState({
    schoolid: schoolId,
    school_year: schoolYear,
    hierarchy_level: 3,
    level_name: "",
  });

  const [isSaved, setIsSaved] = useState(false);

  const { updateLevelName } = useOrganizationSetting();

  useEffect(() => {
    setLevelOneName({
      schoolid: schoolId,
      school_year: schoolYear,
      hierarchy_level: 1,
      level_name: "",
    });
    setLevelTwoName({
      schoolid: schoolId,
      school_year: schoolYear,
      hierarchy_level: 2,
      level_name: "",
    });
    setLevelThreeName({
      schoolid: schoolId,
      school_year: schoolYear,
      hierarchy_level: 3,
      level_name: "",
    });

    if (levelNameData) {
      const thisYearLevel = levelNameData.filter(
        (row) => row.school_year === schoolYear
      );

      if (thisYearLevel.length > 0) {
        thisYearLevel.forEach((lev) => {
          if (lev.hierarchy_level === 1) {
            setLevelOneName(lev);
          }
          if (lev.hierarchy_level === 2) {
            setLevelTwoName(lev);
          }
          if (lev.hierarchy_level === 3) {
            setLevelThreeName(lev);
          }
        });
      }
    }
  }, [levelNameData, schoolYear, schoolId]);

  const handleLevelOneNameChange = (e) =>
    setLevelOneName((level) => ({ ...level, level_name: e.target.value }));
  const handleLevelTwoNameChange = (e) =>
    setLevelTwoName((level) => ({ ...level, level_name: e.target.value }));
  const handleLevelThreeNameChange = (e) =>
    setLevelThreeName((level) => ({ ...level, level_name: e.target.value }));

  const insertLevelNames = () => {
    const levelNamesArray = [levelOneName, levelTwoName, levelThreeName];

    // console.log("levelNamesArray", levelNamesArray);
    updateLevelName.mutate(levelNamesArray, {
      onSuccess: () => {
        setIsModified(false);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 2000);
        // setIsUpLoading(false);
      },
      onError: (error) => {
        // setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <div className="border-bottom ">
      <table
        className="table table-bordered text-center"
        style={{ width: "650px" }}
      >
        <tbody>
          <tr>
            <th>계층단계</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>
              <button
                type="button"
                className="btn btn-sm btn-warning"
                data-tooltip-id="levelTooltip"
                data-tooltip-html="<span>과정, 학년, 반이 기본 구조입니다. 바꾸어도 되지만 제 경험상 가능한 이 구조로 쓰기를 추천합니다. <br>
                계층 1단계를 기준으로 생기부가 나뉩니다. 초등과정으로 쓰면 초등생기부 입니다. <br>
                현재 반이 따로 없더라도 반까지 쓰는 것이 좋습니다. <br>
                나중에 언제가 반이 생기게되면, 생활기록부에 양식이 꼬일 것입니다. <br>
                이 구조를 꼭 바꾸는 것이 좋을 것 같으면 개별 연락 주시기 바랍니다.</span>"
                data-tooltip-place="right"
                data-tooltip-variant="info"
                data-tooltip-class-name="text-left"
              >
                설명_필독!
              </button>
              <Tooltip id="levelTooltip" className="text-start" />
            </th>
          </tr>
          <tr>
            <td>계층이름</td>
            <td style={{ width: "120px" }}>
              <input
                type="text"
                className="form-control input-like-span"
                name="levelOne"
                value={levelOneName ? levelOneName.level_name : ""}
                onChange={handleLevelOneNameChange}
                readOnly={!isModified}
              />
            </td>
            <td style={{ width: "120px" }}>
              <input
                type="text"
                className="form-control input-like-span"
                name="levelTwo"
                value={levelTwoName ? levelTwoName.level_name : ""}
                onChange={handleLevelTwoNameChange}
                readOnly={!isModified}
              />
            </td>
            <td style={{ width: "120px" }}>
              <input
                type="text"
                className="form-control input-like-span"
                name="levelThree"
                value={levelThreeName ? levelThreeName.level_name : ""}
                onChange={handleLevelThreeNameChange}
                readOnly={!isModified}
              />
            </td>
            <td>
              {isModified ? (
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  onClick={insertLevelNames}
                >
                  저장
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-sm btn-info"
                  onClick={() => setIsModified(!isModified)}
                >
                  수정
                </button>
              )}
              {isSaved ? "저장완료" : ""}
              {isModified ? (
                <button
                  type="button"
                  className="btn btn-sm btn-secondary"
                  onClick={() => setIsModified(!isModified)}
                >
                  취소
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
