import React, { useEffect, useState } from "react";
import HelpHyperLink from "../../../../unicomponents/HelpHyperLink";
import useGroups from "../../../../hooks/useGroups";
import { useUIContext } from "../../../../context/UIContext";
import { useQueryClient } from "@tanstack/react-query";
import InsertGroupMembersSidebar from "../../../../unicomponents/InsertGroupMembersSidebar";

//이제 멤버 작업하면 됨.

export default function GroupsSetting({ inputData, groupsQueryConditions }) {
  const [dataToCreate, setDataToCreate] = useState({
    schoolid: groupsQueryConditions.schoolid,
    group_type: groupsQueryConditions.group_type,
    order: "",
    group_name: "",
    description: "",
  });

  const [isAdded, setIsAdded] = useState(false);
  const [dataToSaveRows, setDataToSaveRows] = useState(inputData);
  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);

  //InsertGroupMembersSidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");

  const { createGroups, updateGroups, deleteGroups } = useGroups();

  const { handleToastCenterTop, setIsLoadingModal } = useUIContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    setDataToSaveRows(inputData);
  }, [inputData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataToCreate((prev) => ({
      ...prev,
      [name]: value,
    }));

    console.log("dataToCreate", dataToCreate);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // description을 trim
    dataToCreate.description = dataToCreate.description.trim();

    setIsLoadingModal(true);
    createGroups.mutate(dataToCreate, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setIsAdded(true);
        setTimeout(() => {
          setIsAdded(false);
        }, 1500);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleTrChange = (rowIndex, event) => {
    const { value } = event.target;
    const field = event.target.name;
    const updatedRows = dataToSaveRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);
    setDataToSaveRows(updatedRows);
  };

  const handleSave = (index) => {
    console.log("저장");
    let dataSet = dataToSaveRows[index];

    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      handleToastCenterTop("변경된 사항이 없습니다.");
      console.log("건너뛰기");
      return;
    }

    if (!dataSet.order) {
      handleToastCenterTop("순서에 데이터가 있어야 합니다.");
      return;
    }

    if (!dataSet.group_name) {
      handleToastCenterTop("그룹 이름에 데이터가 있어야 합니다.");
      return;
    }

    // description을 trim
    dataSet.description = dataSet.description.trim();

    // console.log("dataSet", dataSet);
    setIsLoadingModal(true);
    updateGroups.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setDataToSaveRows((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    // console.log("delete id", id);
    setIsLoadingModal(true);
    deleteGroups.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryClient.invalidateQueries(["groups"]);
        setDataToSaveRows((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleMembers = (e) => {
    const { id } = e.target;
    setGroupId(id);
    const selectedRow = dataToSaveRows.find(
      (row) => row.id.toString() === id.toString()
    );
    if (selectedRow) {
      setGroupName(selectedRow.group_name);
    }
    toggleSidebar();
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">
            그룹 설정 <HelpHyperLink link={""} />
          </h3>
        </div>
      </div>
      <InsertGroupMembersSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        groupId={groupId}
        schoolId={groupsQueryConditions.schoolid}
        role={groupsQueryConditions.group_type}
        groupName={groupName}
      />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "120px" }}
          >
            <label htmlFor="order" className="form-label ms-2 fw-bold">
              순서
            </label>
            <input
              type="number"
              name="order"
              className="form-control"
              placeholder="숫자만"
              required
              value={dataToCreate.order || ""}
              onChange={handleChange}
            />
          </div>

          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "200px" }}
          >
            <label htmlFor="group_name" className="form-label ms-2 fw-bold">
              그룹 이름
            </label>
            <input
              type="text"
              name="group_name"
              className="form-control"
              required
              value={dataToCreate.group_name || ""}
              onChange={handleChange}
            />
          </div>

          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "300px" }}
          >
            <label htmlFor="description" className="form-label ms-2 fw-bold">
              그룹 설명
            </label>
            <input
              type="text"
              name="description"
              className="form-control"
              value={dataToCreate.description || ""}
              onChange={handleChange}
            />
          </div>

          <div
            className="col-4 col-sm-3 col-md-2 col-xl-1"
            style={{ minWidth: "80px" }}
          >
            <div className="mb-2">
              <br></br>
            </div>
            <button name="submit" className="btn btn-primary">
              {isAdded ? "완료" : "생성"}
            </button>
          </div>
        </div>
      </form>

      <div className="row mt-3">
        <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
          <table className="table table-bordered text-center">
            <thead>
              <tr className="table-secondary">
                <th style={{ width: "10%" }}>순서</th>
                <th style={{ width: "25%" }}>그룹이름</th>
                <th style={{ width: "35%" }}>설명</th>
                <th style={{ width: "20%" }}>수정</th>
                <th style={{ width: "10%" }}>멤버</th>
              </tr>
            </thead>
            <tbody>
              {dataToSaveRows &&
                dataToSaveRows.map((row, index) => (
                  <tr key={row.id}>
                    <td>
                      <input
                        className="input-like-span"
                        required
                        type="number"
                        name="order"
                        value={row?.order}
                        onChange={(e) => handleTrChange(index, e)}
                      />
                    </td>
                    <td>
                      <input
                        className="input-like-span"
                        required
                        type="text"
                        name="group_name"
                        value={row?.group_name}
                        onChange={(e) => handleTrChange(index, e)}
                      />
                    </td>
                    <td>
                      <input
                        className="input-like-span"
                        required
                        type="text"
                        name="description"
                        value={row?.description}
                        onChange={(e) => handleTrChange(index, e)}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleSave(index)}
                        className="btn btn-sm p-1 me-1 btn-primary"
                      >
                        {savedRows.includes(row.id) ? "저장완료" : "저장"}
                      </button>
                      {confirmDelete.includes(row.id) ? (
                        <>
                          <button
                            type="button"
                            onClick={() => handleDelete(row)}
                            className="btn btn-sm p-1 btn-danger"
                          >
                            확인
                          </button>
                          <button
                            type="button"
                            onClick={() => handleCancelDelete()}
                            className="btn btn-sm p-1 btn-secondary"
                          >
                            취소
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleConfirmDelete(row)}
                          className="btn btn-sm p-1 btn-warning"
                        >
                          삭제
                        </button>
                      )}
                    </td>
                    <td>
                      <button
                        type="button"
                        id={row && row.id}
                        onClick={handleMembers}
                        className="btn btn-sm p-1 me-1 btn-success"
                      >
                        관리
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
