export default class SettingService {
  constructor(http) {
    this.http = http;
  }

  async findAllSettingsByConditions(conditions = {}) {
    // conditions 객체를 쿼리 문자열로 변환
    // console.log("setting conditions", conditions);
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    // console.log("queryParams", queryParams);
    return this.http.fetch(`/api/setting/readallbyconditions?${queryParams}`, {
      method: "GET",
    });
  }

  async createOrganUnit(unitInfo) {
    return this.http.fetch(`/api/setting/create`, {
      method: "POST",
      body: JSON.stringify(unitInfo),
    });
  }

  async updateOrganUnit(unitInfo) {
    return this.http.fetch(`/api/setting/update`, {
      method: "PUT",
      body: JSON.stringify(unitInfo),
    });
  }

  async deleteOrganUnit(id) {
    return this.http.fetch(`/api/setting/delete`, {
      method: "DELETE",

      body: JSON.stringify({ id }),
    });
  }

  async addHrTeacherToOrganUnit(hrTeacherInfo) {
    return this.http.fetch(`/api/setting/updatehrteacher`, {
      method: "PUT",
      body: JSON.stringify(hrTeacherInfo),
    });
  }

  async userTeacherInfoQuery(userId) {
    const queryParams = Object.entries(userId)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/auth/read-user-teacher-info?${queryParams}`, {
      method: "GET",
    });
  }

  async createPreInfo(preInfo) {
    return this.http.fetch(`/api/setting/createpreinfo`, {
      method: "POST",
      body: JSON.stringify(preInfo),
    });
  }

  //권한 만들기 authoritymaking 페이지 함수들

  async readAllAuthorities() {
    return this.http.fetch(`/api/authoritymaking/readall`, {
      method: "GET",
    });
  }

  async createAuthorities(authorityInfo) {
    return this.http.fetch(`/api/authoritymaking/create`, {
      method: "POST",

      body: JSON.stringify(authorityInfo),
    });
  }

  //organization_level 테이블에서 가져오는 것

  async findAllBySchool(schoolId) {
    const queryParams = Object.entries(schoolId)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/organlevel/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createLevelName(levelNamesArray) {
    return this.http.fetch(`/api/organlevel/create`, {
      method: "POST",
      body: JSON.stringify(levelNamesArray),
    });
  }

  async updateLevelName(levelNamesArray) {
    return this.http.fetch(`/api/organlevel/update`, {
      method: "PUT",
      body: JSON.stringify(levelNamesArray),
    });
  }
}
