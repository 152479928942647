import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContextProvider, fetchCsrfToken } from "./context/AuthContext";
import AuthService from "./service/auth";
import HttpClient from "./network/http";
import Layout from "./unicomponents/Layout";
import { useEffect } from "react";
import { UIContextProvider } from "./context/UIContext";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const authService = new AuthService(httpClient);

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/login");
    }
  }, [location.pathname, navigate]);

  return (
    <UIContextProvider>
      <AuthContextProvider authService={authService}>
        <Layout>
          <Outlet />
        </Layout>
      </AuthContextProvider>
    </UIContextProvider>
  );
}

export default App;
