export default class EvalItemsService {
  constructor(http) {
    this.http = http;
  }

  async findAllEvalItems(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/settingevalitems/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createEvalItems(data) {
    return this.http.fetch(`/api/settingevalitems/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateEvalItems(data) {
    return this.http.fetch(`/api/settingevalitems/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async deleteEvalItems(id) {
    return this.http.fetch(`/api/settingevalitems/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }

  async getEvalItemsByLessonId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/settingevalitems/readbylessonid?${queryParams}`,
      {
        method: "GET",
      }
    );
  }
}
