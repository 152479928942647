import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DeveloperService from "../service/developer";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/DeveloperContext";
import { useLocation } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const developerService = new DeveloperService(httpClient);

export default function useDeveloper(conditions) {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const developerPath = paths[1];

  // const { developer } = useDeveloperContext();
  const developer = { schooladmin: "eduseed_admin" };
  const queryClient = useQueryClient();

  //전부 불러오기
  const schoolsQuery = useQuery(
    ["allSchools", developer.schooladmin || ""],
    () => developerService.readall(),
    {
      enabled: developerPath === "developer",
      staleTime: 1000 * 60 * 1, //1분,
    }
  );

  //위치를 바꾸었어야 되었나...
  // console.log("developerPath", developerPath);
  // console.log("developerPath === developer", developerPath !== "developer");
  //제한적 불러오기
  const schoolQueryBySchoolId = useQuery(
    ["schoolid", conditions],
    () => {
      // console.log("developerPath === developer", developerPath === "developer");
      return developerService.findByConditions(conditions);
    },
    {
      enabled: developerPath !== "developer",
      staleTime: 60000,
    }
  );

  // console.log("schools conditions", conditions);

  const registerSchool = useMutation(
    (schoolInfo) => developerService.registerSchool(schoolInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["allSchools"]),
      onError: (error) => {
        // error는 서버에서 반환한 에러 객체입니다.
        // error.message를 통해 에러 메시지를 확인할 수 있습니다.
        // 이 메시지를 사용자에게 보여주거나, 다른 작업을 수행할 수 있습니다.
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
      },
    }
  );

  const updateSchool = useMutation(
    (trInfo) => developerService.updateSchool(trInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["allSchools", developer.schooladmin]);
      },
    }
  );

  // 외부에서 사용할 때 이런식으로 사용함
  // const { deleteSchool, updateSchool } = useDeveloper();
  // const [trInfo, setTrInfo] = useState({
  //   ...school,
  //   startdate: formatDate(startdate),
  // });
  // updateSchool.mutate(trInfo, {
  //   onSuccess: (successMessage) => {
  //     setSuccessTr(successMessage);
  //     setTimeout(() => {
  //       setSuccessTr(null);
  //     }, 4000);
  //     setIsUploading(false);
  //   },
  // });
  // deleteSchool.mutate(id, {
  //   onSuccess: (successMessage) => {
  //     setSuccessTr(successMessage);
  //     setTimeout(() => {
  //       setSuccessTr(null);
  //     }, 4000);
  //     setIsUploading(false);
  //   },
  // });

  const deleteSchool = useMutation((id) => developerService.deleteSchool(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["allSchools", developer.schooladmin]);
    },
  });

  //권한 만들기 authoritymaking 페이지용 함수
  const authorityQuery = useQuery(
    ["allAuthorities", developer.schooladmin || ""],
    () => developerService.readAllAuthorities(),
    {
      enabled: !!developer,
      staleTime: 1000 * 60 * 1, //1분
    }
  );

  const createAuthorities = useMutation(
    (authorityInfo) => developerService.createAuthorities(authorityInfo),
    {
      onSuccess: () => queryClient.invalidateQueries(["allAuthorities"]),
    }
  );

  return {
    schoolsQuery,
    registerSchool,
    updateSchool,
    deleteSchool,
    authorityQuery,
    createAuthorities,
    schoolQueryBySchoolId,
  };
}
