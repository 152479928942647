import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import UniTable from "../../unicomponents/UniTable";
// import UpdateStuInfoModal from "./components/UpdateStuInfoModal";
import LoadingModal from "../../unicomponents/LoadingModal";
// import HelpHyperLink from "../../unicomponents/HelpHyperLink";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import {
  createFilteredAndSortedOptions,
  uniqueDataOptionsKeys,
} from "../../util/dealingArrObj";
import AddCounseling from "./components/AddCounseling";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import useCounseling from "../../hooks/useCounseling";
import useUsers from "../../hooks/useUsers";
import UpdateCounselingModal from "./components/UpdateCounselingModal";

export default function Counseling() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { schoolid: schoolId };

  const [studentList, setStudentList] = useState([]);
  const [invibleOption, setInvibleOption] = useState(true);
  const [rows, setRows] = useState([]);
  const [addCounseling, setAddCounseling] = useState({
    category: "",
    content: "",
    counseling_date: "",
    counselor: "",
    school_year: "",
    schoolid: schoolId,
    username: "",
  });

  const [updateCounselingInfo, setUpdateCounselingInfo] = useState({});

  const [schoolYear, setSchoolYear] = useState(null);
  const [filteredSetting, setFilteredSetting] = useState({});
  const [filterOrgan, setFilterOrgan] = useState({});
  const [idsForKey, setIdsForKey] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [organPathForSort, setOrganPathForSort] = useState("/");
  const [selectedStudent, setSelectedStudent] = useState("");

  const [isUpLoading, setIsUpLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const {
    counselingQuery: { isLoading, data: counselingData },
  } = useCounseling(conditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo({ schoolid: schoolId });

  const {
    usersQuery: { data: userData },
  } = useUsers({ ...conditions, role: "teacher", status: 1 });

  useEffect(() => {
    if (schoolYear === null) {
      //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
      let initialSchoolYear = null;
      let initialSchoolYearId = null;
      if (settingData && settingData.length > 0) {
        // console.log("settingData", settingData);
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];
        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
      }
      setSchoolYear(initialSchoolYear);
      setAddCounseling((addCounseling) => ({
        ...addCounseling,
        school_year: initialSchoolYear,
      }));

      //드랍다운용이다.
      const filtered = settingData.filter(
        (r) => r.school_year === initialSchoolYear
      );
      setFilteredSetting(filtered);

      setFilterOrgan((prevFilter) => ({
        ...prevFilter,
        0: initialSchoolYearId,
      }));

      const forIdsForKey = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(forIdsForKey);

      //rows 데이터처리
      if (counselingData && settingData && initialSchoolYear) {
        const sortedRows = generateRows(
          counselingData,
          settingData,
          initialSchoolYear,
          organPathForSort
        );
        setRows(sortedRows);
        // console.log("sortedRows1", sortedRows);
      }
    } else {
      //rows 데이터처리
      if (counselingData && settingData) {
        // console.log("settingData", settingData);
        const sortedRows = generateRows(
          counselingData,
          settingData,
          schoolYear,
          organPathForSort
        );
        // console.log("작동안해?", sortedRows);

        setRows(sortedRows);
        // console.log("sortedRows2", sortedRows);
      }
    }
  }, [settingData, schoolYear, counselingData, organPathForSort]);

  //가져온 Data를 여러 data와 합쳐서 Datagrid에 들어가기 적합한 형태로 바꾼다.
  function generateRows(
    counselingData,
    settingData,
    schoolYear,
    organPathForSort = "/"
  ) {
    let toBeRows = [];

    if (counselingData && settingData) {
      toBeRows = counselingData.slice();

      if (schoolYear !== "전체") {
        toBeRows = toBeRows.filter((r) => r.school_year === schoolYear);
      }

      if (organPathForSort !== "/" && !organPathForSort.startsWith("nu")) {
        toBeRows = toBeRows
          .map((row) => ({
            ...row,
            registrationinfos: row.registrationinfos.filter((info) =>
              info.organization_path.includes(organPathForSort)
            ),
          }))
          .filter((row) => row.registrationinfos.length > 0);
      }

      toBeRows = toBeRows
        .map((row) => {
          const {
            registrationinfos,
            userInfo,
            counselorInfo,
            followupUserInfo,
            ...otherFields
          } = row;
          // organization_path를 분해하여 필드 만들기
          const paths = registrationinfos[0].organization_path
            .split("/")
            .filter((path) => path);
          const pathFields = paths.reduce((acc, curr, index) => {
            const thisGroup = settingData.find((r) => r.id === Number(curr));

            if (thisGroup) {
              return {
                ...acc,
                [index]: thisGroup.group_name,
              };
            } else {
              console.error(`No group found with id: ${curr}`);
              return acc;
            }
          }, {});

          const nameObject = {
            fullname: userInfo?.fullname ?? "",
            counselorName: counselorInfo?.fullname ?? "",
            followupName: followupUserInfo?.fullname ?? "",
          };

          return {
            ...otherFields,
            ...pathFields,
            ...nameObject,
            // organization_path,
          };
        })
        .sort((a, b) => a.counseling_date - b.counseling_date);
    }

    // console.log("organPathForSort", organPathForSort);

    return toBeRows;
  }

  const { arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc } = {
    arrayData: settingData,
    displayKey: "school_year",
    valueKey: "id",
    filters: { hierarchy_level: 0, parent_id: 0 },
    idKey: "id",
    sortKey: "school_year",
    isDesc: true,
  };

  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey,
      valueKey,
      filters,
      idKey,
      sortKey,
      isDesc,
    });
  }, [arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    const organPath = dataName + "/" + value;

    const lastIndexOfSlash = organPathForSort.lastIndexOf("/");
    const preOrganPath = organPathForSort.slice(0, lastIndexOfSlash);

    let filteredStudents = [];

    setInvibleOption(true);
    setSearchKey("");
    setSelectedStudent("");
    // console.log("organPath", organPath);
    if (organPath === "/") {
      filteredStudents = registrationInfoData.filter((r) =>
        r.organization_path.includes(preOrganPath)
      );
    } else if (organPath.startsWith("nu")) {
      filteredStudents = registrationInfoData.filter(
        (r) => r.school_year === selectedOptionText
      );
    } else {
      filteredStudents = registrationInfoData.filter((r) =>
        r.organization_path.includes(organPath)
      );
    }

    setStudentList(filteredStudents);
    setOrganPathForSort(organPath);

    if (name === "0") {
      const filtered = settingData.filter(
        (r) => r.school_year === selectedOptionText
      );
      setFilteredSetting(filtered);
      const uniqueOptions = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(uniqueOptions);
      setSchoolYear(selectedOptionText);
      setAddCounseling((addCounseling) => ({
        ...addCounseling,
        school_year: selectedOptionText,
      }));
      setSearchKey("");
    }
    // console.log("addRegi", addRegi);
    setFilterOrgan((prevFilter) => {
      const newFilter = { ...prevFilter, [name]: value };
      // console.log("newFilter", newFilter);
      Object.keys(newFilter).forEach((key) => {
        if (Number(key) > Number(name)) {
          delete newFilter[key];
        }
      });
      return newFilter;
    });

    // console.log("filterOrgan2", filterOrgan);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    let filteredStudents = [];

    if (value === "") {
      setInvibleOption(true);

      filteredStudents = registrationInfoData.filter((r) =>
        r.organization_path.includes(organPathForSort)
      );

      setStudentList(filteredStudents);
      setRows(
        generateRows(counselingData, settingData, schoolYear, organPathForSort)
      );
    } else {
      setInvibleOption(false);

      const filteredRows = generateRows(
        counselingData,
        settingData,
        schoolYear,
        organPathForSort
      ).filter((row) => row.fullname === value);

      if (organPathForSort.startsWith("nu")) {
        filteredStudents = registrationInfoData.filter(
          (r) => r.user.fullname === value && r.school_year === schoolYear
        );
      } else {
        filteredStudents = registrationInfoData.filter(
          (r) =>
            r.user.fullname === value &&
            r.organization_path.includes(organPathForSort)
        );
      }

      setStudentList(filteredStudents);
      if (filteredStudents.length > 0) {
        setAddCounseling((addCounseling) => ({
          ...addCounseling,
          username: filteredStudents[0].username,
        }));
      } else {
        setAddCounseling((addCounseling) => ({
          ...addCounseling,
          username: "",
        }));
      }
      setRows(filteredRows);
    }
  };

  const handleStudentChange = (e) => {
    const { value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    setSelectedStudent(value);
    // console.log("value", value);
    if (value === "") {
      setSearchKey("");
      setAddCounseling((addCounseling) => ({
        ...addCounseling,
        username: "",
      }));

      const filteredRows = generateRows(
        counselingData,
        settingData,
        schoolYear,
        organPathForSort
      );
      setRows(filteredRows);
      return;
    }

    setAddCounseling((addCounseling) => ({
      ...addCounseling,
      username: value,
    }));

    setSearchKey(selectedOptionText);

    // console.log("rows", rows);
    const filteredRows = generateRows(
      counselingData,
      settingData,
      schoolYear,
      organPathForSort
    ).filter((row) => row.username === value);
    setRows(filteredRows);
  };

  const handleUpdateClick = (row) => {
    const rowInfo = {
      id: row.id || null,
      schoolid: row.schoolid || null,
      school_year: row.school_year || null,
      username: row.username || null,
      counselor: row.counselor || null,
      category: row.category || null,
      counseling_date: row.counseling_date || null,
      content: row.content || null,
      followup: row.followup || null,
      followup_user: row.followup_user || null,
      fullname: row.fullname || null,
      counselorName: row.counselorName || null,
      followupName: row.followupName || null,
      1: row["1"] || null,
      2: row["2"] || null,
      3: row["3"] || null,
    };
    setUpdateCounselingInfo(rowInfo);
    setModalOpen(true);
  };

  //headerName을 "과정"이라고 직접 쓰는 것이 아니라 levelName으로 바꾸어야 하는데...
  const columns = useMemo(
    () => [
      {
        field: "counseling_date",
        headerAlign: "left",
        headerName: "상담일자",
        align: "left",
      },
      {
        field: "1",
        headerAlign: "center",
        headerName: "과정",
        align: "center",
        width: 50,
      },
      {
        field: "2",
        headerAlign: "center",
        headerName: "학년",
        align: "center",
        width: 50,
      },
      {
        field: "3",
        headerAlign: "center",
        headerName: "반",
        align: "center",
        width: 70,
      },
      {
        field: "fullname",
        headerAlign: "center",
        headerName: "이름",
        align: "center",
        width: 90,
        renderCell: (params) => (
          <button
            className="btn btn-sm btn-outline-dark p-1 m-1"
            style={{
              "--bs-btn-font-size": ".80rem",
            }}
            onClick={() => handleUpdateClick(params.row)}
          >
            {params.value}
          </button>
        ),
      },
      {
        field: "counselorName",
        headerAlign: "center",
        headerName: "상담자",
        align: "center",
        width: 80,
      },
      {
        field: "category",
        headerAlign: "center",
        headerName: "구분",
        align: "center",
        width: 80,
      },
      {
        field: "content",
        headerAlign: "left",
        headerName: "상담내용",
        align: "left",
        width: 450,
        // flex: 1,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: "followup",
        headerAlign: "left",
        headerName: "후속조치",
        align: "left",
        width: 330,
        // flex: 0.8,
        renderCell: (params) => (
          <div
            style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {params.value}
          </div>
        ),
      },
      {
        field: "followupName",
        headerAlign: "center",
        headerName: "조치자",
        align: "center",
        width: 80,
      },
    ],
    []
  );

  return (
    <>
      {isLoading && <LoadingModal />}
      <div className="ms-3 basicWidth">
        <div className="container-fluid">
          {isUpLoading && <LoadingModal />}
          <div className="row mb-4">
            {modalOpen && (
              <UpdateCounselingModal
                setModalOpen={setModalOpen}
                updateCounselingInfo={updateCounselingInfo}
                setUpdateCounselingInfo={setUpdateCounselingInfo}
                setIsUpLoading={setIsUpLoading}
                userData={userData}
              />
            )}
            <div className="col-3">
              <div className="d-flex">
                <h3 className="fw-bold mt-3">상담기록</h3>
                {/* <HelpHyperLink link={"https://youtu.be/3o6ID300gcw"} /> */}
              </div>
            </div>
            <div className="col-9 d-flex gap-2 align-items-center">
              <select className="form-control" name="0" onChange={handleChange}>
                {settingData?.length > 0 && options}
                <option value="all">전체</option>
              </select>
              {filteredSetting?.length > 0 &&
                Object.keys(idsForKey).map((item) => {
                  if (item === "0") return null;
                  return (
                    <select
                      key={item}
                      name={item}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="" data-name="">
                        {idsForKey[item][0]}
                      </option>
                      {filteredSetting
                        .filter(
                          (r) =>
                            r.parent_id ===
                            Number(filterOrgan[(Number(item) - 1).toString()])
                        )
                        .sort((a, b) => {
                          const aValue = a["order"];
                          const bValue = b["order"];
                          if (
                            typeof aValue === "number" &&
                            typeof bValue === "number"
                          ) {
                            // 숫자인 경우
                            return aValue - bValue;
                          } else {
                            // 문자열인 경우
                            return aValue.localeCompare(bValue);
                          }
                        })
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-name={option.organization_path}
                          >
                            {option.group_name}
                          </option>
                        ))}
                    </select>
                  );
                })}
              <select
                className="form-control"
                name="registeredStudents"
                onChange={handleStudentChange}
                value={selectedStudent}
              >
                {invibleOption && <option value="">학생</option>}
                {studentList.map((option) => (
                  <option
                    key={option.id}
                    name={option.organization_path}
                    value={option.username}
                  >
                    {option.user.fullname}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <AddCounseling
              handleSearch={handleSearch}
              searchKey={searchKey}
              addCounseling={addCounseling}
              setAddCounseling={setAddCounseling}
              setIsUpLoading={setIsUpLoading}
              userData={userData}
            />
          </div>
          <div
            style={{
              width: "96vw",
              overflowX: "auto",
              maxWidth: "1536px",
            }}
          >
            {rows?.length > 0 && (
              <UniTable
                columns={columns}
                rows={rows}
                exportedFileName="학생기본정보"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
