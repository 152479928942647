export default class ApprovalGroupMembersService {
  constructor(http) {
    this.http = http;
  }

  async findAllApprovalGroupMembers(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/approvalgroupmembers/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createApprovalGroupMembers(data) {
    return this.http.fetch(`/api/approvalgroupmembers/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateApprovalGroupMembers(data) {
    return this.http.fetch(`/api/approvalgroupmembers/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async deleteApprovalGroupMembers(id) {
    return this.http.fetch(`/api/approvalgroupmembers/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
