import React, { useState } from "react";
import { fetchParsedData } from "../../../../util/dataMigrationPapaparse";
import DataMigrationModal from "./components/DataMigrationModal";
import useDeveloperFunction from "../../../../hooks/developer/useDeveloperFunctions";
import LoadingModal from "../../../../unicomponents/LoadingModal";
import LogsModal from "../../../../unicomponents/LogsModal";

/*
organizationSetting은 그냥 손으로 하고

1. 재적
2. 출석... 이런 순으로 가보자.


1. 재적에서 organization_path를 어떻게 하냐... 손으로 만든 다음에 그것을 바탕으로 해야겠군.

*/

export default function DataMigration() {
  const [jsonData, setJsonData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isMigrated, setIsMigrated] = useState(false);
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const {
    dataMigrationQuery: { data: dataMigrationData },
    dataMigrate,
  } = useDeveloperFunction();

  const handleFetchData = async (row) => {
    const csvLink = row.csv_link;

    // console.log("row", row);

    setAddress(row.address);
    try {
      const parsedData = await fetchParsedData(csvLink);
      setJsonData(parsedData);
      setShowModal(true); // 데이터를 가져오면 바로 모달을 띄웁니다.
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("데이터를 가져오는 중 오류가 발생했습니다.");
    }
  };

  const handleFinalExecute = () => {
    setShowModal(false);

    setIsLoading(true);
    dataMigrate.mutate(
      { dataArray: jsonData, address },
      {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoading(false);
          setIsMigrated(true);
          setTimeout(() => {
            setIsMigrated(false);
          }, 1500);
          setLogs(successMessage?.logs || []);
        },
        onError: (error) => {
          setIsLoading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      }
    );
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="ms-3 basicWidth">
      {isLoading && <LoadingModal />}
      {<LogsModal logs={logs} setLogs={setLogs} />}
      <div className="row mt-3 ms-1">
        <div className="col-auto">
          <h3 className="fw-bold">데이터 이관</h3>
        </div>
      </div>

      <div className="row p-3">
        <table className="table table-bordered">
          <thead className="table-secondary">
            <tr>
              <th>No.</th>
              <th>테이블이름</th>
              <th>관련페이지</th>
              <th>csv 양식</th>
              <th>데이터 업로드 실행</th>
            </tr>
          </thead>
          <tbody>
            {dataMigrationData &&
              dataMigrationData.map((row) => (
                <tr key={row.id}>
                  <td>{row.order}</td>
                  <td>{row.table_name}</td>
                  <td>{row.related_page}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() =>
                        window.open(row.spreadsheet_link, "_blank")
                      }
                    >
                      스프레드시트 링크
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleFetchData(row)}
                    >
                      데이터 가져오기
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <DataMigrationModal
        isOpen={showModal}
        toggleModal={toggleModal}
        jsonData={jsonData}
        onConfirm={handleFinalExecute}
        isMigrated={isMigrated}
      />
    </div>
  );
}
