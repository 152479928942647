import React from "react";
import DOMPurify from "dompurify";
import styles from "./CSS/ToastCenterTop.module.css";
import { useUIContext } from "../context/UIContext";

export default function ToastCenterTop() {
  const { toastCenterTop } = useUIContext();

  if (!toastCenterTop.visible) return null;

  return (
    <div
      className={styles.toastCenterTop}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(toastCenterTop.message),
      }}
    />
  );
}
