import React, { useState } from "react";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { findDuplicateData } from "../../../util/dealingArrObj";

export default function AddUnitModal({
  unit,
  setModalOpen,
  settingData,
  levelNameData,
  conditions,
}) {
  // console.log("unit", unit);

  const [addUnit, setAddUnit] = useState({
    ...unit,
    level_name: simpleFilterForLevelName(levelNameData, unit),
    hierarchy_level: unit.hierarchy_level + 1,
    parent_id: unit.id,
    description: "",
    hr_teacher: unit.hr_teacher,
    organization_path:
      unit.organization_path !== "0"
        ? unit.organization_path + "/" + unit.id
        : "/" + unit.id,
  });

  const { createOrganizationUnit } = useOrganizationSetting();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "hierarchy_level") {
      if (isNaN(Number(value))) {
        alert("단계가 숫자가 아닙니다.");
        return;
      } else {
        formattedValue = Number(value);
      }
    }
    setAddUnit((addUnit) => ({ ...addUnit, [name]: formattedValue }));
    // console.log("addUnit", addUnit);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // setIsUpLoading(true);
    if ("id" in addUnit) {
      // 'id' 속성이 newObj에 있는지 확인
      delete addUnit.id;
    }

    const isDuplicate = findDuplicateData(settingData, addUnit, [
      "school_year",
      "parent_id",
      "group_name",
    ]);

    if (isDuplicate) {
      alert("같은 분류그룹 내에서 같은 그룹이름으로는 생성할 수 없습니다.");
      return;
    }

    createOrganizationUnit.mutate(addUnit, {
      onSuccess: () => {
        setModalOpen(false);
      },
      onError: (error) => {
        // setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error
        );
      },
    });
  };

  function simpleFilterForLevelName(levelNameData, unit) {
    const filtered = levelNameData.filter(
      (r) =>
        r.school_year === unit.school_year &&
        r.hierarchy_level === unit.hierarchy_level + 1
    );

    return filtered[0].level_name;
  }

  return (
    <>
      <div
        className="modal-container"
        // onClick={() => {
        //   setModalOpen(false);
        // }}
      >
        <div className="modalForUnit">
          <div
            className="modal-header mb-3"
            // onClick={() => {
            //   setModalOpen(false);
            // }}
          >
            <h3>하위 그룹 추가하기</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="parent_id" className="col-form-label">
                    상위그룹
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "5px" }}>
                  <span className="align-bottom">
                    {unit?.group_name}
                    {unit?.level_name && unit?.level_name}
                  </span>
                </div>
                {/* <div className="col-3">
                  <input
                    required
                    type="text"
                    name="parent_id"
                    className="form-control"
                    value={unit?.id}
                    disabled
                  />
                </div> */}
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="hierarchy_level" className="col-form-label">
                    분류단계
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "5px" }}>
                  <span className="align-bottom">
                    {unit?.hierarchy_level + 1} 단계
                  </span>
                  {/* <input
                    required
                    type="number"
                    name="hierarchy_level"
                    className="form-control"
                    value={unit?.hierarchy_level + 1}
                    disabled
                  /> */}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="group_name" className="col-form-label">
                    계층이름
                  </label>
                </div>
                <div className="col-auto" style={{ paddingTop: "5px" }}>
                  <span className="align-bottom">
                    {levelNameData &&
                      simpleFilterForLevelName(levelNameData, unit)}
                  </span>
                  {/* <input
                    type="text"
                    name="level_name"
                    className="form-control"
                    value={
                      levelNameData &&
                      simpleFilterForLevelName(levelNameData, unit)
                    }
                    disabled
                  /> */}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="group_name" className="col-form-label">
                    분류그룹<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    required
                    type="text"
                    name="group_name"
                    className="form-control"
                    placeholder="계층이름에 맞는 이름"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="order" className="col-form-label">
                    정렬순서<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    type="number"
                    name="order"
                    className="form-control"
                    required
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="teacher" className="col-form-label">
                    담임이름
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="teacher"
                    className="form-control"
                    onChange={handleTeacherNameChange}
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label
                    htmlFor="hr_teacher_username"
                    className="col-form-label"
                  >
                    교사아이디<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8">
                  <select
                    required
                    name="hr_teacher_username"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option value="">선택</option>
                    {filteredTeachers.map((user) => (
                      <option key={user.id} value={user.fullname}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="tname" className="col-form-label">
                    배정담임<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8 border text-nowrap m-1 p-1 ms-3">
                  {addUnit?.hr_teacher_username?.map((user, index) => (
                    <span key={index}>
                      {user.tname}
                      <MdOutlineCancel
                        role="button"
                        onClick={() => {
                          handleClick(user.username);
                        }}
                      />
                    </span>
                  ))}
                </div>
              </div> */}

              {/* <div className="row mb-3">
                <div className="col-3">
                  <label htmlFor="description" className="col-form-label">
                    설명
                  </label>
                </div>
                <div className="col-8">
                  <textarea
                    name="description"
                    className="form-control"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                취소
              </button>
              <button type="submit" className="btn btn-primary">
                추가하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
