import React, { useEffect, useState } from "react";
import LeftMenuSidebar from "../../unicomponents/LeftMenuSidebar";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import { useLocation } from "react-router-dom";
import PhysicalRecord from "./components/PhysicalRecord";
import useVariousRecordInputs from "../../hooks/useVariousRecordInputs";
import useVariousRecordLists from "../../hooks/useVariousRecordLists";
import HealthRecord from "./components/HealthRecord";

export default function VariousRecords() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const pageUrl = paths[3];
  const conditions = {
    schoolid: schoolId,
  };
  const schoolIdPageUrl = {
    schoolid: schoolId,
    page_url: pageUrl,
  };

  const [inputQueryConditions, setInputQueryConditions] = useState({
    schoolid: schoolId,
    record_list_id: "",
  });

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    variousRecordListsQuery: { data: variousRecordListsData },
  } = useVariousRecordLists(schoolIdPageUrl);

  const {
    variousRecordInputsQuery: { data: variousInputsData },
  } = useVariousRecordInputs(inputQueryConditions);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
  });

  useEffect(() => {
    if (variousRecordListsData) {
      setInputQueryConditions((prev) => ({
        ...prev,
        record_list_id: variousRecordListsData[0].id,
      }));
      setActiveMenu({
        menu_name: variousRecordListsData[0].record_name,
        menu_id: variousRecordListsData[0].id,
      });
    }
  }, [variousRecordListsData]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setInputQueryConditions((prev) => ({
        ...prev,
        record_list_id: item.id,
      }));
    }
  };

  function renderComponent() {
    const filteredInputData = variousInputsData
      .filter(
        (item) => item.record_list_id === inputQueryConditions.record_list_id
      )
      .sort((a, b) => {
        const orderA = parseInt(a.order, 10);
        const orderB = parseInt(b.order, 10);

        if (isNaN(orderA) && isNaN(orderB)) {
          return 0;
        } else if (isNaN(orderA)) {
          return 1;
        } else if (isNaN(orderB)) {
          return -1;
        } else {
          return orderA - orderB;
        }
      });

    switch (activeMenu.menu_name) {
      case "physicalRecord":
        return (
          <PhysicalRecord
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
            inputQueryConditions={inputQueryConditions}
          />
        );
      case "healthRecord":
        return (
          <HealthRecord
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
          />
        );
      default:
        return (
          <PhysicalRecord
            settingData={settingData}
            inputData={filteredInputData}
            schoolId={schoolId}
          />
        );
    }
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={variousRecordListsData}
              extraFunction={handleQueryCondition}
            />
          </div>
          <div className="col-12 col-md-10">
            {variousInputsData && renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}
