import React, { useState } from "react";
import { calculateTextNumber } from "../../../../../util/etCetera";
import { useUIContext } from "../../../../../context/UIContext";

export default function NamusupCustomTable({
  evaluationType,
  lessonStudentsData,
  setLessonStudentsData,
  lessonSetting,
  lessonInfo,
  updateLessonStudents,
  updateLessonStudentsBulk,
  deleteLessonStudents,
}) {
  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const [savedRows, setSavedRows] = useState([]);
  const [savedBulk, setSavedBulk] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState([]);

  // 일반적인 row 데이터 변경을 처리하는 함수
  const handleTrChange = (rowIndex, event) => {
    // const { value } = event.target;
    const field = event.target.name;
    const dataName = event.target.getAttribute("data-name");
    let value;
    let updatedRows = [...lessonStudentsData];

    if (field === "eval_area_grades") {
      value = {
        ...lessonStudentsData[rowIndex].eval_area_grades,
        [dataName]: event.target.value,
      };
    } else {
      value = event.target.value;
    }
    updatedRows = updatedRows.map((row, index) =>
      index === rowIndex ? { ...row, [field]: value, isModified: true } : row
    );
    // console.log("updatedRows tr", updatedRows);

    if (field === "eval_area_grades") {
      const lessonSettingItem = lessonSetting?.find(
        (r) => r.item_code === "evalArea100"
      );

      const settings = lessonSettingItem?.detailed_setting || [];
      const evalType = lessonSettingItem?.saved_setting;
      const totalGrades = calculateTotalGrades100(
        updatedRows[rowIndex].eval_area_grades,
        settings,
        evalType,
        lessonInfo
      );

      updatedRows = updatedRows.map((row, index) =>
        index === rowIndex ? { ...row, grade: totalGrades } : row
      );
    }

    setLessonStudentsData(updatedRows);
  };

  // 태그 변경을 처리하는 함수
  const handleTagChange = (tagName, event, rowIndex, tagIndex) => {
    const { value } = event.target; // 선택된 새로운 태그 값

    // 먼저 해당하는 row를 찾아서 복사한 다음
    const updatedRows = lessonStudentsData.map((row, index) => {
      if (index === rowIndex) {
        const updatedTags = row.tags.map((tag, idx) => {
          if (idx === tagIndex) {
            return { [tagName]: value }; // 태그 값을 업데이트
          }
          return tag;
        });

        return { ...row, tags: updatedTags, isModified: true };
      }
      return row;
    });

    // console.log("updatedRows", updatedRows);
    setLessonStudentsData(updatedRows); // 상태 업데이트
  };

  //이수제, 3단평가, ABC, 직접입력, 서술형, 5단평가,

  //평가항목별과 커스텀은 따로 table로...

  function renderTh() {
    switch (evaluationType) {
      case "커스텀":
        return (
          <>
            {lessonSetting
              ?.find((r) => r.item_code === "evalArea100")
              ?.detailed_setting?.map((setting, index) => (
                <th key={index}>{setting}</th>
              ))}
            <th>합</th>
            <th>ABC</th>
          </>
        );
      default:
        return <th>정보없음</th>;
    }
  }

  function calculateTotalGrades100(
    eval_area_grades,
    settings,
    evalType,
    lessonInfo
  ) {
    if (evalType === "원점수기록") {
      return settings.reduce((acc, setting) => {
        if (eval_area_grades && eval_area_grades.hasOwnProperty(setting)) {
          const value = isNaN(eval_area_grades[setting])
            ? 0
            : Number(eval_area_grades[setting]);
          const weight = lessonInfo?.eval_area_weights?.[setting] || 0;
          const validWeight = isNaN(weight) ? 0 : Number(weight);
          return acc + (value * validWeight) / 100;
        }
        return acc;
      }, 0);
    } else {
      const filteredGrades = settings
        .filter(
          (setting) =>
            eval_area_grades && eval_area_grades.hasOwnProperty(setting)
        )
        .map((setting) => {
          const value = eval_area_grades[setting];
          return isNaN(value) ? 0 : Number(value);
        });

      return filteredGrades.reduce((acc, grade) => acc + grade, 0);
    }
  }

  function renderTd(eval_area_grades, rowIndex, grade, convert100) {
    switch (evaluationType) {
      case "커스텀":
        const lessonSettingItem = lessonSetting?.find(
          (r) => r.item_code === "evalArea100"
        );
        const settings = lessonSettingItem?.detailed_setting || [];

        return (
          <>
            {settings.map((setting, index) => (
              <td key={index} className="ps-2">
                <input
                  className="input-like-span"
                  type="number"
                  data-name={setting}
                  name="eval_area_grades"
                  onChange={(e) => handleTrChange(rowIndex, e)}
                  value={
                    eval_area_grades && eval_area_grades.hasOwnProperty(setting)
                      ? eval_area_grades[setting] || ""
                      : ""
                  }
                />
              </td>
            ))}
            <td>{grade}</td>
            <td>
              <input
                className="input-like-span"
                type="text"
                name="convert100"
                onChange={(e) => handleTrChange(rowIndex, e)}
                value={convert100 || ""}
              />
            </td>
          </>
        );
      default:
        return <td>관리자에게 문의</td>;
    }
  }

  const handleSubmit = (index) => {
    const dataSet = lessonStudentsData[index];
    if (!dataSet.isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }
    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataSet);
    setIsLoadingModal(true);
    updateLessonStudents.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, dataSet.id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
    // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
    setLessonStudentsData((currentDataSets) =>
      currentDataSets.map((dataSet, dataSetIndex) =>
        dataSetIndex === index ? { ...dataSet, isModified: false } : dataSet
      )
    );
  };

  const handleSubmitAll = () => {
    const dataArray = lessonStudentsData.filter(
      (data) => data.isModified === true
    );

    if (dataArray.length < 1) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }
    // 변경된 데이터 세트 처리 로직...
    // console.log("제출된 데이터 세트:", dataArray);
    setIsLoadingModal(true);
    updateLessonStudentsBulk.mutate(dataArray, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedBulk(true);
        setTimeout(() => {
          setSavedBulk(false);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setLessonStudentsData((currentDataSets) =>
          currentDataSets.map((data) =>
            data.isModified === true ? { ...data, isModified: false } : data
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleDelete = (row) => {
    const id = row.id;
    setIsLoadingModal(true);
    deleteLessonStudents.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        setLessonStudentsData((rows) => rows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  return (
    <>
      <form>
        <table className="table text-center align-middle">
          <thead>
            <tr>
              <th style={{ width: "8%" }}>과정</th>
              <th style={{ width: "8%" }}>학년</th>
              <th style={{ width: "8%" }}>반</th>
              <th style={{ width: "8%" }}>이름</th>
              {renderTh()}
              <th style={{ width: "30%", minWidth: "30%" }}>세부특기사항</th>
              <th style={{ width: "9%" }}>
                <button
                  type="button"
                  onClick={handleSubmitAll}
                  className="btn btn-sm p-1 btn-primary"
                >
                  {savedBulk ? "저장완료" : "모두저장"}
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {lessonStudentsData &&
              lessonStudentsData.length > 0 &&
              lessonStudentsData.map((student, rowIndex) => (
                <React.Fragment key={student.id}>
                  <tr
                    key={student.id}
                    className="align-middle"
                    style={{ fontSize: "14px" }}
                  >
                    <td>{student.hierarchyOne}</td>
                    <td>{student.hierarchyTwo}</td>
                    <td>{student.hierarchyThree}</td>
                    <td>{student.fullname}</td>
                    {renderTd(
                      student.eval_area_grades,
                      rowIndex,
                      student.grade,
                      student.convert100
                    )}

                    <td className="ps-3 p-1" rowSpan={2}>
                      <textarea
                        type="text"
                        rows="4"
                        className="input-like-span"
                        name="comment"
                        value={student?.comment || ""}
                        onChange={(e) => handleTrChange(rowIndex, e)}
                      />
                    </td>
                    <td className="p-0" rowSpan={2}>
                      글자수: {calculateTextNumber(student.comment)}
                      <br />
                      <button
                        type="button"
                        onClick={() => handleSubmit(rowIndex)}
                        className="btn btn-sm p-1 btn-primary"
                      >
                        {savedRows.includes(student.id) ? "저장완료" : "저장"}
                      </button>
                      {confirmDelete.includes(student.id) ? (
                        <>
                          <button
                            type="button"
                            onClick={() => handleDelete(student)}
                            className="btn btn-sm p-1 btn-danger m-1"
                          >
                            확인
                          </button>
                          <button
                            type="button"
                            onClick={() => handleCancelDelete()}
                            className="btn btn-sm p-1 btn-secondary m-1"
                          >
                            취소
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleConfirmDelete(student)}
                          className="btn btn-sm p-1 btn-warning m-1"
                        >
                          삭제
                        </button>
                      )}
                    </td>
                  </tr>
                  <tr style={{ borderBottom: "3px solid #000" }}>
                    <th className="pb-0 pt-0 align-middle">태그</th>
                    <td colSpan={6} className="pb-1 pt-1 full-width-colspan">
                      <div className="d-flex" style={{ fontSize: "14px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            gap: "5px",
                          }}
                        >
                          {student?.tags?.map((tag, tagIndex) => {
                            const tagName = Object.keys(tag)[0];
                            return (
                              <div
                                key={tagIndex}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  className="text-center"
                                  style={{ marginBottom: "1px" }}
                                >
                                  {tagName}
                                </div>
                                <select
                                  className="form-control p-1"
                                  style={{ fontSize: "14px" }}
                                  value={tag[tagName] || ""}
                                  name="tags"
                                  onChange={(e) =>
                                    handleTagChange(
                                      tagName,
                                      e,
                                      rowIndex,
                                      tagIndex
                                    )
                                  }
                                >
                                  <option value="1">매우약함</option>
                                  <option value="2">약함</option>
                                  <option value="3">보통</option>
                                  <option value="4">강함</option>
                                  <option value="5">매우강함</option>
                                </select>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </form>
    </>
  );
}
