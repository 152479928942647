import React, { useState } from "react";
import formatDate from "../../../util/formatdate";
import useDeveloper from "../../../hooks/useDeveloper";

export default function SchoolTr({
  school,
  school: {
    id,
    schoolid,
    schoolname,
    schooladmin,
    adminid,
    status,
    usergroup,
    startdate,
  },
  index,
  setIsUploading,
  setSuccessTr,
}) {
  const { deleteSchool, updateSchool } = useDeveloper();

  const [trInfo, setTrInfo] = useState({
    ...school,
    startdate: formatDate(startdate),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "startdate") {
      formattedValue = formatDate(value);
    }
    setTrInfo((trInfo) => ({ ...trInfo, [name]: formattedValue }));
  };

  const handleClick = () => {
    setIsUploading(true);
    updateSchool.mutate(trInfo, {
      onSuccess: (successMessage) => {
        setSuccessTr(successMessage);
        setTimeout(() => {
          setSuccessTr(null);
        }, 4000);
        setIsUploading(false);
      },
    });
  };

  const handleDelete = (e) => {
    const id = e.target.name;
    // Confirmation prompt
    if (window.confirm("정말 이 학교를 삭제하시겠습니까? 정말로?")) {
      setIsUploading(true);
      deleteSchool.mutate(id, {
        onSuccess: (successMessage) => {
          setSuccessTr(successMessage);
          setTimeout(() => {
            setSuccessTr(null);
          }, 4000);
          setIsUploading(false);
        },
      });
    } else {
      // User clicked 'Cancel', do nothing
      console.log("삭제가 취소됨. 휴....");
    }
  };

  return (
    <tr className={id} key={id}>
      <th scope="row">{index + 1}</th>
      <td>{schoolid}</td>
      <th>
        <input
          className="form-control"
          type="text"
          name="schoolname"
          defaultValue={schoolname}
          onChange={handleChange}
        />
      </th>
      <td>
        <input
          className="form-control"
          type="text"
          name="schooladmin"
          defaultValue={schooladmin}
          onChange={handleChange}
        />
      </td>
      <td>{adminid}</td>
      <td>
        <input
          className="form-control "
          type="text"
          name="password"
          defaultValue="****"
          onChange={handleChange}
        />
      </td>
      <td>
        <input
          className="form-control "
          type="number"
          name="status"
          defaultValue={status}
          onChange={handleChange}
        />
      </td>
      <td>
        <select
          className="form-control"
          name="usergroup"
          defaultValue={usergroup || ""} // Fallback to an empty string if usergroup is null
          onChange={handleChange}
        >
          <option value="">사용자그룹선택</option> {/* Placeholder option */}
          <option value="교사">교사</option>
          <option value="학생">학생</option>
          <option value="부모">부모</option>
          <option value="교사학생">교사학생</option>
          <option value="교사학생부모">교사학생부모</option>
          <option value="교사부모">교사부모</option>
        </select>
      </td>
      <td>
        <input
          className="form-control"
          type="date"
          name="startdate"
          defaultValue={formatDate(startdate)}
          onChange={handleChange}
        />
      </td>
      <td>
        <button className="btn btn-primary" name={id} onClick={handleClick}>
          저장
        </button>
        <button className="btn btn-warning" name={id} onClick={handleDelete}>
          삭제
        </button>
      </td>
    </tr>
  );
}
