export default class ApprovalGroupsService {
  constructor(http) {
    this.http = http;
  }

  async findAllApprovalGroups(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/approvalgroups/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createApprovalGroups(data) {
    return this.http.fetch(`/api/approvalgroups/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateApprovalGroups(data) {
    return this.http.fetch(`/api/approvalgroups/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async deleteApprovalGroups(id) {
    return this.http.fetch(`/api/approvalgroups/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
