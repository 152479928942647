import {
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DeveloperContext = createContext();
const csrfRef = createRef();

export function DeveloperContextProvider({ developerService, children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [developer, setDeveloper] = useState();
  const [csrfToken, setCsrfToken] = useState(undefined);

  useImperativeHandle(csrfRef, () => csrfToken);

  useEffect(() => {
    developerService.csrfToken().then(setCsrfToken).catch(console.error);
  }, [developerService]);

  // useEffect(() => {
  //   developerService.me().then(setDeveloper).catch(console.error);
  // }, [developerService]);

  useEffect(() => {
    developerService
      .me()
      .then((developerData) => {
        setDeveloper(developerData);
        // console.log("developerData", developerData);

        // console.log("location.pathname", location.pathname);

        if (location.pathname === "/developer") {
          // Redirect to dashboard if the user is on the login page after logging in
          navigate("/developer/devschools");
        }
      })
      .catch((error) => {
        console.error(error);
        // Redirect to login if there is an error (e.g., user not logged in)
        navigate("/developer");
      });
  }, [developerService, navigate, location.pathname]);

  const login = useCallback(
    async (adminid, password) =>
      developerService.login(adminid, password).then((developer) => {
        setDeveloper(developer);
        // console.log("developer", developer);
        if (location.pathname === "/developer") {
          // Redirect to dashboard if the user is on the login page after logging in
          navigate("/developer/devschools");
        }
      }),
    [developerService, navigate, location.pathname]
  );

  const logout = useCallback(
    async () =>
      developerService.logout().then(() => {
        setDeveloper(undefined);
        navigate("/developer");
      }),
    [developerService, navigate]
  );

  return (
    <DeveloperContext.Provider
      value={{
        developer,
        login,
        logout,
      }}
    >
      {children}
    </DeveloperContext.Provider>
  );
}

export function useDeveloperContext() {
  return useContext(DeveloperContext);
}
export const fetchCsrfToken = () => csrfRef.current;
