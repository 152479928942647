import React, { useEffect, useRef, useState } from "react";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import useAllForms from "../../../hooks/useAllForms";
import { useLocation } from "react-router-dom";
import useComponentsToForms from "../../../hooks/useComponentsToForms";
import DynamicComponentsRenderer from "../../../unicomponents/formComponents/DynamicComponentsRenderer";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { setTermIdByToday } from "../../../util/dateRelated";
import { generateRegistrationRows } from "../../../util/dealingArrObj";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import { useUIContext } from "../../../context/UIContext";
import FiltersBar from "../../../unicomponents/Dropdowns/FilterBar";
import ReportCardButtonBar from "./components/ReportCardButtonBar";
// import styles from "./css/ReportCard.module.css";

import { useReactToPrint } from "react-to-print";
import ApprovalProcess from "../../../unicomponents/approvalForms/ApprovalProcess";
import useDocumentForms from "../../../hooks/useDocumentForms";
import { useAuthContext } from "../../../context/AuthContext";
import useApprovalRequests from "../../../hooks/useApprovalRequest";
//${styles.tableStyle} 이런식으로 씀. class에 삽입

/* 
우엥... 인쇄까지 순탄하게 된다. 예전에 인쇄 엄청 어려워서 한참 시간 걸리고도 만족스럽게 못했는데...

좋아, 이제 할일
1. 인쇄 안 되는 윗대가리 디자인
2. 본문에 들어갈 컴포넌트들 개발
3. 넣어서 인쇄 확인
*/

const PageHeader = () => (
  <div className="header">
    <h1> </h1>
  </div>
);

const PageFooter = () => (
  <div className="footer">
    <p className="page-number"></p>
  </div>
);

export default function ReportCard() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedTeacherId: "",
    selectedLessonId: "",
  });
  const [termId, setTermId] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [studentListForApproval, setStudentListForApproval] = useState([]);
  const [keyData, setKeyData] = useState({});
  // const [validateInput, setValidateInput] = useState({});
  const [modifyMode, setModifyMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvalLines, setApprovalLines] = useState([]);
  const [studentApprovalRequests, setStudentApprovalRequests] = useState([]);

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "",
    menu_id: "",
  });

  const conditions = {
    schoolid: schoolId,
    form_category: "reportCard",
  };

  const [formQueryConditions, setFormQueryConditions] = useState({
    schoolid: schoolId,
    form_id: "",
  });

  const [approvalData, setApprovalData] = useState({
    schoolid: schoolId,
    document_type: "fixedForm",
    document_category: "reportCard",
    document_name: "",
  });

  const [approvalConditions, setApprovalConditions] = useState({
    schoolid: schoolId,
    document_type: "fixedForm",
    document_category: "reportCard",
    document_name: "",
  });

  const requestType = "schoolTerm";

  const [requestTitle, setRequestTitle] = useState("");

  const [documentFormData, setDocumentFormData] = useState([]);

  const {
    allFormsQuery: { data: allFormsData },
  } = useAllForms(conditions);

  const {
    componentsToFormsQuery: { data: componentsToFormsData },
  } = useComponentsToForms(formQueryConditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting({ schoolid: schoolId });

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod({ schoolid: schoolId });

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo({ schoolid: schoolId });

  const { getDocumentForms } = useDocumentForms();
  const getDocumentFormsRef = useRef(getDocumentForms);

  const { getApprovalRequestsByUserId } = useApprovalRequests();
  const getApprovalRequestsByUserIdRef = useRef(getApprovalRequestsByUserId);

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();
  const { user } = useAuthContext();

  useEffect(() => {
    if (allFormsData) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: allFormsData[0].id,
      }));
      setActiveMenu({
        menu_name: allFormsData[0].form_name,
        menu_id: allFormsData[0].id,
      });
      setApprovalData((prev) => ({
        ...prev,
        document_name: allFormsData[0].form_name,
      }));
    }
  }, [allFormsData]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    // let initialUsername = null;

    if (
      filters.schoolYear === null &&
      settingData &&
      settingData.length > 0 &&
      registrationInfoData
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      }

      //학생 리스트
      if (initialSchoolYear) {
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          initialSchoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          filters.schoolYear,
          filters.organPathForSort
        );
        setStudentList(sortedRows);
      }
    }
  }, [
    filters,
    filters.schoolYear,
    registrationInfoData,
    settingData,
    termId,
    termPeriodData,
  ]);

  useEffect(() => {
    if (termPeriodData) {
      const nowPeriod = termPeriodData.filter(
        (r) => r.id.toString() === termId.toString()
      )[0];

      if (nowPeriod) {
        // console.log("nowPeriod", nowPeriod);
        setCurrentPeriod(nowPeriod);
      } else {
        setCurrentPeriod({});
      }
    }
  }, [termId, termPeriodData]);

  useEffect(() => {
    if (studentList && filters.selectedStudent) {
      const selectedObject = studentList.find(
        (r) => r.username === filters.selectedStudent
      );
      if (currentPeriod && Object.keys(currentPeriod).length > 0) {
        setKeyData({
          ...selectedObject,
          currentPeriod: currentPeriod,
        });

        const makeConditions = {
          schoolid: schoolId,
          target_username: filters.selectedStudent,
          document_id: documentFormData[0]?.id || "",
          request_type: requestType,
          term_id: currentPeriod?.id,
        };

        if (makeConditions.document_id) {
          setApprovalConditions(makeConditions);

          getApprovalRequestsByUserIdRef.current.mutate(makeConditions, {
            onSuccess: (data) => {
              setStudentApprovalRequests(data);
            },
            onError: (error) => {
              console.error(error); // 일단 콘솔에 에러를 출력합니다.
              console.error("위와 같은 에러가 났으니 고치삼");
              alert(
                "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                  error.message
              );
            },
          });
        }
      } else {
        setKeyData(selectedObject);
      }
    } else {
      setKeyData({});
    }
  }, [
    currentPeriod,
    documentFormData,
    filters.selectedStudent,
    schoolId,
    studentList,
  ]);

  useEffect(() => {
    if (approvalData && approvalData.document_name.length > 0) {
      getDocumentFormsRef.current.mutate(approvalData, {
        onSuccess: (data) => {
          setDocumentFormData(data);
        },
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    }
  }, [approvalData]);

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setFormQueryConditions((prev) => ({
        ...prev,
        form_id: item.id,
      }));

      setApprovalData((prev) => ({
        ...prev,
        document_name: item.form_name,
      }));
    }
  };

  const handleExtraFunction = () => {
    getApprovalRequestsByUserIdRef.current.mutate(approvalConditions, {
      onSuccess: (data) => {
        setStudentApprovalRequests(data);
      },
      onError: (error) => {
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, filters.schoolYearId, value);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        filters.schoolYearId,
        filters.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));
    }

    if (name === "students") {
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }
  };

  // const showAllInfo = () => {
  //   if (filters.selectedStudent === "") {
  //     handleToastCenterTop("학생을 선택하세요.");
  //     setValidateInput((prev) => ({ ...prev, user: true }));
  //     return;
  //   } else {
  //     setValidateInput((prev) => ({ ...prev, user: false }));
  //   }
  // };

  const handleModifyMode = (e) => {
    e.preventDefault();

    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.currentPeriod?.term_name &&
      keyData.fullname
    ) {
      const onOff = e.target.name;
      if (onOff === "modifyOn") {
        setModifyMode(false);
      } else {
        setModifyMode(true);
      }
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop("학생과 학기를 선택 후 수정할 수 있습니다.");
    }
  };

  const adminMode = () => {
    console.log("adminMode 임");
  };

  // const addPageNumbers = () => {
  //   const pages = document.querySelectorAll(".print-page");
  //   pages.forEach((page, index) => {
  //     const footer = document.createElement("div");
  //     footer.className = "page-footer";
  //     footer.innerText = `Page ${index + 1}`;
  //     page.appendChild(footer);
  //   });
  // };

  const triggerPrint = useReactToPrint({
    content: () => {
      // addPageNumbers();
      return contentToPrint.current;
    },
    documentTitle: `${keyData.school_year}-${keyData?.currentPeriod?.term_name}-${keyData[1]}-${keyData[2]}-${keyData[3]}-${keyData.fullname}`,
    onBeforePrint: () => console.log("before printing..."),
    // onAfterPrint: () => {
    //   console.log("after printing...");
    //   // Cleanup the page numbers after printing
    //   document.querySelectorAll(".page-footer").forEach((el) => el.remove());
    // },
    removeAfterPrint: true,
    pageStyle: `@media print {

      @page {
        size: A4;
        margin: 10mm;
      }


    }`,
  });

  // 일단 페이지 넘버는 포기
  // body {
  //   counter-reset: page;
  // }

  //       tfoot:before {
  //         counter-increment: page;
  //         content: "Page " counter(page);
  //         position: fixed;
  //         bottom: 0;
  //         left: 0;
  //         right: 0;
  //         text-align: center;
  //       }

  // .page-footer {
  //   position: fixed;
  //   bottom: 10mm;
  //   left: 0;
  //   right: 0;
  //   text-align: center;
  // }

  const contentToPrint = useRef(null);
  const handlePrint = () => {
    // console.log("keyData", keyData);
    if (
      keyData.school_year &&
      keyData[1] &&
      keyData[2] &&
      keyData[3] &&
      keyData.currentPeriod?.term_name &&
      keyData.fullname
    ) {
      triggerPrint();
    } else {
      console.log(
        "keyData is incomplete. Please ensure all data is available."
      );
      handleToastCenterTop("학생과 학기를 선택 후 인쇄할 수 있습니다.");
    }
  };

  const handleOpenModal = (e) => {
    const { name } = e.target;

    if (documentFormData.length < 1) {
      handleToastCenterTop("결재 설정이 없습니다. 관리자에게 문의하세요");
      return;
    }

    const matchSchoolStage = documentFormData[0].approving_possible_names?.find(
      (name) => name === keyData[1]
    );

    if (!matchSchoolStage) {
      handleToastCenterTop("문서와 해당 학생이 일치하지 않습니다.");
      return;
    }

    // 권한 체크 로직
    const isApprover = documentFormData.some(
      (form) =>
        form.approvalGroupMembers.username === user.user ||
        keyData.hr_teacher_username.some(
          (teacher) => teacher.username === user.user
        )
    );

    if (!isApprover) {
      handleToastCenterTop(
        "결재 권한이 없습니다. 담임이거나 지정된 결재자여야 합니다."
      );
      return;
    }

    let schoolYear = "";
    let schoolTermName = "";
    let schoolStage = "";
    let studentYear = "";
    let className = "";
    let documentLabel = "";
    let studentName = "";
    let studentId = "";
    let preTitle = "";
    const bulkApproval = "일괄결재";

    // 1단계 결재권자 설정
    const firstStageApprover = keyData.hr_teacher_username.find(
      (teacher) => teacher.username === user.user
    );

    const firstStageUsername = firstStageApprover
      ? firstStageApprover.username
      : keyData.hr_teacher_username[0].username;
    const firstStageFullname = firstStageApprover
      ? firstStageApprover.tname
      : "담임";

    // approvalLines 설정
    const approvalLines = [
      {
        username: firstStageUsername,
        fullname: firstStageFullname,
        stage: 1,
      },
      ...documentFormData.map((member, index) => ({
        username: member.approvalGroupMembers.username,
        fullname: member.approvalGroupMembers.user.fullname,
        stage: member.approvalGroupMembers.stage,
      })),
    ];

    setApprovalLines(approvalLines);

    if (name === "approval") {
      if (
        keyData.school_year &&
        keyData[1] &&
        keyData[2] &&
        keyData[3] &&
        keyData.currentPeriod?.term_name &&
        keyData.fullname
      ) {
        schoolYear = keyData.currentPeriod.school_year;
        schoolTermName = keyData.currentPeriod.term_name;
        schoolStage = keyData["1"];
        studentYear = keyData["2"];
        className = keyData["3"];
        documentLabel = documentFormData[0].document_label;
        studentName = keyData.fullname;
        studentId = keyData.username;

        preTitle =
          schoolYear +
          "-" +
          schoolTermName +
          "-" +
          schoolStage +
          "-" +
          studentYear +
          "-" +
          className +
          "-" +
          documentLabel +
          "-" +
          studentName +
          "-" +
          studentId;

        setRequestTitle(preTitle);
        setIsModalOpen(true);
        setStudentListForApproval(
          studentList.filter((r) => r.username === studentId)
        );
      } else {
        handleToastCenterTop("학생과 학기를 선택 후 인쇄할 수 있습니다.");
      }
    } else if (name === "bulkApproval") {
      if (
        keyData.school_year &&
        keyData[1] &&
        keyData[2] &&
        keyData[3] &&
        keyData.currentPeriod?.term_name
      ) {
        schoolYear = keyData.currentPeriod.school_year;
        schoolTermName = keyData.currentPeriod.term_name;
        schoolStage = keyData["1"];
        studentYear = keyData["2"];
        className = keyData["3"];
        documentLabel = documentFormData[0].document_label;

        preTitle =
          schoolYear +
          "-" +
          schoolTermName +
          "-" +
          schoolStage +
          "-" +
          studentYear +
          "-" +
          className +
          "-" +
          documentLabel +
          "-" +
          bulkApproval;

        setStudentListForApproval(studentList);
        setRequestTitle(preTitle);
        setIsModalOpen(true);
      } else {
        handleToastCenterTop("반과 학기를 선택 후 인쇄할 수 있습니다.");
      }
    }

    //2024-1-중등-7-소민-학기성적표-여중등-middle.girl@sampleschool
    //2024-1-중등-7-소민-학기성적표-일괄결재
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={allFormsData}
              extraFunction={handleQueryCondition}
              itemName="form_name"
              itemLabel="form_label"
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="d-flex justify-content-end flex-wrap mt-2">
              <FiltersBar
                settingData={settingData}
                handleDropdownChange={handleDropdownChange}
                filters={filters}
                studentList={studentList}
                // validateInput={validateInput}
                termPeriodData={termPeriodData}
                termId={termId}
                currentPeriod={currentPeriod}
                modifyMode={modifyMode}
                disabled={modifyMode}
              />
            </div>
            <div className="d-flex justify-content-end flex-wrap mb-3 mt-2">
              <ReportCardButtonBar
                handlePrint={handlePrint}
                handleModifyMode={handleModifyMode}
                initiateApproval={handleOpenModal}
                adminMode={adminMode}
                contentToPrint={contentToPrint}
                modifyMode={modifyMode}
              />
            </div>
            <div
              id="gradeReportPrintArea"
              className="d-flex justify-content-center print-page"
              ref={contentToPrint}
            >
              <table className="" style={{ width: "210mm" }}>
                <thead>
                  <tr>
                    <th colSpan="3">
                      <PageHeader />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="1"></td>
                    <td colSpan="1" className="p-2">
                      {componentsToFormsData &&
                        componentsToFormsData.map((item, index) => (
                          // <div key={`${item.id}-${item.order}-${index}`}>
                          <DynamicComponentsRenderer
                            components={item.components}
                            customizedContents={item.customized_contents}
                            keyData={keyData}
                            modifyMode={modifyMode}
                            setIsLoadingModal={setIsLoadingModal}
                            key={`${item.id}-${item.order}-${index}`}
                            componentsToFormId={item.id}
                            approvalData={studentApprovalRequests}
                          />
                          // </div>
                        ))}
                    </td>
                    <td colSpan="1"></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="3">
                      <PageFooter />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ApprovalProcess
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
          documentData={keyData}
          approvalData={approvalData}
          requestType={requestType}
          requestTitle={requestTitle}
          studentListForApproval={studentListForApproval}
          documentFormData={documentFormData}
          approvalLines={approvalLines}
          handleExtraFunction={handleExtraFunction}
        />
      )}
    </div>
  );
}

/*
keyData = {
    "0": "나무와숲 학교",
    "1": "중등",
    "2": "7",
    "3": "1",
    "id": 87,
    "schoolid": "namusup",
    "school_year": "2024",
    "username": "middle.girl@namusup",
    "studentno": "1",
    "status": "재학중",
    "lastattended": null,
    "attendance_remark": "",
    "future_plan": "",
    "superkey": "namusup-2024-middle.girl@namusup",
    "createdAt": "2024-07-10T02:21:39.000Z",
    "updatedAt": "2024-07-10T02:21:39.000Z",
    "order0": 1,
    "hr_teacher_username": [
        {
            "tname": "김선생",
            "username": "teacher.kim@namusup"
        }
    ],
    "order1": 2,
    "order2": 1,
    "order3": 1,
    "fullname": "여중등",
    "gender": "여",
    "hr_teacher": "김선생",
    "organization_path": "/38/244/245/246",
    "currentPeriod": {
        "id": 15,
        "schoolid": "namusup",
        "school_year_id": 38,
        "term_name": "1",
        "term_category": "학기",
        "start_date": "2024-03-01",
        "end_date": "2024-07-26",
        "class_days": "90",
        "applied_hierarchy_one": null,
        "applied_hierarchy_two": null,
        "createdAt": "2024-05-26T03:16:10.000Z",
        "updatedAt": "2024-07-10T02:39:16.000Z",
        "TermSchoolYear": {
            "school_year": "2024"
        },
        "TermHierarchyOne": null,
        "TermHierarchyTwo": null,
        "school_year": "2024",
        "hierarchy_one_name": "전체",
        "hierarchy_two_name": "전체"
    }
};

*/
