export default class VariousStudentRecordsService {
  constructor(http) {
    this.http = http;
  }

  async findAllVariousStudentRecords(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/variousstudentrecords/readall?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async getVariousStudentDataWithRegi(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/variousstudentrecords/readwithregi?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async getVariousStudentRecordByUserId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/variousstudentrecords/readbyid?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async getAllVariousStudentRecordByUserId(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/variousstudentrecords/readallbyid?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async createOrUpdateVariousStudentRecords(data) {
    return this.http.fetch(`/api/variousstudentrecords/createorupdate`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async createOrUpdateVariousStudentRecordsBulk(dataArray) {
    return this.http.fetch(`/api/variousstudentrecords/createorupdatebulk`, {
      method: "POST",
      body: JSON.stringify(dataArray),
    });
  }

  async deleteVariousStudentRecords(data) {
    return this.http.fetch(`/api/variousstudentrecords/delete`, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  async deleteVariousStudentRecordOne(id) {
    return this.http.fetch(`/api/variousstudentrecords/deleteone`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
