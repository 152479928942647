import React, { useEffect, useState } from "react";
import useSettingEvalItems from "../../../../hooks/useSettingEvalItems";
import { validateObjectByFields } from "../../../../util/validate";
import { useUIContext } from "../../../../context/UIContext";
import { arrangeDataByHierarchy } from "../../../../util/specialCase";

export default function EmptyEvalItemTr({
  setHideAddBtn,
  setIsAddingRow,
  addNewRow,
  lessonSetting,
  setAddNewRow,
  setEvalItemsData,
  getEvalItemsByLessonId,
}) {
  const { createEvalItems } = useSettingEvalItems();

  const [isSaving, setIsSaving] = useState(false);

  const [validateStyles, setValidateStyles] = useState({});

  const { handleToastCenterTop } = useUIContext();

  useEffect(() => {
    if (!addNewRow.evaluation_type && lessonSetting) {
      const thisSetting = lessonSetting.find(
        (r) => r.item_code === "evalMethod"
      );

      let firstOption;
      firstOption = thisSetting.detailed_setting[0]; // 첫 번째 설정
      if (firstOption === "평가항목별") {
        firstOption = thisSetting.detailed_setting[1];
      }
      if (!firstOption) {
        alert(
          "수업 환경 설정이 되지 않은 것 같습니다. 관리자에게 문의 하세요."
        );
      }
      setAddNewRow((prevLesson) => ({
        ...prevLesson,
        evaluation_type: firstOption,
      }));
    }
  }, [addNewRow.evaluation_type, lessonSetting, setAddNewRow]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setAddNewRow((prev) => ({ ...prev, [name]: value }));

    // 입력 요소가 textarea일 경우 높이 자동 조정
    if (type === "textarea") {
      const textarea = e.target;
      textarea.style.height = "auto"; // 높이를 자동으로 재설정
      textarea.style.height = `${textarea.scrollHeight}px`; // 스크롤 높이로 높이 설정
    }
    console.log("addNewRow", addNewRow);
    console.log("name, value, type", name, value, type);
  };

  const handleCancel = () => {
    setAddNewRow((prev) => ({
      ...prev,
      hierarchy_level: "",
      content: "",
      evaluation_type: "",
      order: "",
      parent_id: "",
    }));
    setHideAddBtn(false);
    setIsAddingRow(false);
  };

  const createNewRow = () => {
    const fieldsToValidate = [
      { key: "content", type: "string", required: true, name: "평가내용" },
      {
        key: "evaluation_type",
        type: "string",
        required: true,
        name: "평가제",
      },
      { key: "order", type: "number", required: true, name: "순서" },
    ];

    setValidateStyles({});

    const errorObject = validateObjectByFields(addNewRow, fieldsToValidate);
    const typeErrors = Object.values(errorObject.type || {});
    const requiredErrors = Object.values(errorObject.required || {});
    const allErrors = typeErrors.concat(requiredErrors);
    const errorMessageString = allErrors.join("\n");

    if (allErrors.length > 0) {
      const newStyles = {};
      Object.keys(errorObject.type).forEach((key) => (newStyles[key] = true));
      Object.keys(errorObject.required).forEach(
        (key) => (newStyles[key] = true)
      );
      setValidateStyles(newStyles);

      handleToastCenterTop(errorMessageString);
      return;
    }
    setIsSaving(true);

    const forWhere = {
      schoolid: addNewRow.schoolid,
      lesson_id: addNewRow.lesson_id,
    };

    // console.log("forWhere", forWhere);

    createEvalItems.mutate(addNewRow, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsSaving(false);
        setIsAddingRow(false);
        setHideAddBtn(false);
        setAddNewRow((prev) => ({
          ...prev,
          hierarchy_level: "",
          content: "",
          evaluation_type: "",
          order: "",
          parent_id: "",
        }));

        // console.log("이게 왜 안 돼?");
        getEvalItemsByLessonId.mutate(forWhere, {
          onSuccess: (data) => {
            const processedData = arrangeDataByHierarchy([...data]);
            setEvalItemsData(processedData);
          },
          onError: (error) => {
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(
              "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                error.message
            );
          },
        });
      },
      onError: (error) => {
        setIsSaving(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <tr>
      <th scope="row" className="text-center align-middle">
        {addNewRow?.hierarchy_level || ""}
      </th>
      <td
        className="changePaddings d-flex"
        style={{ paddingLeft: `${(addNewRow?.hierarchy_level - 1) * 25}px` }}
      >
        <textarea
          className="form-control"
          name="content"
          value={addNewRow?.content || ""}
          onChange={handleChange}
          rows={1}
          required
          style={{
            backgroundColor: validateStyles?.content ? "pink" : undefined,
          }}
        />
      </td>
      <td className="text-center align-middle">
        <select
          className="select-like-span ms-2"
          value={addNewRow?.evaluation_type || ""}
          name="evaluation_type"
          onChange={handleChange}
          style={{
            backgroundColor: validateStyles?.evaluation_type
              ? "pink"
              : undefined,
          }}
          required
        >
          {lessonSetting?.filter((r) => r.item_code === "evalMethod").length >
            0 &&
            lessonSetting
              ?.filter((r) => r.item_code === "evalMethod")[0]
              .detailed_setting.map((setting, index) => {
                if (
                  setting !== "평가항목별" &&
                  setting !== "서술형" &&
                  setting !== "커스텀"
                ) {
                  return (
                    <option key={index} value={setting}>
                      {setting}
                    </option>
                  );
                }
                return null;
              })}
        </select>
      </td>
      <td className="text-center align-middle">
        <input
          type="number"
          className="input-like-span ms-3 ps-2"
          value={addNewRow?.order || ""}
          name="order"
          onChange={handleChange}
          required
          style={{
            backgroundColor: validateStyles?.order ? "pink" : undefined,
          }}
        />
      </td>
      <td className="text-center align-middle p-0 m-0"></td>
      <td className="text-center align-middle">
        <button
          type="button"
          onClick={handleCancel}
          className="btn btn-sm btn-secondary"
        >
          취소
        </button>

        {isSaving ? (
          <button className="btn btn-sm btn-primary ms-1" disabled>
            추가중
          </button>
        ) : (
          <button
            type="button"
            onClick={createNewRow}
            className="btn btn-sm btn-primary ms-1"
          >
            추가
          </button>
        )}
      </td>
    </tr>
  );
}
