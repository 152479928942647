import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CounselingService from "../service/counseling";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const counselingService = new CounselingService(httpClient);

export default function useCounseling(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();

  //users db에서 counseling join해서 가져온다.
  const counselingQuery = useQuery(
    ["counseling", conditions || ""],
    () => counselingService.findAllCounselingWithUserAndRegi(conditions),
    {
      staleTime: 1000 * 60 * 10, //10분,
    }
  );

  const createCounseling = useMutation(
    (addCounseling) => counselingService.createCounseling(addCounseling),
    {
      onSuccess: () => queryClient.invalidateQueries(["counseling"]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const updateCounseling = useMutation(
    (counseling) => counselingService.updateCounseling(counseling),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["counseling"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error updating counseling:", error);
        alert(error);
        // Add your error handling logic here
      },
    }
  );

  const deleteCounseling = useMutation(
    (id) => counselingService.deleteCounseling(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["counseling"]);
      },
    }
  );

  return {
    counselingQuery,
    createCounseling,
    updateCounseling,
    deleteCounseling,
  };
}
