export default class GroupsService {
  constructor(http) {
    this.http = http;
  }

  async findAllGroups(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/groups/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createGroups(data) {
    return this.http.fetch(`/api/groups/create`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async updateGroups(data) {
    return this.http.fetch(`/api/groups/update`, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  async deleteGroups(id) {
    return this.http.fetch(`/api/groups/delete`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
