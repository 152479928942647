import React, { useEffect, useMemo, useState } from "react";
import AddRegistration from "./components/AddRegistration";
import UniTable from "../../unicomponents/UniTable";
import { useLocation } from "react-router-dom";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import {
  createFilteredAndSortedOptions,
  uniqueDataOptionsKeys,
} from "../../util/dealingArrObj";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import LoadingModal from "../../unicomponents/LoadingModal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { MdCheck, MdDelete, MdSave } from "react-icons/md";
import UpdateRegiModal from "./components/UpdateRegiModal";
import useUsers from "../../hooks/useUsers";
import HelpHyperLink from "../../unicomponents/HelpHyperLink";

function RegistrationInfo() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = { schoolid: schoolId };

  const [schoolYear, setSchoolYear] = useState(null);
  const [filteredSetting, setFilteredSetting] = useState({});
  const [addRegi, setAddRegi] = useState({ schoolid: schoolId });
  const [updateRegi, setUpdateRegi] = useState();
  const [rows, setRows] = useState([]);
  const [allSortedRows, setAllSortedRows] = useState([]);
  const [regiColumnData, setRegiColumnData] = useState([]);
  const [idsForKey, setIdsForKey] = useState(null);
  const [filterOrgan, setFilterOrgan] = useState({});
  const [filterModal, setFilterModal] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [isSaved, setIsSaved] = useState({});
  const [organPathForSort, setOrganPathForSort] = useState("/");

  const {
    registrationInfoQuery: { error, data: registrationInfoData },
    deleteRegistrationInfo,
  } = useRegistrationInfo(conditions);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    usersQuery: { data: userData },
  } = useUsers(conditions);

  useEffect(() => {
    // console.log("registrationInfoData", registrationInfoData);
    if (schoolYear === null) {
      //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
      let initialSchoolYear = null;
      let initialSchoolYearId = null;
      if (settingData && settingData.length > 0) {
        // console.log("settingData", settingData);
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];
        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
      }
      setSchoolYear(initialSchoolYear);

      //업데이트용 데이터 기본 세팅
      setAddRegi((addRegi) => ({ ...addRegi, school_year: initialSchoolYear }));
      const filtered = settingData.filter(
        (r) => r.school_year === initialSchoolYear
      );

      //드랍다운용이다.
      setFilteredSetting(filtered);
      // console.log("filteredSetting2", filtered);

      setFilterOrgan((prevFilter) => ({
        ...prevFilter,
        0: initialSchoolYearId,
      }));

      setFilterModal((prevFilter) => ({
        ...prevFilter,
        0: initialSchoolYearId,
      }));

      // console.log("filterOrgan1", filterOrgan);
      // setFilterOrgan({
      //   ...filterOrgan,
      //   0: initialSchoolYearId,
      // });
      const forIdsForKey = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(forIdsForKey);

      //columns 세팅
      const dColumns = Object.keys(forIdsForKey)
        .filter((item) => item !== "0")
        .map((item) => {
          return {
            field: item,
            headerAlign: "center",
            headerName: forIdsForKey[item][0],
            align: "center",
          };
        });

      setRegiColumnData([...dColumns, ...columnData]);

      //rows 데이터처리
      if (registrationInfoData && settingData && initialSchoolYear) {
        const sortedRows = generateRows(
          registrationInfoData,
          settingData,
          initialSchoolYear,
          organPathForSort
        );
        setAllSortedRows(sortedRows);
        setRows(sortedRows);
        // console.log("sortedArray", sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("settingData", settingData);

        const sortedRows = generateRows(
          registrationInfoData,
          settingData,
          schoolYear,
          organPathForSort
        );
        setAllSortedRows(sortedRows);
        setRows(sortedRows);
      }
    }
    // columnData는 dependency로 필요없기 때문
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingData, schoolYear, registrationInfoData]);

  function generateRows(
    registrationInfoData,
    settingData,
    schoolYear,
    organPathForSort = "/"
  ) {
    let toBeRows = registrationInfoData
      .filter((r) => r.school_year === schoolYear)
      .map((row) => {
        const { organization_path, user, ...otherFields } = row;
        const paths = organization_path.split("/").filter((path) => path);
        const pathFields = paths.reduce((acc, curr, index) => {
          const thisGroup = settingData.find((r) => r.id === Number(curr));

          if (thisGroup) {
            return {
              ...acc,
              [index]: thisGroup.group_name,
              ["order" + index]: thisGroup.order,
              hr_teacher_username: thisGroup.hr_teacher_username,
            };
          } else {
            console.error(`No group found with id: ${curr}`);
            return acc;
          }
        }, {});

        // console.log("pathFields", pathFields);
        let hr_teacher = "";
        if (pathFields.hr_teacher_username) {
          hr_teacher = pathFields.hr_teacher_username
            .map((item) => item.tname)
            .join(", ");
        }
        const userObject = { fullname: user.fullname, gender: user.gender };

        // console.log("hr_teacher", hr_teacher);

        return {
          ...otherFields,
          ...pathFields,
          ...userObject,
          hr_teacher,
          organization_path,
        };
      })
      .sort((a, b) => a.studentno - b.studentno);

    // console.log("toBeRows", toBeRows);
    // console.log("organPathForSort", organPathForSort);

    if (organPathForSort !== "/" && !organPathForSort.startsWith("nu")) {
      toBeRows = toBeRows.filter((row) =>
        row.organization_path.includes(organPathForSort)
      );
    }

    // console.log("toBeRows2", toBeRows);

    const sortedArray = toBeRows.sort((a, b) => {
      const orderKeysA = Object.keys(a)
        .filter((key) => key.startsWith("order"))
        .sort();
      const orderKeysB = Object.keys(b)
        .filter((key) => key.startsWith("order"))
        .sort();

      for (let i = 0; i < orderKeysA.length; i++) {
        if (a[orderKeysA[i]] !== b[orderKeysB[i]]) {
          return a[orderKeysA[i]] - b[orderKeysB[i]];
        }
      }

      return 0;
    });

    return sortedArray;
  }

  const { arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc } = {
    arrayData: settingData,
    displayKey: "school_year",
    valueKey: "id",
    filters: { hierarchy_level: 0, parent_id: 0 },
    idKey: "id",
    sortKey: "school_year",
    isDesc: true,
  };

  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey,
      valueKey,
      filters,
      idKey,
      sortKey,
      isDesc,
    });
  }, [arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const organPath =
      e.target.selectedOptions[0].getAttribute("data-name") + "/" + value;

    setOrganPathForSort(organPath);
    setRows(
      generateRows(registrationInfoData, settingData, schoolYear, organPath)
    );
    const keys = Object.keys(idsForKey);
    const maxKey = Math.max(...keys.map(Number));

    if (maxKey.toString() === name.toString()) {
      setAddRegi((addRegi) => ({ ...addRegi, organization_path: organPath }));
    } else {
      setAddRegi((addRegi) => ({ ...addRegi, organization_path: "" }));
    }

    if (name === "0") {
      const filtered = settingData.filter(
        (r) => r.school_year === selectedOptionText
      );
      setFilteredSetting(filtered);
      const uniqueOptions = uniqueDataOptionsKeys(
        filtered,
        "hierarchy_level",
        "level_name_new"
      );
      setIdsForKey(uniqueOptions);

      //columns 세팅
      const dColumns = Object.keys(uniqueOptions)
        .filter((item) => item !== "0")
        .map((item) => {
          return {
            field: item,
            headerAlign: "center",
            headerName: uniqueOptions[item][0],
            align: "center",
          };
        });

      setRegiColumnData([...dColumns, ...columnData]);

      setAddRegi((addRegi) => {
        const { organization_path, ...rest } = addRegi;
        return {
          ...rest,
          school_year: selectedOptionText,
        };
      });

      setSchoolYear(selectedOptionText);
    }
    // console.log("addRegi", addRegi);
    setFilterOrgan((prevFilter) => {
      const newFilter = { ...prevFilter, [name]: value };
      // console.log("newFilter", newFilter);
      Object.keys(newFilter).forEach((key) => {
        if (Number(key) > Number(name)) {
          delete newFilter[key];
        }
      });
      return newFilter;
    });

    // console.log("filterOrgan2", filterOrgan);
  };

  const handleSaveClick = (row) => {
    setModalOpen(true);
    setUpdateRegi(row);
    const paths = row.organization_path.split("/");

    let counter = 0;
    const filters = paths.reduce((result, value) => {
      if (value !== "") {
        result[counter++] = value;
      }
      return result;
    }, {});

    setFilterModal(filters);
    setIsSaved(false);
    // console.log("row", row);
  };

  const handleDeleteClick = (id) => {
    // Confirmation prompt
    if (window.confirm("정말 삭제하시겠습니까? 신중하게 생각하세요")) {
      setIsUpLoading(true);
      deleteRegistrationInfo.mutate(id, {
        onSuccess: (successMessage) => {
          setRows(rows.filter((row) => row.id !== id));
          setIsUpLoading(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(error.message);
        },
      });
    } else {
      // User clicked 'Cancel', do nothing
      console.log("취소되었습니다.");
    }
  };

  const columnData = [
    {
      field: "fullname",
      headerAlign: "center",
      headerName: "이름",
      align: "center",
    },
    {
      field: "username",
      headerAlign: "center",
      headerName: "사용자아이디",
      align: "center",
      width: 180,
    },
    {
      field: "studentno",
      headerAlign: "center",
      headerName: "번호",
      align: "center",
    },
    {
      field: "gender",
      headerAlign: "center",
      headerName: "성별",
      align: "center",
    },
    {
      field: "hr_teacher",
      headerAlign: "center",
      headerName: "담임",
      align: "center",
      width: 150,
    },
    {
      field: "status",
      headerAlign: "center",
      headerName: "상태",
      align: "center",
    },
    {
      field: "saveDelete",
      headerAlign: "center",
      headerName: "수정/삭제",
      align: "center",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={
            isSaved[params.id] ? <MdCheck size={20} /> : <MdSave size={20} />
          }
          label="수정"
          className="saveDataBtn"
          onClick={() => handleSaveClick(params.row)}
        />,
        <GridActionsCellItem
          icon={<MdDelete size={20} />}
          label="삭제"
          onClick={() => handleDeleteClick(params.id)}
        />,
      ],
    },
  ];

  return (
    <>
      <div className="ms-3 basicWidth">
        <div className="container-fluid">
          {isUpLoading && <LoadingModal />}
          {error && error.toString()}
          <div className="row mb-4 mt-2">
            {modalOpen && (
              <UpdateRegiModal
                setModalOpen={setModalOpen}
                updateRegi={updateRegi}
                setUpdateRegi={setUpdateRegi}
                idsForKey={idsForKey}
                filteredSetting={filteredSetting}
                filterModal={filterModal}
                setFilterModal={setFilterModal}
                userData={userData}
                setIsUpLoading={setIsUpLoading}
              />
            )}
            <div className="col-3">
              <div className="d-flex">
                <h3 className="fw-bold mt-3">재적정보</h3>
                <HelpHyperLink link={"https://youtu.be/iLtudESuyD4"} />
              </div>
            </div>
            <div className="col-9 d-flex gap-2 align-items-center">
              <select
                className="form-control"
                name="0"
                onChange={handleChange}
                value={filterOrgan[0] || ""}
              >
                {settingData?.length > 0 && options}
              </select>
              {filteredSetting?.length > 0 &&
                Object.keys(idsForKey).map((item) => {
                  if (item === "0") return null;
                  return (
                    <select
                      key={item}
                      name={item}
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="" data-name="">
                        {idsForKey[item][0]}
                      </option>
                      {filteredSetting
                        .filter(
                          (r) =>
                            r.parent_id ===
                            Number(filterOrgan[(Number(item) - 1).toString()])
                        )
                        .sort((a, b) => {
                          const aValue = a["order"];
                          const bValue = b["order"];
                          if (
                            typeof aValue === "number" &&
                            typeof bValue === "number"
                          ) {
                            // 숫자인 경우
                            return aValue - bValue;
                          } else {
                            // 문자열인 경우
                            return aValue.localeCompare(bValue);
                          }
                        })
                        .map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            data-name={option.organization_path}
                          >
                            {option.group_name}
                          </option>
                        ))}
                    </select>
                  );
                })}
            </div>
          </div>

          <div className="mb-2">
            <AddRegistration
              conditions={conditions}
              setIsUpLoading={setIsUpLoading}
              addRegi={addRegi}
              setAddRegi={setAddRegi}
              schoolYear={schoolYear}
              rows={rows}
              userData={userData}
              allSortedRows={allSortedRows}
            />
          </div>
          <div style={{ width: "95vw", overflowX: "auto", maxWidth: "1536px" }}>
            <UniTable
              columns={regiColumnData}
              rows={rows}
              exportedFileName="재적명단"
              rowModesModel={rowModesModel}
              setRowModesModel={setRowModesModel}
              setRows={setRows}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default RegistrationInfo;

/*불러와야 할 데이터
1. organization_setting where schoolid
  -> 오른쪽 상위 드랍다운
  -> 학년도는 필수로 최상위 것 먼저 나오게 하고

쉽게 가자. 그냥 hierarchy 0 인거 다 불러와서 sort 하면 끝이다. setSchoolYear 로 상태 관리하자. 

  -> 그 학년도로 필터해서 hierarchy_level 갯수만큼 반복해서 드랍다운 만든다.

  schoolYear 정보가 있으면 hierarchy schoolyear로 filter해서 몇 개인지 만든다. 그리고 그 수만큼 드랍다운 만든다.

  setFilter를 만든다. {school_year: 2024, 1: (level1의 id), 2: level2의 id, 3: level3의 id...]
    이거 dropdown이 change 할 때 마다 handleChange로 update 하고, update된 것을 바탕으로 dropdown option이 나온다.


  -> 드랍다운에는 hierarchy_level 안에 있는 group_name이 들어간다. 맨 위에는 level_name 1개 들어가고



  -> level_name은 1개밖에 못하도록 나중에 업데이트 해야함
   group_name이 보이지만, value는 schoolid로
  -> 필터를 잘 해야하고, 상위 것이 선택되지 않으면 하위도 안 나오도록 한다.

  - 아래 테이블에 넣을 컬럼 세팅에 hierarchy 상위순으로 있는 갯수만큼 column 나오는데 일조한다.
  

2. users where schoolid, student
  -> 학생이름을 입력하면 사용자 아이디가 나와서 선택할 수 있도록한다. 현재 학교 다니는 유저만으로 한다.
  - 아이디 선택하면 성별, 생년월일 자동으로 나오게 한다.
  - 담임이름과 교사아이디도 마찬가지로 한다.
  - 입력하는 것 모두 handleChange 같은 걸로 상태에 업데이트 한다.
  - 저장을 누르면 username과 번호, 교사아이디(배열로), 
  그리고 상단의 드랍다운의 아이디로 조합되는 organization_path, school_year, schoolid를 서버에 보내서 insert 한다.
  
3. registrationinfo where schoolid, 학년도 join with users by username (password 등 몇 개는 빼고)
 - 학생의 organization_path를 풀어서 그 id의 해당하는 group_name을 넣는다.
 -이름, 성별, 담임이름은 username으로 가져온 것 넣고, 번호, 상태는 regis에서 넣는다.
 - delete, update 가능하도록 한다.
 - 수정 누르면 모달이 뜨면서 세부 업데이트 가능하게 한다.
*/
