import React from "react";

/*
이 드랍다운을 사용하기 위한 props는 아래와 같이 불러온다.

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod(conditions);


학년도, 과정, 학년의 의해서 filter가 되어야 한다.
현재 날짜 기준으로 먼저 한번 나와야 한다. 

차근차근 생각해 보자.

1. 학년도 id로 필터한다
2. start_date로 정렬한다. (더 옛날 시간이 우선으로)
3. hierarchyOneId이 있으면 이것으로 필터하는데, 일치하는 것이 없으면 null인 것을 return 한다. 
4. hierarchyTwoId이 있으면 이것으로 필터하는데, 일치하는 것이 없으면 null인 것을 return 한다.
... 흠.. 이러면 1학기만 세팅한 과정이면 한 학기만 나오겠군... 어쩌겠어 그렇게 해야지. 
과정/학년별로 세팅했으면 모든 학기 세팅해야 한다고 해야겠다.

그렇게 나온 데이터로 옵션을 만든다.

*/

export default function DropdownTermDate({
  termPeriodData,
  handleDropdownChange,
  termId,
  schoolYearId,
  hierarchyOneId,
  hierarchyTwoId,
  required = false,
  disabled = false,
  highLight = false,
  isTitle = true,
  styleClass = "form-control",
}) {
  function createTermOptions(arrayData, filters) {
    arrayData = arrayData || [];

    let filteredData = arrayData.sort((a, b) =>
      String(a.start_date).localeCompare(String(b.start_date))
    );

    // Step 1: school_year_id로 필터링
    filteredData = filteredData.filter(
      (data) =>
        data.school_year_id.toString() === filters.school_year_id.toString()
    );

    // Step 2: applied_hierarchy_one 필터링, 결과가 있는 경우만 진행
    const hierarchyOneFiltered = filteredData.filter((data) => {
      const dataValue = data.applied_hierarchy_one?.toString();
      const filterValue = filters.applied_hierarchy_one?.toString();
      return dataValue === filterValue;
    });

    // Step 3: applied_hierarchy_two 필터링, 필요한 경우만 진행
    if (hierarchyOneFiltered.length > 0) {
      const hierarchyTwoFiltered = hierarchyOneFiltered.filter((data) => {
        const dataValue = data.applied_hierarchy_two?.toString();
        const filterValue = filters.applied_hierarchy_two?.toString();
        return dataValue === filterValue;
      });

      if (hierarchyTwoFiltered.length > 0) {
        filteredData = hierarchyTwoFiltered;
      } else {
        // If no results for hierarchy_two, return data where applied_hierarchy_two is null or empty
        filteredData = hierarchyOneFiltered.filter(
          (data) =>
            data.applied_hierarchy_two === null ||
            data.applied_hierarchy_two === ""
        );
      }
    } else {
      // If no results for hierarchy_one, return data where applied_hierarchy_one is null or empty
      filteredData = filteredData.filter(
        (data) =>
          data.applied_hierarchy_one === null ||
          data.applied_hierarchy_one === ""
      );
    }

    return filteredData.map((option) => (
      <option
        key={option.id}
        value={option.id}
        data-start={option.start_date}
        data-end={option.end_date}
        data-classdays={option.class_days}
      >
        {option.term_name + option.term_category}
      </option>
    ));
  }

  const options = createTermOptions(termPeriodData, {
    school_year_id: schoolYearId,
    applied_hierarchy_one: hierarchyOneId,
    applied_hierarchy_two: hierarchyTwoId,
  });

  const combinedClassName = `${styleClass} ${
    highLight ? "highlight-validate" : ""
  }`;

  return (
    <select
      className={combinedClassName}
      name="termDate"
      onChange={handleDropdownChange}
      value={termId || ""}
      required={required}
      disabled={disabled}
    >
      {isTitle && <option value="">학기</option>}
      {options}
    </select>
  );
}
