import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";
import RegistrationInfoService from "../service/registrationInfo";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const registrationInfoService = new RegistrationInfoService(httpClient);

export default function useRegistrationInfo(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();

  //conditions 에는 schoolid 가 들어있음

  // console.log("regi conditions", conditions);
  const registrationInfoQuery = useQuery(
    ["registrationInfoConditions"],
    () => {
      // console.log("regi in conditions", conditions);
      return registrationInfoService.findAllRegistrationInfoByConditions(
        conditions
      );
    },
    {
      enabled: !!conditions?.schoolid,
      staleTime: 1000 * 60 * 1, //1분,
    }
  );

  const getRegistrationInfoByUsername = useMutation((forWhere) =>
    registrationInfoService.getRegistrationInfoByUsername(forWhere)
  );

  // console.log("initialSchoolYear2", initialSchoolYear);

  // 보내는 데이터
  const createRegistrationInfo = useMutation(
    (regiInfo) => registrationInfoService.createRegistration(regiInfo),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["registrationInfoConditions"]),
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error("Error creating:", error);
        // Add your error handling logic here
      },
    }
  );

  const updateRegistrationInfo = useMutation(
    (regiInfo) => registrationInfoService.updateRegistration(regiInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["registrationInfoConditions"]);
      },
      onError: (error) => {
        // Handle the failure by displaying an error message or performing other actions
        console.error(
          "같은 단계에 같은 그룹 이름은 안됩니다. Error updating:",
          error
        );
      },
    }
  );

  const deleteRegistrationInfo = useMutation(
    (id) => registrationInfoService.deleteRegistration(id),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(["registrationInfoConditions"]);

        // Snapshot the previous value
        const previousData = queryClient.getQueryData([
          "registrationInfoConditions",
        ]);

        // Return the snapshotted value
        return { previousData };
      },
      onError: (error, id, context) => {
        // Rollback to the previous value
        queryClient.setQueryData(
          ["registrationInfoConditions"],
          context.previousData
        );
      },
      onSuccess: async (data, variables) => {
        // Refetch the query first
        await queryClient.invalidateQueries(["registrationInfoConditions"]);

        // Then update the data based on the refetched query
        queryClient.setQueryData(["registrationInfoConditions"], (old) =>
          old ? old.filter((d) => d.id !== variables) : []
        );
      },
    }
  );

  return {
    registrationInfoQuery,
    createRegistrationInfo,
    updateRegistrationInfo,
    deleteRegistrationInfo,
    getRegistrationInfoByUsername,
  };
}
