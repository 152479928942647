//재사용 할지 모르고 만들었다가 급하게 재사용하는 것으로 가져옴. 아마 나중에 수정이 필요할 듯
export function setTermIdByToday(
  termPeriodData,
  setTermId,
  schoolYearId,
  hierarchyOneId = "",
  hierarchyTwoId = ""
) {
  let filteredData = termPeriodData.sort((a, b) =>
    String(a.start_date).localeCompare(String(b.start_date))
  );

  filteredData = filteredData.filter(
    (data) => data.school_year_id.toString() === schoolYearId.toString()
  );

  // Step 2: applied_hierarchy_one 필터링, 결과가 있는 경우만 진행
  const hierarchyOneFiltered = filteredData.filter((data) => {
    const dataValue = data.applied_hierarchy_one?.toString();
    const filterValue = hierarchyOneId?.toString();
    return dataValue === filterValue;
  });

  // Step 3: applied_hierarchy_two 필터링, 필요한 경우만 진행
  if (hierarchyOneFiltered.length > 0) {
    const hierarchyTwoFiltered = hierarchyOneFiltered.filter((data) => {
      const dataValue = data.applied_hierarchy_two?.toString();
      const filterValue = hierarchyTwoId?.toString();
      return dataValue === filterValue;
    });

    if (hierarchyTwoFiltered.length > 0) {
      filteredData = hierarchyTwoFiltered;
    } else {
      // If no results for hierarchy_two, return data where applied_hierarchy_two is null or empty
      filteredData = hierarchyOneFiltered.filter(
        (data) =>
          data.applied_hierarchy_two === null ||
          data.applied_hierarchy_two === ""
      );
    }
  } else {
    // If no results for hierarchy_one, return data where applied_hierarchy_one is null or empty
    filteredData = filteredData.filter(
      (data) =>
        data.applied_hierarchy_one === null || data.applied_hierarchy_one === ""
    );
  }

  const today = new Date().toISOString().slice(0, 10); // YYYY-MM-DD 형식의 문자열로 오늘 날짜 얻기

  // 현재 날짜가 start_date와 end_date 사이에 있는 데이터만 필터링
  const validTerms = filteredData.filter(
    (data) => data.start_date <= today && data.end_date >= today
  );

  // 유효한 term이 있으면 setTermId 호출
  if (validTerms.length > 0) {
    setTermId(validTerms[0].id);
  } else {
    setTermId("");
  }
}

//시작날짜와 종료날짜 사이에 있는 데이터만 추출하기 위해 쓰인다.
//Data에 []에 start_date 라는 객체가 있어야 가능하다.
export function filterDataByDateRange(
  data,
  startDate,
  endDate,
  dateAttribute = "start_date"
) {
  // 입력 검증
  if (!data || !Array.isArray(data)) {
    console.error("data는 배열이어야 합니다.");
    return [];
  }
  if (!startDate || !endDate) {
    console.error("startDate와 endDate는 반드시 존재해야 합니다.");
    return [];
  }

  // startDate와 endDate를 Date 객체로 변환
  const start = new Date(startDate);
  const end = new Date(endDate);

  // 유효한 Date 객체인지 확인
  if (isNaN(start) || isNaN(end)) {
    console.error("startDate와 endDate는 유효한 날짜 형식이어야 합니다.");
    return [];
  }

  // 필터링된 데이터
  const filteredData = data.filter((r) => {
    // r[dateAttribute]가 존재하는지와 형식이 올바른지 확인
    if (!r[dateAttribute]) {
      console.warn("데이터 항목에 start_date가 없습니다:", r);
      return false;
    }

    // r[dateAttribute]를 Date 객체로 변환
    const itemDate = new Date(r[dateAttribute]);

    // 유효한 Date 객체인지 확인
    if (isNaN(itemDate)) {
      console.warn(
        "데이터의 start_date가 유효한 날짜 형식이 아닙니다:",
        r[dateAttribute]
      );
      return false;
    }

    // 날짜 비교: 이상 (>=) 및 이하 (<=) 조건으로 변경
    return itemDate >= start && itemDate <= end;
  });

  // 필터링된 데이터를 start_date 기준으로 오름차순 정렬
  filteredData.sort((a, b) => {
    const dateA = new Date(a[dateAttribute]);
    const dateB = new Date(b[dateAttribute]);
    return dateA - dateB;
  });

  return filteredData;
}

/* 사용 예제
const data = [
  { start_date: "2023-07-01" },
  { start_date: "2023-07-15" },
  { start_date: "2023-08-01" },
  { start_date: "invalid-date" },
  { start_date: null },
];

const startDate = "2023-07-01";
const endDate = "2023-07-31";

const filtered = filterDataByDateRange(data, startDate, endDate);
console.log(filtered); // [{ start_date: "2023-07-01" }, { start_date: "2023-07-15" }]
*/

// studentArray는 생기부에 organization_path를 갖고 있는 학생이 여러 학년도에 걸친 경우다.
// periodData는 schoolYearPeriodData, termPeriodData 둘 다 된다.
export function getAppliedPeriods2(studentArray, periodData) {
  const appliedPeriods = studentArray.reduce((acc, array) => {
    const paths = array.organization_path.split("/");

    // 우선 applied_hierarchy_one을 기준으로 매칭되는 모든 항목 찾기
    const matchedByHierarchy = periodData.filter(
      (period) => period.applied_hierarchy_one?.toString() === paths[2]
    );

    // 추가하려는 항목 중 이미 추가된 항목이 있는지 확인하여 중복 제거
    matchedByHierarchy.forEach((period) => {
      if (!acc.some((p) => p.id === period.id)) {
        acc.push(period);
      }
    });

    // school_year_id로 매칭되는 모든 항목 찾기
    const matchedBySchoolYear = periodData.filter(
      (period) => period.school_year_id.toString() === paths[1]
    );

    // 중복 제거 후 추가
    matchedBySchoolYear.forEach((period) => {
      if (!acc.some((p) => p.id === period.id)) {
        acc.push(period);
      }
    });

    return acc;
  }, []); // 초기값을 빈 배열로 설정

  // start_date 기준으로 문자열 비교로 정렬
  return appliedPeriods.sort((a, b) =>
    a.start_date.localeCompare(b.start_date)
  );
}

// studentArray는 생기부에 organization_path를 갖고 있는 학생이 여러 학년도에 걸친 경우다.
// periodData는 schoolYearPeriodData, termPeriodData 둘 다 된다.
export function getAppliedPeriods(studentArray, periodData) {
  const appliedPeriods = studentArray.reduce((acc, array) => {
    const paths = array.organization_path.split("/");

    let matchedArray = [];
    // applied_hierarchy_one을 기준으로 매칭되는 모든 항목 찾기
    matchedArray = periodData.filter(
      (period) => period.applied_hierarchy_one?.toString() === paths[2]
    );

    if (matchedArray.length < 1) {
      // school_year_id로 매칭되는 모든 항목 찾기
      matchedArray = periodData.filter(
        (period) => period.school_year_id.toString() === paths[1]
      );
    }

    // 두 배열을 합쳐서 누적 배열에 추가
    acc.push(...matchedArray);

    return acc;
  }, []); // 초기값을 빈 배열로 설정

  // start_date 기준으로 문자열 비교로 정렬
  return appliedPeriods.sort((a, b) =>
    a.start_date.localeCompare(b.start_date)
  );
}
