import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ApprovalRequestsService from "../service/approvalRequests";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const approvalRequestsService = new ApprovalRequestsService(httpClient);

export default function useApprovalRequests(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 approvalRequests join해서 가져온다.
  const approvalRequestsQuery = useQuery(
    ["approvalRequests", conditions],
    () => approvalRequestsService.findAllApprovalRequests(conditions),
    // console.log("conditions", conditions),
    {
      enabled:
        !!conditions?.schoolid &&
        !!conditions?.username && //approval_lines에서 검사하는 username이다.
        !!conditions?.document_id &&
        !!conditions?.request_type &&
        !!conditions?.term_id,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("ApprovalRequests Query Data:", data);
      // },
    }
  );

  const getApprovalRequestsByUserId = useMutation((forWhere) =>
    approvalRequestsService.getApprovalRequestsByUserId(forWhere)
  );

  const createOrUpdateApprovalRequestsBulk = useMutation(
    (data) => approvalRequestsService.createOrUpdateApprovalRequestsBulk(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["approvalRequests", conditions]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  const deleteApprovalRequests = useMutation(
    (id) => approvalRequestsService.deleteApprovalRequests(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["approvalRequests"]);
      },
    }
  );

  return {
    approvalRequestsQuery,
    getApprovalRequestsByUserId,
    createOrUpdateApprovalRequestsBulk,
    deleteApprovalRequests,
  };
}
