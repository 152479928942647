import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDeveloperContext } from "../../../context/DeveloperContext";

export default function DevNavbar() {
  const { developer, login, logout } = useDeveloperContext();

  const [adminid, setAdminid] = useState("");
  const [password, setPassword] = useState("");

  const developerFuncMenu = {
    categoryname: "개발자기능",
    link: "none",
    subMenus: [
      {
        categoryname: "데이터이관",
        link: "/developer/data-migration",
      },
    ],
  };

  const schoolManagementMenu = {
    categoryname: "학교관리",
    link: "none",
    subMenus: [
      {
        categoryname: "학교등록",
        link: "/developer/devschools",
      },
      {
        categoryname: "학교세부정보",
        link: "none",
      },
    ],
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (developer) {
      logout();
    } else {
      login(adminid, password);
    }
    console.log("developer", developer);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "adminid") {
      setAdminid(value);
      return;
    } else if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <header className="d-flex justify-content-between border-bottom border-2 p-2">
      <Link
        to="/developer"
        className="text-center align-self-center text-brand x-underline"
      >
        <h1>EduSeed</h1>
      </Link>
      <div className="d-flex align-items-center">
        {developer && <DeveloperMenuDropdown menuList={schoolManagementMenu} />}
      </div>

      <div className="d-flex align-items-center">
        {developer && <DeveloperMenuDropdown menuList={developerFuncMenu} />}
      </div>
      <form className="d-flex align-items-center" onSubmit={handleSubmit}>
        {!developer && (
          <>
            <input
              type="text"
              className="form-control m-2"
              placeholder="아이디"
              name="adminid"
              required
              onChange={handleChange}
            />
            <input
              type="password"
              className="form-control m-2"
              placeholder="비밀번호"
              name="password"
              required
              onChange={handleChange}
            />
            <button className="btn btn-primary text-nowrap m-2" type="submit">
              로그인
            </button>
          </>
        )}

        {developer && (
          <button className="btn btn-primary text-nowrap m-2" type="submit">
            로그아웃
          </button>
        )}
      </form>
    </header>
  );
}

function DeveloperMenuDropdown({ menuList }) {
  return (
    <div className="dropdown align-middle">
      <button
        className="btn btn-sm dropdown-toggle"
        type="button"
        component={Link}
        to={menuList.link}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {menuList.categoryname}
      </button>
      <ul className="dropdown-menu">
        {menuList.subMenus.map((value) => {
          if (value.link !== "none") {
            return (
              <li key={value.link}>
                <Link
                  className="dropdown-item"
                  to={value?.link ? value?.link : ""}
                >
                  {value.categoryname}
                </Link>
              </li>
            );
          } else {
            return (
              <li key={value.link}>
                <Link className="dropdown-item">{value.categoryname}</Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
