import React from "react";

export default function ChangeMonth({ preNextMonth }) {
  const today = new Date();
  const dateStr = `${today.getFullYear()}.${String(
    today.getMonth() + 1
  ).padStart(2, "0")}.${String(today.getDate()).padStart(2, "0")}`;

  return (
    <div className="row">
      <div className="col-6 text-center d-flex align-items-center justify-content-center">
        오늘날짜: {dateStr}
      </div>
      <div className="col-3 text-end">
        <button
          className="btn btn-outline-dark"
          onClick={() => preNextMonth("pre")}
        >
          지난 달
        </button>
      </div>
      <div className="col-3 text-start">
        <button
          className="btn btn-outline-dark"
          onClick={() => preNextMonth("next")}
        >
          다음 달
        </button>
      </div>
    </div>
  );
}
