import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import GroupsService from "../service/groups.js";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const groupsService = new GroupsService(httpClient);

export default function useGroups(conditions) {
  //location.path로 학교 아이디, role은 파람으로 모든 정보 가져오기
  const queryClient = useQueryClient();
  // console.log("conditions", conditions);
  //users db에서 groups join해서 가져온다.

  const groupsQuery = useQuery(
    ["groups", conditions],
    () => groupsService.findAllGroups(conditions),
    // console.log("conditions", conditions),
    {
      enabled: !!conditions?.schoolid && !!conditions?.group_type,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("Groups Query Data:", data);
      // },
    }
  );

  const createGroups = useMutation((data) => groupsService.createGroups(data), {
    onSuccess: () => queryClient.invalidateQueries(["groups"]),
    onError: (error) => {
      console.error("Error creatingGroups:", error);
    },
  });

  const updateGroups = useMutation((data) => groupsService.updateGroups(data), {
    onSuccess: () => queryClient.invalidateQueries(["groups"]),
    onError: (error) => {
      console.error("Error updatingGroups:", error);
    },
  });

  const deleteGroups = useMutation((id) => groupsService.deleteGroups(id), {
    onSuccess: () => {
      // Refetch the query first
      queryClient.invalidateQueries(["groups"]);
    },
  });

  return {
    groupsQuery,
    createGroups,
    updateGroups,
    deleteGroups,
  };
}
