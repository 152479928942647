import React from "react";

export default function LoadingModal() {
  return (
    <div className="modalForSpinner">
      <div className="spinnerForLoading">
        <button className="btn btn-primary" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            aria-hidden="true"
          ></span>
          <span role="status" className="ms-1">
            Loading...
          </span>
        </button>
      </div>
    </div>
  );
}
