import React, { useState } from "react";
import { uniqueOptionsKeys, uniqueValues } from "../../../util/dealingArrObj";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import { useQueryClient } from "@tanstack/react-query";

/* 필요한 기능 정리
기존 데이터베이스에서 재적 세팅 정보를 불러옴
힉년도 선택의 옵션으로 넣어둠
 - 선택하면 해당 학년도의 정보로 필터되어서 아래 나옴
이전 설정에도 옵셥 들어감
 - 선택하면 해당 학년도의 정보로 아래가 바뀜. 
 새학년도 생성을 누르면 맨 왼쪽 학년도 선택에 생성된 학년도로 바뀜 / 내용이 비어 있으면 안됨
 이전 설정 불러오기 오른쪽에 저장을 누르면 현재 아래와 나온대로 전체 저장됨.
학년도 선택에서 삭제 누르면, 해당 학년도 정보 전체 삭제(그래서 아래 데이터 모두 삭제해야 지울 수 있도록 함_알람 경고도 뜨도록)
수정버튼 누르면 오른쪽 새학년도 생성이 이름바뀌고, 수정 후 저장할 수 있도록 함.
*/

export default function CreateNewYear({
  settingData,
  handleSchoolYearChange,
  setIsUpLoading,
  schoolId,
  createOrganizationUnit,
  schoolData,
  schoolYear,
  updateOrganizationUnit,
  deleteOrganizationUnit,
  setSchoolYear,
  setHideIcons,
  filteredSetting,
}) {
  const [isDeletingYear, setIsDeletingYear] = useState(false);
  const [isModifingYear, setIsModifingYear] = useState(false);
  const [isSavingYear, setIsSavingYear] = useState(false);
  const [isSavingPreYear, setIsSavingPreYear] = useState(false);
  const [newSchoolYear, setNewSchoolYear] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [notPossibleMsg, setNotPossibleMsg] = useState(false);
  const [schoolYearSave, setSchoolYearSave] = useState("");

  const { createPreUnits, createLevelName } = useOrganizationSetting();

  const queryClient = useQueryClient();

  const deleteSchoolYear = () => {
    const schoolYearId = settingData.filter(
      (r) =>
        r.school_year === schoolYear &&
        r.hierarchy_level === 0 &&
        r.parent_id === 0
    );

    // console.log("schoolYearId", schoolYearId);

    // Confirmation prompt
    if (window.confirm("정말 삭제하시겠습니까?")) {
      setIsUpLoading(true);
      const hasChild = settingData.some(
        (item) => item.parent_id === schoolYearId[0].id
      );

      if (hasChild) {
        alert("하위에 속한 그룹이 없어야 삭제할 수 있습니다.");
        setIsUpLoading(false);
      } else {
        deleteOrganizationUnit.mutate(schoolYearId[0].id, {
          onSuccess: (successMessage) => {
            queryClient.invalidateQueries(["settingConditions"]);
            queryClient.invalidateQueries(["settingLevelName"]);
            setIsDeletingYear(true);
            setTimeout(() => {
              setIsDeletingYear(false);
            }, 2000);
            setIsUpLoading(false);
          },
          onError: (error) => {
            setIsUpLoading(false);
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(error.message);
          },
          onSettled: () => {
            setIsUpLoading(false); // 로딩 상태를 mutation 완료 후 항상 변경
          },
        });
      }
    } else {
      // User clicked 'Cancel', do nothing
      console.log("삭제가 취소됨. 휴....");
    }
  };

  const modifySchoolYear = () => {
    //누르면 동작해야 하는 것
    //1. 수정 버튼 취소로 바뀜, 수정하고 저장을 누르세요로 바뀜, 생성이 저장으로 바뀜
    //2. 왼쪽 학년도 선택이 readonly로 바뀜 오른쪽 인풋에 수정할 수 있게 정보 넣기
    //3. 수정하면 newSchoolYear에 들어오기
    //4. 저장 누르면 같은 이름 있는지 확인해서 같은 이름 안된다고 하기
    //5. validate 통과하면 updateSchoolYearObj 만들어서 (new와의 차이점은 기존 학년도의 id가 있고, 변하지 않을 정보는 없다는 점)
    //6. 업데이트 실행하기

    if (!isModifingYear) {
      const schoolYearId = settingData.filter(
        (r) =>
          r.school_year === schoolYear &&
          r.hierarchy_level === 0 &&
          r.parent_id === 0
      );

      const hasChild = settingData.some(
        (item) => item.parent_id === schoolYearId[0].id
      );

      if (hasChild) {
        alert("하위에 속한 그룹이 없어야 수정할 수 있습니다.");
      } else {
        setIsModifingYear(true);
      }
    } else {
      setIsModifingYear(false);
    }
    // console.log(schoolData);
  };

  const saveSchoolYear = () => {
    setIsUpLoading(true);
    if (
      uniqueValues(settingData, "school_year").indexOf(
        newSchoolYear.school_year
      ) !== -1
    ) {
      alert("같은 이름의 학년도를 생성할 수는 없습니다.");
      setIsUpLoading(false);
      return;
    }

    setIsSavingYear(true);
    // console.log("isModifingYear", isModifingYear);
    if (isModifingYear) {
      const schoolYearId = settingData.filter(
        (r) =>
          r.school_year === schoolYear &&
          r.hierarchy_level === 0 &&
          r.parent_id === 0
      );

      // console.log("schoolYearId", schoolYearId);

      const updateSchoolYearObj = {
        id: schoolYearId[0].id,
        hierarchy_level: 0,
        schoolid: schoolId,
        school_year: newSchoolYear.school_year,
        level_name: "학교",
        group_name: schoolData.schoolname,
        parent_id: 0,
        order: 1,
        description: "",
      };

      // console.log("updateSchoolYearObj", updateSchoolYearObj);
      updateOrganizationUnit.mutate(updateSchoolYearObj, {
        onSuccess: () => {
          setIsUpLoading(false);
          setTimeout(() => {
            setIsSavingYear(false);
          }, 2000);
          setIsModifingYear(false);
        },
        onError: (error) => {
          setIsUpLoading(false);
          setIsSavingYear(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error
          );
          setIsModifingYear(false);
        },
      });
    } else {
      //가져올 정보: 새학년도 이름,
      const newSchoolYearObj = {
        schoolid: schoolId,
        school_year: newSchoolYear.school_year,
        hierarchy_level: 0,
        level_name: "학교",
        group_name: schoolData.schoolname,
        parent_id: 0,
        order: 1,
        description: "현 학교",
        organization_path: 0,
      };

      // console.log("newSchoolYearObj", newSchoolYearObj);
      createOrganizationUnit.mutate(newSchoolYearObj, {
        onSuccess: () => {
          setIsUpLoading(false);
          setTimeout(() => {
            setIsSavingYear(false);
          }, 2000);
        },
        onError: (error) => {
          setIsUpLoading(false);
          setIsSavingYear(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error
          );
        },
      });

      const newLevelArray = [
        {
          schoolid: schoolId,
          school_year: newSchoolYear.school_year,
          hierarchy_level: 1,
          level_name: "과정",
        },
        {
          schoolid: schoolId,
          school_year: newSchoolYear.school_year,
          hierarchy_level: 2,
          level_name: "학년",
        },
        {
          schoolid: schoolId,
          school_year: newSchoolYear.school_year,
          hierarchy_level: 3,
          level_name: "반",
        },
      ];

      createLevelName.mutate(newLevelArray, {
        onError: (error) => {
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "계층단계를 만드는 곳에서 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error
          );
        },
      });
    }
  };

  //이전 설정 불러오기 누르면 0단계 빼 놓고 밑에 것 다 불러오기
  //단! 0단계 밑에 뭔가가 있으면 못 불러옴
  //그리고 저장 누르면 0단계 밑에 모든 것이 선택된 학년도에 저장됨.
  // - school_year은 새로운 것으로 일괄 바꾸고, id는 새로 생성이고, 나머지는 다 똑같지만 parent_id가 힘들겠다.
  // 이전 parent_id를 가진 row와 school_year 정보만 다른 새로생성된 row를 찾아서 그 id를 가져와서 자신의 parent_id로 해야 할 듯 하다.
  const handlePreYearChange = (e) => {
    const { value } = e.target;
    setSelectedYear(value);
    // console.log("value", value);
    // console.log("schoolYear", schoolYear);
    setSchoolYearSave(schoolYear);

    const filterCheck = settingData.filter(
      (r) => r.hierarchy_level !== 0 && r.school_year === schoolYear
    );
    // console.log("filterCheck", filterCheck);

    if (filterCheck.length < 1) {
      setSchoolYear(value);
      setHideIcons(true);
    } else {
      setSelectedYear("");
      setNotPossibleMsg(true);
      setHideIcons(false);
      setTimeout(() => {
        setNotPossibleMsg(false);
      }, 3000);
    }
  };

  const saveFromPreYear = () => {
    setIsUpLoading(true);

    //선택된 학년도에 하위 그룹이 하나도 없을 때만 저장 가능해야 한다.(물론 handlePreYearChange에서 하지만 노파심에...)
    const filterCheck = settingData.filter(
      (r) => r.hierarchy_level !== 0 && r.school_year === schoolYearSave
    );

    //이전 설정 불러오기에 아무것도 없을 때 실행 안되도록
    //이전 설정 불러왔는데, 하위 그룹이 없으면 실행 안되도록...
    if (
      filterCheck.length < 1 &&
      selectedYear.length > 0 &&
      filteredSetting.length > 1
    ) {
      setIsSavingPreYear(true);

      let preInfo = filteredSetting.map((item) => {
        const { superkey, ...theRest } = item;
        return {
          ...theRest,
          school_year: schoolYearSave,
        };
      });

      // console.log("preInfo", preInfo);

      createPreUnits.mutate(preInfo, {
        onSuccess: () => {
          setIsUpLoading(false);
          setTimeout(() => {
            setIsSavingPreYear(false);
            setSchoolYear(schoolYearSave);
            setSelectedYear("");
            setHideIcons(false);
          }, 2000);
        },
        onError: (error) => {
          setIsUpLoading(false);
          setIsSavingPreYear(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error
          );
        },
      });
    } else {
      setIsUpLoading(false);
      setSelectedYear("");
      setNotPossibleMsg(true);
      setHideIcons(false);
      setTimeout(() => {
        setNotPossibleMsg(false);
      }, 3000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSchoolYear((newSchoolYear) => ({ ...newSchoolYear, [name]: value }));
  };

  return (
    <div className="row g-2 border-bottom">
      <div className="col-auto">
        <label htmlFor="school_year" className="form-label ms-2 fw-bold">
          학년도 선택
        </label>
        <select
          className="form-control m-2"
          name="school_year"
          onChange={(e) => {
            handleSchoolYearChange(e);
            setSelectedYear("");
          }}
          disabled={isModifingYear}
        >
          {settingData?.length > 0 &&
            uniqueOptionsKeys(settingData, "school_year", "id", "desc")}
        </select>
      </div>

      <div className="col-auto">
        <label
          htmlFor="deleteModify"
          className="form-label ms-2 fw-bold"
        ></label>
        <div>
          <button
            className="btn btn-danger text-nowrap m-2 mt-3"
            name="delete"
            onClick={deleteSchoolYear}
            disabled={isDeletingYear}
          >
            {isDeletingYear ? "삭제 중..." : "삭제"}
          </button>

          <button
            className="btn btn-info text-nowrap m-2 mt-3"
            name="modify"
            onClick={modifySchoolYear}
          >
            {isModifingYear ? "취소" : "수정"}
          </button>
        </div>
      </div>

      <div className="col-auto">
        <label htmlFor="saveSchoolYear" className="form-label ms-2 fw-bold">
          {isModifingYear ? "수정하고 저장을 누르세요." : "새학년도 생성"}
        </label>
        <input
          type="text"
          className="form-control m-2"
          name="school_year"
          placeholder={isModifingYear ? schoolYear : ""}
          onChange={handleChange}
        />
      </div>

      <div className="col-auto">
        <label
          htmlFor="saveSchoolYear"
          className="form-label ms-2 fw-bold"
        ></label>
        <div>
          <button
            className="btn btn-primary text-nowrap m-2 mt-3"
            name="saveSchoolYear"
            onClick={saveSchoolYear}
            disabled={isSavingYear}
          >
            {isModifingYear ? "저장" : isSavingYear ? "작업 중" : "생성"}
          </button>
        </div>
      </div>

      <div className="col-auto">
        <label htmlFor="preYearSetting" className="form-label ms-2 fw-bold">
          {notPossibleMsg ? "등록된 그룹이 없어야함" : "이전 설정 불러오기"}
        </label>
        <select
          className="form-control m-2 preYearSetting"
          name="school_year"
          value={selectedYear}
          onChange={handlePreYearChange}
        >
          <option value="">선택</option>
          {settingData?.length > 1 &&
            uniqueOptionsKeys(settingData, "school_year", "id", "desc")}
        </select>
      </div>

      <div className="col-auto">
        <label
          htmlFor="saveFromPreYear"
          className="form-label ms-2 fw-bold"
        ></label>
        <div>
          <button
            className="btn btn-primary text-nowrap m-2 mt-3"
            name="saveFromPreYear"
            onClick={saveFromPreYear}
            disabled={isSavingPreYear}
          >
            {isSavingPreYear ? "작업 중" : "저장"}
          </button>
        </div>
      </div>
    </div>
  );
}
