import React, { useEffect, useRef, useState } from "react";
import styles from "./CSS/InsertStudentsSidebar.module.css";
import { MdClose } from "react-icons/md";
import DropdownSchoolYear from "./Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "./Dropdowns/DropdownHierarchyOne";
import useOrganizationSetting from "../hooks/useOrganizationSetting";
import { useLocation } from "react-router-dom";
import DropdownHierarchyTwo from "./Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "./Dropdowns/DropdownHierarchyThree";
import useRegistrationInfo from "../hooks/useRegistrationInfo";
import { generateRegistrationRows } from "../util/dealingArrObj";

//자주 쓰게 될 것 같은... 재적을 기준으로 학생들을 넣기 위한 컴포넌트이다.
//handleAddClick을 통해서 row 정보가 나가면, 외부 컴포넌트의 정보와 결합해서 서버에 create 하면 된다.
export default function InsertStudentsSidebar({
  isOpen,
  toggleSidebar,
  handleAddClick,
  isAdded,
  isAddedAll,
  handleAddAllClick,
  schoolStageId = "",
  schoolStageOrganPath = "/",
}) {
  // 사이드바 DOM 노드를 참조하기 위한 ref 생성
  const sidebarRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      // 사이드바 외부 클릭을 감지
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // 사이드바가 열려있는 경우에만 닫기
        if (isOpen) {
          toggleSidebar();
        }
      }
    }
    // 클릭 이벤트 리스너 등록
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // 클린업 함수를 통해 이벤트 리스너 해제
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo(conditions);

  //필터를 위한 모음
  const [organSettingInfo, setOrganSettingInfo] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    selectedStudent: "",
    organPathForSort: "/",
  });

  //필터된 학생들 정보를 나오게 하려고
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setOrganSettingInfo((organSettingInfo) => ({
      ...organSettingInfo,
      hierarchyOneId: schoolStageId,
      hierarchyTwoId: "",
      hierarchyThreeId: "",
      organPathForSort: schoolStageOrganPath,
    }));
  }, [schoolStageId, schoolStageOrganPath]);

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    // let initialUsername = null;

    //초기에 schoolYear 정보가 아직 없을 때, 최신 순서로 schoolYear를 만들어 준다.
    //사실 완전 최신이 아닌 것 같다. 그냥 정렬한 거지. 나중에 누군가 school_year을 2025 이런 식으로 규칙적으로 안 적으면 순서 꼬임
    if (organSettingInfo.schoolYear === null) {
      if (registrationInfoData && settingData && settingData.length > 0) {
        const initialData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        )[0];

        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
        setOrganSettingInfo((organSettingInfo) => ({
          ...organSettingInfo,
          schoolYear: initialSchoolYear,
          schoolYearId: initialSchoolYearId,
        }));

        if (initialSchoolYear) {
          //rows 데이터처리
          const sortedRows = generateRegistrationRows(
            registrationInfoData,
            settingData,
            initialSchoolYear,
            organSettingInfo.organPathForSort
          );
          setRows(sortedRows);
        }
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("settingData", settingData);

        //사실 Mui dataGrid row용으로 만든 건데, 유용해서 계속 쓰자.
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          organSettingInfo.schoolYear,
          organSettingInfo.organPathForSort
        );
        setRows(sortedRows);
      }
    }

    //여기 dependency가 있어서 schoolYear와 organPathForSort 필터에 맞게 필터되어서 배포된다.
  }, [
    settingData,
    organSettingInfo.schoolYear,
    registrationInfoData,
    organSettingInfo.organPathForSort,
  ]);

  //아래 dropdown에 세트로 들어가야 할 애들이다.
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    const organPath = dataName + "/" + value;

    let lastIndex = organSettingInfo.organPathForSort.lastIndexOf("/");
    const previousPath = organSettingInfo.organPathForSort.substring(
      0,
      lastIndex
    );
    // 100%는 아니지만 적당히 작동하니까...
    // console.log("previousPath", previousPath);
    // console.log("organPath", organPath);

    if (!organPath.startsWith("nu")) {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        organPathForSort: organPath,
      }));
    } else {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        organPathForSort: previousPath,
      }));
    }

    if (name === "0") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "0",
      }));
    }

    if (name === "1") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
      }));
    }

    if (name === "2") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
      }));
    }

    if (name === "3") {
      setOrganSettingInfo((organSettingInfo) => ({
        ...organSettingInfo,
        hierarchyThreeId: value,
      }));
    }

    // if (name === "students") {
    //   setSelectedStudent(value);
    // }
  };

  return (
    <div
      className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}
      ref={sidebarRef}
    >
      <div className="text-end mt-3">
        <MdClose size={27} type="button" onClick={toggleSidebar} />
      </div>
      <div className="row p-2">
        <div className="col-6 pe-1 mb-2">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={organSettingInfo.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col-6 ps-1">
          <DropdownHierarchyOne
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={organSettingInfo.schoolYear}
            hierarchyOneId={organSettingInfo.hierarchyOneId}
          />
        </div>
        <div className="col-6 pe-1">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={organSettingInfo.schoolYear}
            hierarchyOneId={organSettingInfo.hierarchyOneId}
            hierarchyTwoId={organSettingInfo.hierarchyTwoId}
          />
        </div>
        <div className="col-6 ps-1">
          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={organSettingInfo.schoolYear}
            hierarchyTwoId={organSettingInfo.hierarchyTwoId}
            hierarchyThreeId={organSettingInfo.hierarchyThreeId}
          />
        </div>
      </div>
      <div className="row ms-2 me-2 mt-1">
        <table className="table table-bordered text-center">
          <thead>
            <tr className="table-secondary p-1" style={{ fontSize: "14px" }}>
              <th className="p-1 align-middle" style={{ width: "48px" }}>
                과정
              </th>
              <th className="p-1 align-middle" style={{ width: "48px" }}>
                학년
              </th>
              <th className="p-1 align-middle" style={{ width: "61px" }}>
                반
              </th>
              <th className="p-1 align-middle" style={{ width: "74px" }}>
                이름
              </th>
              <th className="p-1 align-middle">
                <button
                  className={`btn btn-sm ${
                    isAddedAll ? "btn-success" : "btn-primary"
                  } p-1 pt-0 pb-0 ms-1`}
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    if (
                      organSettingInfo?.hierarchyOneId?.length < 1 ||
                      organSettingInfo?.hierarchyTwoId?.length < 1 ||
                      organSettingInfo?.hierarchyOneId?.toString() === "0" ||
                      organSettingInfo?.hierarchyTwoId?.toString() === "0"
                    ) {
                      alert("최소 과정과 학년이 선택되어야 합니다.");
                    } else {
                      handleAddAllClick(rows);
                    }
                  }}
                  disabled={isAddedAll ? true : false}
                >
                  {isAddedAll ? (
                    <>
                      <span style={{ display: "block" }}>작업</span>
                      <span style={{ display: "block" }}>완료</span>
                    </>
                  ) : (
                    <>
                      <span style={{ display: "block" }}>모두</span>
                      <span style={{ display: "block" }}>배정</span>
                    </>
                  )}
                </button>
              </th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "13px" }} className="align-middle">
            {rows?.map((row) => (
              <tr key={row.id}>
                <td className="p-1">{row[1]}</td>
                <td className="p-1">{row[2]}</td>
                <td className="p-1">{row[3]}</td>
                <td className="p-1">{row.fullname}</td>
                <td className="p-1">
                  <button
                    className={`btn btn-sm ${
                      isAdded.includes(row.id)
                        ? "btn-success"
                        : "btn-outline-primary"
                    } p-1 pt-0 pb-0 ms-1`}
                    style={{ fontSize: "14px" }}
                    onClick={() => {
                      if (organSettingInfo?.hierarchyOneId?.length < 1) {
                        alert("과정이 선택되어야 합니다.");
                      } else {
                        handleAddClick(row);
                      }
                    }}
                    disabled={isAdded.includes(row.id) ? true : false}
                  >
                    {isAdded.includes(row.id) ? "완료" : "배정"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
