/**
 * 데이터를 계층적으로 정렬하고 재배열합니다.
 * @param {Array} data - 정렬할 데이터 배열
 * @returns {Array} 정렬된 데이터 배열
 */
// export function arrangeDataByHierarchy(data) {
//   data.sort((a, b) => {
//     if (a.hierarchy_level !== b.hierarchy_level) {
//       return a.hierarchy_level - b.hierarchy_level;
//     } else if (a.order !== b.order) {
//       return b.order - a.order;
//     } else {
//       return a.school_year - b.school_year;
//     }
//   });

//   let result = [];
//   let map = data.reduce((acc, cur) => {
//     acc[cur.id] = cur;
//     return acc;
//   }, {});

//   for (let item of data) {
//     if (item.parent_id === 0) {
//       result.push(item);
//     } else {
//       let parent = map[item.parent_id];
//       let idx = result.indexOf(parent);
//       if (idx !== -1 && idx + 1 < result.length) {
//         result.splice(idx + 1, 0, item);
//       } else {
//         result.push(item); // 부모를 찾지 못한 경우 끝에 추가
//       }
//     }
//   }

//   return result;
// }

export function arrangeDataByHierarchy(data) {
  // 1. 전체 데이터를 hierarchy_level과 order에 따라 오름차순 정렬
  data.sort(
    (a, b) => a.hierarchy_level - b.hierarchy_level || a.order - b.order
  );

  let result = [];
  let map = {};

  // 2. map을 사용하여 각 항목의 ID와 해당 항목을 연결
  data.forEach((item) => (map[item.id] = { ...item, children: [] }));

  // 3. 데이터를 계층적으로 구성
  data.forEach((item) => {
    if (item.parent_id === 0) {
      result.push(map[item.id]);
    } else if (map[item.parent_id]) {
      map[item.parent_id].children.push(map[item.id]);
    }
  });

  // 4. 계층적 구조를 평평하게 만들어 결과 리스트에 순서대로 넣기
  function flattenItems(items, depth = 0) {
    let flatList = [];
    items.forEach((item) => {
      item.depth = depth; // 계층 깊이 추가
      flatList.push(item);
      if (item.children && item.children.length > 0) {
        flatList = flatList.concat(flattenItems(item.children, depth + 1));
      }
    });
    return flatList;
  }

  return flattenItems(result);
}

/* 실사용법 - useQuery 훅에서 이렇게 사용
  const evalItemsQuery = useQuery(
    ["evalItems"],
    () => evalItemsService.findAllEvalItems(conditions),
    {
      // enabled: !conditions?.type,
      staleTime: 1000 * 60 * 10, //10분,
      // onSuccess: (data) => {
      //   console.log("evalItems Query Data:", data);
      // },
    }
  );

  const sortedData = React.useMemo(() => {
    if (evalItemsQuery.data && evalItemsQuery.data.length > 0) {
      return arrangeDataByHierarchy([...evalItemsQuery.data]);
    } else {
      return [];
    }
  }, [evalItemsQuery.data]);

    const sortedData = React.useMemo(() => {
    if (evalItemsQuery.data && evalItemsQuery.data.length > 0) {
      return arrangeDataByHierarchy([...evalItemsQuery.data]);
    } else {
      return [];
    }
  }, [evalItemsQuery.data]);

  return {
    evalItemsQuery: { ...evalItemsQuery, data: sortedData },
    createEvalItems,
    updateEvalItems,
    deleteEvalItems,
  };

*/

/**
 * 학생들의 organization_path를 기반으로 설정 데이터를 필터링합니다.
 * organization_path가 '0'인 항목은 필터링하지 않고 포함시킵니다.
 * @param {Array} registrationInfos - 학생들의 정보가 담긴 배열
 * @param {Array} settings - 설정 데이터가 담긴 배열
 * @returns {Array} 필터링된 설정 데이터 배열
 */
export function filterSettingsByStudentsPath(registrationInfos, settings) {
  // 결과를 저장할 배열 초기화
  const filteredSettings = [];

  // 각 학생 정보에 대해 반복
  registrationInfos.forEach((registrationInfo) => {
    // 학생의 organization_path 추출
    const studentPath = registrationInfo.organization_path;

    // 설정 데이터에서 organization_path + '/' + parent_id 값으로 시작하는 항목을 필터링
    const studentSettings = settings.filter((setting) => {
      const fullPath = `${setting.organization_path}/${setting.id}`;
      return (
        setting.organization_path === "0" || studentPath.startsWith(fullPath)
      );
    });

    // 필터링된 설정 데이터를 결과 배열에 추가
    filteredSettings.push(...studentSettings);
  });

  // 중복 제거
  const uniqueFilteredSettings = filteredSettings.filter(
    (setting, index, self) =>
      index === self.findIndex((t) => t.id === setting.id)
  );

  // 필터링된 설정 데이터 배열 반환
  return uniqueFilteredSettings;
}

/* 예시 데이터
const registrationInfoData = [
  { id: 1, schoolid: 'sampleschool', school_year: '2024', username: 'sample.kim@sampleschool', organization_path: '/1/2/16/17', studentno: 1, status: '재학중' },
  { id: 2, schoolid: 'sampleschool', school_year: '2024', username: 'sample.lee@sampleschool', organization_path: '/1/2/5', studentno: 2, status: '재학중' }
  // 추가 학생 데이터...
];

const setting = [
  { id: 1, schoolid: 'sampleschool', school_year: '2024', hierarchy_level: 0, level_name: '학교', group_name: '샘플학교', parent_id: 0, order: 1, hr_teacher_username: null, description: '현 학교', organization_path: '0' },
  { id: 2, schoolid: 'sampleschool', school_year: '2024', hierarchy_level: 1, level_name: '과정', group_name: '초등', parent_id: 1, order: 1, hr_teacher_username: null, description: '', organization_path: '/1' },
  { id: 3, schoolid: 'sampleschool', school_year: '2024', hierarchy_level: 1, level_name: '과정', group_name: '중등', parent_id: 1, order: 2, hr_teacher_username: null, description: '', organization_path: '/1' },
  { id: 5, schoolid: 'sampleschool', school_year: '2024', hierarchy_level: 2, level_name: '학년', group_name: '2', parent_id: 2, order: 2, hr_teacher_username: null, description: '', organization_path: '/1/2' },
  { id: 17, schoolid: 'sampleschool', school_year: '2024', hierarchy_level: 3, level_name: '반', group_name: '은비', parent_id: 16, order: 1, hr_teacher_username: '[{"tname": "최선생", "username": "gong@sampleschool"}]', description: '', organization_path: '/1/2/16' }
  // 추가 데이터...
];

*/
