import React, { useState } from "react";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";

function AddRegistration({
  conditions,
  setIsUpLoading,
  addRegi,
  setAddRegi,
  schoolYear,
  userData,
  allSortedRows,
}) {
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [genderBirth, setGenderBirth] = useState([]);

  const { createRegistrationInfo } = useRegistrationInfo(conditions);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!addRegi.organization_path || addRegi.organization_path.length === 0) {
      alert("소속될 조직이 선택되어야 합니다.");
      return;
    }

    if (!addRegi.school_year || addRegi.school_year.length === 0) {
      setAddRegi((addRegi) => ({ ...addRegi, school_year: schoolYear }));
      return;
    }

    const checkIfExistThisYear = allSortedRows.filter((r) => {
      return r.username === addRegi.username && r.school_year === schoolYear;
    });
    // console.log("allSortedRows", allSortedRows);

    // console.log("checkIfExistThisYear", checkIfExistThisYear);
    if (checkIfExistThisYear.length > 0) {
      alert("같은 학년도에 같은 학생이 들어갈 수 없습니다.");
      return;
    }

    // console.log("addRegi", addRegi);

    createRegistrationInfo.mutate(addRegi, {
      onSuccess: () => {
        setIsUpLoading(false);
        setIsSaving(true);
        setTimeout(() => {
          setIsSaving(false);
        }, 2000);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error
        );
      },
    });
  };

  const handleFullNameChange = (e) => {
    const { name, value } = e.target;
    const filtered = userData.filter((user) => {
      return (
        user.fullname.toLowerCase() === value.toLowerCase() &&
        user.role === name &&
        user.status === 1
      );
    });
    if (name === "student") {
      setFilteredStudents(filtered);
    }
  };

  //{id: 1, fullname: "kim", hr_teacher_username: [{username: "hi@eduseed", tname:"이지아"},
  // {username: "hello@miraldure", tname: "유재석"}] }
  const handleInfoChange = (e) => {
    const { name, value } = e.target;

    setAddRegi((addRegi) => ({ ...addRegi, [name]: value }));
    // console.log("addRegi", addRegi);
    // console.log("filteredStudents", filteredStudents);
    // console.log("value", value);

    if (name === "username" && value !== null) {
      const thisUser = filteredStudents.find((r) => r.username === value);

      // const thisUser = filteredStudents.filter((r) => {
      //   console.log("r", r);
      //   return r.username === value;
      // });
      if (thisUser) {
        setGenderBirth([
          thisUser.gender?.length > 0 ? thisUser.gender : "",
          thisUser.birthday?.length > 0 ? thisUser.birthday : "",
        ]);
      } else {
        setGenderBirth(["", ""]);
      }
    }
  };

  // const handleClick = (hr_teacher) => {
  //   setAddRegi((addRegi) => ({
  //     ...addRegi,
  //     hr_teacher_username: addRegi.hr_teacher_username.filter(
  //       (user) => user.username !== hr_teacher
  //     ),
  //   }));
  //   // console.log("addRegi", addRegi);
  // };

  return (
    <form className="g-2" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-5 col-sm-3 col-md-2">
          <label htmlFor="fullname" className="form-label ms-2 fw-bold">
            학생이름<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control m-2"
            name="student"
            required
            onChange={handleFullNameChange}
          />
        </div>

        <div className="col-6 col-sm-4 col-md-3">
          <label htmlFor="username" className="form-label ms-2 fw-bold">
            사용자아이디<span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control m-2"
            name="username"
            required
            onChange={handleInfoChange}
          >
            <option value="">선택</option>
            {filteredStudents.map((user) => (
              <option key={user.id} value={user.username}>
                {user.username}
              </option>
            ))}
          </select>
        </div>

        <div className="col-3 col-sm-2 col-md-1 text-center">
          <label htmlFor="gender" className="form-label ms-2 fw-bold">
            성별
          </label>
          <div className="m-1 mt-1 p-1">
            <span name="gender">{genderBirth && genderBirth[0]}</span>
          </div>
        </div>

        <div className="col-4 col-sm-3 col-md-2 text-center">
          <label htmlFor="birthday" className="form-label ms-2 fw-bold">
            생년월일
          </label>
          <div className="m-1 mt-1 p-1">
            <span name="birthday">{genderBirth && genderBirth[1]}</span>
          </div>
        </div>

        <div className="col-3 col-sm-3 col-md-2">
          <label htmlFor="studentno" className="form-label ms-2 fw-bold">
            번호
          </label>
          <input
            type="number"
            className="form-control m-2"
            name="studentno"
            onChange={handleInfoChange}
          />
        </div>

        {/* <div className="col-6 col-sm-4 col-md-2">
          <label htmlFor="fullname" className="form-label ms-2 fw-bold">
            담임이름<span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            className="form-control m-2"
            name="teacher"
            required
            onChange={handleFullNameChange}
          />
        </div>

        <div className="col-6 col-sm-5 col-md-3">
          <label
            htmlFor="hr_teacher_username"
            className="form-label ms-2 fw-bold"
          >
            교사아이디<span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control m-2"
            name="hr_teacher_username"
            onChange={handleInfoChange}
          >
            <option value="">선택</option>
            {filteredTeachers.map((user) => (
              <option key={user.id} value={user.fullname}>
                {user.username}
              </option>
            ))}
          </select>
        </div>

        <div className="col-8 col-sm-6 col-md-3">
          <label htmlFor="username" className="form-label ms-2 fw-bold">
            배정담임<span style={{ color: "red" }}>*</span>
          </label>
          <div className="border align-middle text-nowrap m-1 mt-2 p-1">
            {addRegi?.hr_teacher_username?.map((user, index) => (
              <span key={index}>
                {user.tname}
                <MdOutlineCancel
                  role="button"
                  onClick={() => {
                    handleClick(user.username);
                  }}
                />
              </span>
            ))}
          </div>
        </div> */}

        <div className="col-1 col-sm-2 col-md-1">
          <label htmlFor="saveBtn" className="form-label ms-2 fw-bold"></label>
          <div>
            <button className="btn btn-primary text-nowrap mt-3">
              {isSaving ? "저장완료" : "저장"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddRegistration;
