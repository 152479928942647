// LogsModal.js
import React, { useEffect, useState } from "react";
import styles from "./CSS/LogsModal.module.css";

const LogsModal = ({ logs, setLogs }) => {
  const [logsModalState, setLogsModalState] = useState(false);

  // logs가 변경될 때 모달을 자동으로 열기
  useEffect(() => {
    if (logs && logs.length > 0) {
      setLogsModalState(true);
    }
  }, [logs]);

  // 모달 닫기 핸들러
  const handleClose = () => {
    setLogsModalState(false);
    setLogs([]);
  };

  if (!logsModalState) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h3>로그 메시지</h3>
          <button onClick={handleClose} className={styles.closeButton}>
            Close
          </button>
        </div>
        <div className={styles.modalContent}>
          {logs && logs.length > 0 ? (
            <ul className={styles.logsList}>
              {logs.map((log, index) => (
                <li key={index} className={styles.logItem}>
                  {log}
                </li>
              ))}
            </ul>
          ) : (
            <p>로그가 없습니다.</p>
          )}
        </div>
        <div className={styles.modalHeader}>
          <h3>완료</h3>
          <button onClick={handleClose} className={styles.closeButton}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogsModal;
