export default class RegistrationInfoService {
  constructor(http) {
    this.http = http;
  }

  async findAllRegistrationInfoByConditions(conditions = {}) {
    // conditions 객체를 쿼리 문자열로 변환
    // console.log("regi conditions", conditions);
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    // console.log("queryParams", queryParams);
    return this.http.fetch(
      `/api/registrationinfo/readalljoinuserbyconditions?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async getRegistrationInfoByUsername(conditions) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(
      `/api/registrationinfo/readbyusername?${queryParams}`,
      {
        method: "GET",
      }
    );
  }

  async createRegistration(regiInfo) {
    return this.http.fetch(`/api/registrationinfo/create`, {
      method: "POST",
      body: JSON.stringify(regiInfo),
    });
  }

  async updateRegistration(regiInfo) {
    return this.http.fetch(`/api/registrationinfo/update`, {
      method: "PUT",
      body: JSON.stringify(regiInfo),
    });
  }

  async deleteRegistration(id) {
    return this.http.fetch(`/api/registrationinfo/delete`, {
      method: "DELETE",

      body: JSON.stringify({ id }),
    });
  }
}
