export default class ComponentsToFormsService {
  constructor(http) {
    this.http = http;
  }

  async findAllComponentsToForms(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/componentstoforms/readall?${queryParams}`, {
      method: "GET",
    });
  }

  async createOrUpdateComponentsToForms(data) {
    return this.http.fetch(`/api/componentstoforms/createorupdate`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  async createOrUpdateComponentsToFormsBulk(dataArray) {
    return this.http.fetch(`/api/componentstoforms/createorupdatebulk`, {
      method: "POST",
      body: JSON.stringify(dataArray),
    });
  }

  async deleteComponentsToForms(data) {
    return this.http.fetch(`/api/componentstoforms/delete`, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  async deleteVariousStudentRecordOne(id) {
    return this.http.fetch(`/api/componentstoforms/deleteone`, {
      method: "DELETE",
      body: JSON.stringify({ id }),
    });
  }
}
