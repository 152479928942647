import axios from "axios";
import axiosRetry from "axios-retry";

const defaultRetryConfig = {
  retries: 5,
  initialDelayMs: 100,
};

export default class HttpClient {
  constructor(baseURL, getCsrfToken, config = defaultRetryConfig) {
    this.getCsrfToken = getCsrfToken;
    this.client = axios.create({
      baseURL: baseURL,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });
    axiosRetry(this.client, {
      retries: config.retries,
      retryDelay: (retry) => {
        const delay = Math.pow(2, retry) * config.initialDelayMs;
        const jitter = delay * 0.1 * Math.random();
        return delay + jitter;
      },
      retryCondition: (err) =>
        axiosRetry.isNetworkOrIdempotentRequestError(err) ||
        err.response.status === 429,
    });
  }

  async fetch(url, options) {
    const { body, method, headers } = options;
    const req = {
      url,
      method,
      headers: {
        ...headers,
        "eduseed-csrf-token": this.getCsrfToken(),
      },
      data: body,
    };

    try {
      const res = await this.client(req);
      return res.data;
    } catch (err) {
      console.log("err.response", err);

      if (err.response) {
        const status = err.response.status;
        const data = err.response.data;
        const message =
          data && data.message
            ? data.message
            : `Something went wrong! ${JSON.stringify(err.message)}`;

        // 401 Unauthorized 처리
        if (status === 401) {
          console.log("Unauthorized access - handling 401 error");
          // 필요에 따라 전역 상태 관리 또는 이벤트 등을 통해 모달을 열도록 호출
          // 예시: 이벤트 트리거로 모달 열기
          window.dispatchEvent(new Event("unauthorized"));
        }

        throw new Error(message);
      }
      throw new Error("connection error");
    }
  }
}
