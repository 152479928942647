import { useMutation, useQuery } from "@tanstack/react-query";
import DeveloperFunctionService from "../../service/developer/developerFunction";
import HttpClient from "../../network/http";
import { fetchCsrfToken } from "../../context/DeveloperContext";
import { useLocation } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const developerFunctionService = new DeveloperFunctionService(httpClient);

export default function useDeveloperFunction() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const developerFunctionPath = paths[2];

  //전부 불러오기
  const dataMigrationQuery = useQuery(
    ["dataMigration"],
    () => developerFunctionService.readalldataMigration(),
    {
      enabled: developerFunctionPath === "data-migration",
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("dataMigration:", data);
      // },
    }
  );

  const dataMigrate = useMutation(
    ({ dataArray, address }) =>
      developerFunctionService.dataMigrate(dataArray, address),
    {
      onError: (error) => {
        console.error("Error migrating data:", error);
        alert("데이터 이관 중 에러 발생. 로그 확인 하세요.", error.logs);
      },
    }
  );

  return {
    dataMigrationQuery,
    dataMigrate,
  };
}
