import React, { useState } from "react";

export default function GenerateStuNoModal({
  setModalOpen,
  rows,
  setUserInfo,
}) {
  const [entranceDate, setEntranceDate] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [idAtSchool, setIdAtSchool] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "entranceDate" && value.length === 2) {
      setEntranceDate(value);
    }

    if (name === "birthYear" && value.length === 2) {
      setBirthYear(value);
    }

    setIdAtSchool("");
    // console.log("rows", rows);
  };

  const handleClick = () => {
    if ((entranceDate + birthYear).length === 4) {
      const filteredRows = rows
        .filter((r) => {
          return (
            r.idatschool && r.idatschool.startsWith(entranceDate + birthYear)
          );
        })
        .sort((a, b) => {
          if (a.idatschool < b.idatschool) {
            return -1;
          }
          if (a.idatschool > b.idatschool) {
            return 1;
          }
          return 0;
        });

      if (filteredRows.length > 0) {
        const lastOne = filteredRows.slice(-1)[0];
        const newIdAtSchool = Number(lastOne.idatschool) + 1;
        let toText = newIdAtSchool.toString();
        if (toText.length < 7) {
          toText = "0" + toText;
        }
        setIdAtSchool(toText);
      } else {
        const newId = entranceDate + birthYear + "001";
        setIdAtSchool(newId);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserInfo((prevState) => ({
      ...prevState,
      idatschool: idAtSchool,
    }));
    setModalOpen(false);
  };

  return (
    <>
      <div
        className="modal-container"
        // onClick={() => {
        //   setModalOpen(false);
        // }}
      >
        <div className="modalForUnit">
          <div className="modal-header">
            <h3>학번생성하기</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              <div className="row mb-3">
                <div className="col-2">
                  <label htmlFor="explanation" className="col-form-label">
                    학번 생성 원리
                  </label>
                </div>
                <div className="col-10">
                  <textarea
                    name="explanation"
                    rows={5}
                    className="form-control"
                    value="총 7자리로 입학년도 뒤2자리, 생년 뒤 2자리 + 생성순서 3자리입니다.
                    앞에 4자리 수를 갖고 있는 모든 학번을 찾아서 맨 마지막 학번의 뒷 세자리에서 더하기 1을 하는 방식으로 생성합니다."
                    readOnly
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-5 text-center">
                  <label htmlFor="entranceDate" className="col-form-label">
                    입학년도 뒤 2자리
                  </label>
                </div>
                <div className="col-4">
                  <input
                    required
                    type="number"
                    name="entranceDate"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-5 text-center">
                  <label htmlFor="birthYear" className="col-form-label">
                    생년도 뒤 2자리
                  </label>
                </div>
                <div className="col-4">
                  <input
                    required
                    type="number"
                    name="birthYear"
                    className="form-control"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-5 text-center">
                  <label htmlFor="fourDigits" className="col-form-label">
                    학번 앞 4자리
                  </label>
                </div>
                <div className="col-5">
                  <input
                    type="text"
                    name="fourDigits"
                    className="form-control"
                    value={entranceDate + birthYear}
                    readOnly
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-5 text-center">
                  <label
                    htmlFor="generatedIdatSchool"
                    className="col-form-label"
                  >
                    <button
                      className="btn btn-secondary btn-sm"
                      type="button"
                      onClick={handleClick}
                    >
                      학번생성 클릭
                    </button>
                  </label>
                </div>
                <div className="col-auto">
                  <input
                    required
                    type="text"
                    name="generatedIdatSchool"
                    className="form-control"
                    value={idAtSchool}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                취소
              </button>
              <button type="submit" className="btn btn-primary">
                입력하기
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
