import React from "react";
import DropdownSchoolYear from "../../../../unicomponents/Dropdowns/DropdownSchoolYear";
import DropdownHierarchyOne from "../../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownMonths from "../../../../unicomponents/Dropdowns/DropdownMonths";

export default function DropdownsForAttendanceStatistics({
  settingData,
  handleDropdownChange,
  organSettingInfo,
  selectedMonth,
  onChangeMonth,
}) {
  return (
    <>
      <DropdownSchoolYear
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYearId={organSettingInfo.schoolYearId}
        allYears={false}
      />
      <DropdownHierarchyOne
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYear={organSettingInfo.schoolYear}
        hierarchyOneId={organSettingInfo.hierarchyOneId}
      />
      <DropdownHierarchyTwo
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYear={organSettingInfo.schoolYear}
        hierarchyOneId={organSettingInfo.hierarchyOneId}
        hierarchyTwoId={organSettingInfo.hierarchyTwoId}
      />
      <DropdownHierarchyThree
        settingData={settingData}
        handleDropdownChange={handleDropdownChange}
        schoolYear={organSettingInfo.schoolYear}
        hierarchyTwoId={organSettingInfo.hierarchyTwoId}
        hierarchyThreeId={organSettingInfo.hierarchyThreeId}
      />
      <DropdownMonths
        selectedMonth={selectedMonth}
        onChangeMonth={onChangeMonth}
      />
    </>
  );
}
