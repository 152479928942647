import React, { useEffect, useState } from "react";
import LeftMenuSidebar from "../../../unicomponents/LeftMenuSidebar";
import { useLocation } from "react-router-dom";
import useGroups from "../../../hooks/useGroups";
import GroupsSetting from "./components/GroupsSetting";
import ApprovalSetting from "./components/ApprovalSetting";

export default function Groups() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];

  const [groupsQueryConditions, setGroupsQueryConditions] = useState({
    schoolid: schoolId,
    group_type: "teacher",
  });

  const [activeMenu, setActiveMenu] = useState({
    menu_name: "teacher",
    menu_id: "teacher",
  });

  const menuData = [
    { group_name: "교직원", group_type: "teacher" },
    { group_name: "결재그룹", group_type: "approval" },
  ];

  const {
    groupsQuery: { data: groupsData },
  } = useGroups(groupsQueryConditions);

  useEffect(() => {
    setGroupsQueryConditions((prev) => ({
      ...prev,
      group_type: activeMenu.menu_name,
    }));
  }, [activeMenu]);

  const handleQueryCondition = (item) => {
    if (item?.group_type !== "approval") {
      setGroupsQueryConditions((prev) => ({
        ...prev,
        group_type: item.group_type,
      }));
    }
  };

  function renderComponent() {
    const filteredGroupsData = groupsData
      .filter((item) => item.group_type === groupsQueryConditions.group_type)
      .sort((a, b) => {
        const orderA = parseInt(a.order, 10);
        const orderB = parseInt(b.order, 10);

        if (isNaN(orderA) && isNaN(orderB)) {
          return 0;
        } else if (isNaN(orderA)) {
          return 1;
        } else if (isNaN(orderB)) {
          return -1;
        } else {
          return orderA - orderB;
        }
      });

    switch (activeMenu.menu_name) {
      case "teacher":
        return (
          <>
            <GroupsSetting
              inputData={filteredGroupsData}
              groupsQueryConditions={groupsQueryConditions}
            />
          </>
        );
      case "approval":
        return <ApprovalSetting />;
      default:
        return (
          <GroupsSetting
            inputData={filteredGroupsData}
            groupsQueryConditions={groupsQueryConditions}
          />
        );
    }
  }

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <LeftMenuSidebar
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              menuData={menuData}
              extraFunction={handleQueryCondition}
              itemName="group_type"
              itemLabel="group_name"
            />
          </div>
          <div className="col-12 col-md-10">
            {groupsData && renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}
