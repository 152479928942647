import DevNavbar from "./components/DevNavbar";
import { Outlet } from "react-router-dom";
import {
  DeveloperContextProvider,
  fetchCsrfToken,
} from "../../context/DeveloperContext";
import DeveloperService from "../../service/developer";
import HttpClient from "../../network/http";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const developerService = new DeveloperService(httpClient);

function Developer() {
  return (
    <DeveloperContextProvider developerService={developerService}>
      <DevNavbar />
      <Outlet />
      {/* {developer ? (
        <Outlet />
      ) : (
        // <DevSchools developerService={developerService} />
        <div> "로그인 부터 하고 하세요. 이그!"</div>
      )} */}
    </DeveloperContextProvider>
  );
}

export default Developer;
