export default class DeveloperFunctionService {
  constructor(http) {
    this.http = http;
  }

  async readalldataMigration() {
    return this.http.fetch(`/api/datamigration/readalldatamigration`, {
      method: "GET",
    });
  }

  // 새로운 데이터 마이그레이션 메서드 추가
  async dataMigrate(dataArray, address) {
    return this.http.fetch(`/api/${address}`, {
      method: "POST",
      body: JSON.stringify(dataArray),
    });
  }
}
