export default class StudentInfoService {
  constructor(http) {
    this.http = http;
  }

  async findAllStudentInfoWithRegiInfoBySchoolid(studentInfo = {}) {
    const queryParams = Object.entries(studentInfo)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/studentinfo/readallWithUsers?${queryParams}`, {
      method: "GET",
    });
  }

  // async findStudentInfo(studentInfo) {
  //   console.log("service", studentInfo);
  //   return this.http.fetch(`/api/studentinfo/read-one-user`, {
  //     method: "GET",
  //     body: JSON.stringify(studentInfo),
  //   });
  // }

  async findStudentInfo(studentInfo) {
    const queryParams = Object.entries(studentInfo)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/studentinfo/read-one-user?${queryParams}`, {
      method: "GET",
    });
  }

  async updateStudentInfo(studentInfo) {
    return this.http.fetch(`/api/studentinfo/update`, {
      method: "PUT",
      body: JSON.stringify(studentInfo),
    });
  }

  async createStudentInfo(studentInfo) {
    return this.http.fetch(`/api/studentinfo/create`, {
      method: "POST",
      body: JSON.stringify(studentInfo),
    });
  }
}
