import React from "react";

export default function DropdownMonths({ selectedMonth, onChangeMonth }) {
  const months = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2];
  return (
    <>
      <select
        className="form-control"
        value={selectedMonth}
        name="month"
        onChange={onChangeMonth}
      >
        {months.map((month) => (
          <option key={month} value={month}>
            {month}월
          </option>
        ))}
      </select>
    </>
  );
}
