import React, { useEffect, useState } from "react";
import InsertStudentsSidebar from "../../../unicomponents/InsertStudentsSidebar";
import { useLocation } from "react-router-dom";
import useLessonStudents from "../../../hooks/useLessonStudentData";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import useLessons from "../../../hooks/useLessons";
import DropdownUniqueUsersInDisplayData from "../../../unicomponents/Dropdowns/DropdownUniqueUsersInDisplayData";
import DropdownTermDate from "../../../unicomponents/Dropdowns/DropdownTermDate";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import useOrganizationSetting from "../../../hooks/useOrganizationSetting";
import useTermPeriod from "../../../hooks/useTermPeriod";
import { setTermIdByToday } from "../../../util/dateRelated";
import { useAuthContext } from "../../../context/AuthContext";
import { useUIContext } from "../../../context/UIContext";
import DropdownUniqueItems from "../../../unicomponents/Dropdowns/DropdownUniqueItems";
import UniDivider from "../../../unicomponents/UniDivider";
import LessonStudentsTable from "./components/LessonStudentsTable";
import useUsers from "../../../hooks/useUsers";
import LessonStudentsItemsTable from "./components/LessonStudentsItemsTable";
import useLessonSetting from "../../../hooks/useLessonSetting";
import useSettingEvalItems from "../../../hooks/useSettingEvalItems";
import CustomTableSwitch from "./components/CustomTableSwitch";

/* 복잡하군... 스텝을 정리해서 하자. 
1. 데이터 불러온다.
  - 조직, 학기 데이터 먼저, 그리고 수업데이터(교사, 수업목록), 선택된 수업 바탕으로 학생-수업 배정 데이터
2. 필터한다. (교사와 수업은 있으면 필터하고, 없으면 전체 다 보여주는 컨셉으로)
3. 선택된 과목 보여준다.
4. 성적수정 개별로, 전체로 할 수 있다. / 삭제(배정에서 빼기) 할 수 있다.
5. 학생을 배정한다. (side) 
6. 배정된 학생에게 평가제의 맞춰서 성적입력하는 테이블이 나온다.

5번에 bulkcreate, 그리고 6번만 하면 됨.
*/

export default function DoLessons() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  const schoolId = paths[1];
  const conditions = {
    schoolid: schoolId,
    // lesson_id: 이거 나중에 추가해야 함
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    termPeriodQuery: { data: termPeriodData },
  } = useTermPeriod(conditions);

  const {
    lessonsQuery: { data: lessonsData },
    updateLessonTags,
  } = useLessons(conditions);

  const {
    lessonSettingQuery: { data: lessonSettingData },
  } = useLessonSetting(conditions);

  const {
    usersQuery: { data: userData },
  } = useUsers(conditions, { role: "teacher", status: 1 });

  const { getEvalItemsByLessonId } = useSettingEvalItems(conditions);

  const {
    getLessonStudentsByLessonId,
    createLessonStudents,
    createLessonStudentsBulk,
    updateLessonStudents,
    updateLessonStudentsBulk,
    deleteLessonStudents,
  } = useLessonStudents(conditions);

  const { user } = useAuthContext();

  const { handleToastCenterTop, setIsLoadingModal } = useUIContext();

  const [lessonInfo, setLessonInfo] = useState({
    id: "",
    evaluation_type: "",
    hierarchyOne: "",
    hierarchyTwo: "",
    hierarchyThree: "",
    school_year: "",
    schoolid: "",
    subject_name: "",
    teachers: [],
    termName: "",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAdded, setIsAdded] = useState([]);
  const [isAddedAll, setIsAddedAll] = useState(false);
  // organizationPath로 하는 것이 아니라... 모든 곳에서 단순하게 filters로 하는 것이 낫겠다.
  //필요한 필터는 여기서 통합해서 사용하자. 그리고 필터가 서로 다르게 적용되어야 할 때가 있다.
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    organPathForSort: "/",
    termId: "",
    selectedTeacherId: "",
    selectedLessonId: "",
  });

  const [termId, setTermId] = useState("");
  const [lessonList, setLessonList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [lessonStudentsData, setLessonStudentsData] = useState([]);
  const [lessonSetting, setLessonSetting] = useState([]);
  const [evalItemsData, setEvalItemsData] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    // let initialUsername = null;

    if (
      filters.schoolYear === null &&
      lessonsData &&
      settingData &&
      settingData.length > 0
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      const sortedLessons = lessonsData.filter(
        (r) => r.school_year_id === initialSchoolYearId
      );
      setLessonList(sortedLessons);

      // console.log("sortedLessons", sortedLessons);

      if (termPeriodData) {
        setTermIdByToday(termPeriodData, setTermId, initialSchoolYearId);
      }
    }
  }, [
    lessonsData,
    filters,
    filters.schoolYear,
    settingData,
    termId,
    termPeriodData,
  ]);

  useEffect(() => {
    if (lessonsData) {
      const sortedLessons = filterDataByOrganSettingDropdown(
        lessonsData,
        filters,
        termId,
        filters.selectedTeacherId
      );
      setLessonList(sortedLessons);
    }
  }, [lessonsData, filters, termId]);

  useEffect(() => {
    if (userData && user) {
      if (user.role === "teacher") {
        setFilters((filters) => ({ ...filters, selectedTeacherId: user.user }));
      }
    }
  }, [user, userData]);

  useEffect(() => {
    if (!filters.selectedLessonId) {
      setLessonInfo({});
      return;
    }
    if (lessonList && filters.selectedLessonId) {
      const selectedLessonInfo = lessonList.find(
        (lesson) => lesson.id.toString() === filters.selectedLessonId.toString()
      );
      setLessonInfo(selectedLessonInfo);
    }
  }, [filters.selectedLessonId, lessonList]);

  useEffect(() => {
    if (lessonInfo && lessonInfo.tags) {
      setSelectedOptions(lessonInfo.tags);
    }
  }, [lessonInfo]);

  useEffect(() => {
    if (lessonSettingData && termPeriodData) {
      let thisTermEndDate;

      if (termId) {
        // termId가 있을 경우, 해당 termId의 end_date를 찾습니다.
        const termData = termPeriodData.find((period) => period.id === termId);
        thisTermEndDate = termData ? new Date(termData.end_date) : undefined;
      } else {
        // termId가 null 혹은 undefined일 경우, termPeriodData 전체에서 가장 늦은 end_date를 찾습니다.
        thisTermEndDate = new Date(
          Math.max(
            ...termPeriodData.map((period) =>
              new Date(period.end_date).getTime()
            )
          )
        );
      }

      if (thisTermEndDate) {
        const filteredAndSortedLessonSetting = lessonSettingData
          .filter((r) => new Date(r.start_date) <= thisTermEndDate) // start_date가 thisTermEndDate 이전인 항목을 필터링
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)); // end_date 기준으로 내림차순 정렬

        // Use a reducer to keep only the first occurrence of each item_code
        const uniqueLessonSetting = filteredAndSortedLessonSetting.reduce(
          (acc, current) => {
            const x = acc.find((item) => item.item_code === current.item_code);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
        setLessonSetting(uniqueLessonSetting);
      }
    }
  }, [lessonSettingData, termId, termPeriodData]);

  function filterDataByOrganSettingDropdown(
    arrayDataToBeFiltered,
    organSettingObj,
    termId,
    selectedUser
  ) {
    /* 
필터해야 하는 것이
school_year_id = 이건 필수로 id(숫자)로 하지만.. 아래는 id이지만 문자이고, 0은 통합으로 쓰임 
hierarchy_one
hierarchy_two
hierarchy_three
term_id - 이건 아이디 숫자임, termDropdown의 id로 필터
-   const [termId, setTermId] = useState(""); 이거로 따로 있음
teachers: [{tname: "김샘플", username:"sample@sampleschool"}] 이런식으로 되어 있음. username으로 필터해야 함
 -   selectedUser, setSelectedUser = useState(""); 이거로 따로 있음
*/
    if (!arrayDataToBeFiltered) return [];

    const toBeRows = arrayDataToBeFiltered.filter((record) => {
      const matchesSchoolYearId = organSettingObj.schoolYearId
        ? String(record.school_year_id) === String(organSettingObj.schoolYearId)
        : true;
      const matchesHierarchyOne = organSettingObj.hierarchyOneId
        ? record.hierarchy_one === organSettingObj.hierarchyOneId
        : true;
      const matchesHierarchyTwo = organSettingObj.hierarchyTwoId
        ? record.hierarchy_two === organSettingObj.hierarchyTwoId
        : true;
      const matchesHierarchyThree = organSettingObj.hierarchyThreeId
        ? record.hierarchy_three === organSettingObj.hierarchyThreeId
        : true;
      const matchesTermId = termId
        ? String(record.term_id) === String(termId)
        : true;

      const matchesSelectedUser = selectedUser
        ? record.teachers.some((teacher) => teacher.username === selectedUser)
        : true;

      return (
        matchesSchoolYearId &&
        matchesHierarchyOne &&
        matchesHierarchyTwo &&
        matchesHierarchyThree &&
        matchesTermId &&
        matchesSelectedUser
      );
    });

    return toBeRows;
  }

  function queryLessonStudentsData(forWhere) {
    getLessonStudentsByLessonId.mutate(forWhere, {
      onSuccess: (data) => {
        if (settingData) {
          const settingMap = settingData.reduce((map, setting) => {
            map[setting.id] = setting.group_name;
            return map;
          }, {});

          const updatedData = data.map((studentData) => {
            const pathParts = studentData.organization_path.split("/");
            const stageId = pathParts[2]; // 과정
            const yearId = pathParts[3]; // 학년
            const classId = pathParts[4]; // 반

            const groupNameOne = settingMap[stageId];
            const groupNameTwo = settingMap[yearId];
            const groupNameThree = settingMap[classId];

            return {
              ...studentData,
              hierarchyOne: groupNameOne,
              hierarchyTwo: groupNameTwo,
              hierarchyThree: groupNameThree,
            };
          });
          // console.log("updatedData", updatedData);
          setLessonStudentsData(updatedData);
        }
      },
      onError: (error) => {
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  }

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, value);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
      }));

      setTermIdByToday(termPeriodData, setTermId, filters.schoolYearId, value);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
      }));

      setTermIdByToday(
        termPeriodData,
        setTermId,
        filters.schoolYearId,
        filters.hierarchyOneId,
        value
      );
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
      }));
    }

    if (name === "termDate") {
      setTermId(value);
    }

    if (name === "teachers") {
      setFilters((filters) => ({
        ...filters,
        selectedTeacherId: value,
      }));
    }

    if (name === "lessons") {
      setFilters((filters) => ({
        ...filters,
        selectedLessonId: value,
      }));

      if (value) {
        const forWhere = {
          schoolid: schoolId,
          lesson_id: value,
        };

        queryLessonStudentsData(forWhere);
        getEvalItemsByLessonId.mutate(forWhere, {
          onSuccess: (data) => {
            setEvalItemsData(data);
          },
          onError: (error) => {
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(
              "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                error.message
            );
          },
        });
      }
    }
  };

  const handleAddClick = (row) => {
    if (filters.selectedLessonId) {
      const filteredData = lessonStudentsData.filter(
        (r) =>
          r.school_year === filters.schoolYear && r.lesson_id === lessonInfo.id
      );
      if (filteredData.find((r) => r.username === row.username)) {
        alert("이미 배정된 학생입니다.");
        return;
      }

      // tags가 배열이 아니면 빈 배열로 설정
      const tags = Array.isArray(lessonInfo.tags) ? lessonInfo.tags : [];
      // tags 배열 내의 각 객체를 순회하며 값을 3으로 설정
      const generatedTags = tags.map((tagObject) => {
        // 객체 내의 모든 키에 대해 값을 3으로 설정
        const updatedTagObject = {};
        Object.keys(tagObject).forEach((key) => {
          updatedTagObject[key] = "3"; // 값으로 문자열 "3"을 설정, 숫자로 설정하려면 3으로 변경
        });
        return updatedTagObject;
      });

      //데이터베이스에 저장할 데이터 만들기
      const addDataSet = {
        schoolid: lessonInfo.schoolid,
        school_year: lessonInfo.school_year,
        school_year_id: lessonInfo.school_year_id,
        lesson_id: lessonInfo.id,
        username: row.username,
        tags: generatedTags,
      };

      const forWhere = {
        schoolid: lessonInfo.schoolid,
        lesson_id: lessonInfo.id,
      };

      setIsLoadingModal(true);
      createLessonStudents.mutate(addDataSet, {
        onSuccess: (successMessage) => {
          console.log(successMessage);
          setIsLoadingModal(false);
          setIsAdded((prev) => [...prev, row.id]);
          setTimeout(() => {
            setIsAdded([]);
          }, 1500);
          queryLessonStudentsData(forWhere);
        },
        onError: (error) => {
          setIsLoadingModal(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(
            "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
              error.message
          );
        },
      });
    } else {
      alert("수업을 먼저 선택해 주세요.");
    }
  };

  const handleAddAllClick = (rows) => {
    if (lessonInfo) {
      const bulkDataSets = [];
      const addedStudents = []; // 성공적으로 추가될 학생들의 이름을 저장할 배열
      const duplicateStudents = []; // 중복으로 추가되지 않은 학생들의 이름을 저장할 배열

      let message;

      rows.forEach((row) => {
        const filteredData = lessonStudentsData.filter(
          (r) => r.school_year === filters.schoolYear
        );

        const isDuplicate = filteredData.some(
          (r) => r.lesson_id === lessonInfo.id && r.username === row.username
        );

        if (isDuplicate) {
          duplicateStudents.push(row.fullname);
          return;
        }

        const generatedTags = lessonInfo.tags.map((tagObject) => {
          const updatedTagObject = {};
          Object.keys(tagObject).forEach((key) => {
            updatedTagObject[key] = "3";
          });
          return updatedTagObject;
        });

        const addDataSet = {
          schoolid: lessonInfo.schoolid,
          school_year: lessonInfo.school_year,
          school_year_id: lessonInfo.school_year_id,
          lesson_id: lessonInfo.id,
          username: row.username,
          tags: generatedTags,
        };

        addedStudents.push(row.fullname);
        bulkDataSets.push(addDataSet);
      });

      if (bulkDataSets.length > 0) {
        const forWhere = {
          schoolid: lessonInfo.schoolid,
          lesson_id: lessonInfo.id,
        };

        setIsLoadingModal(true);
        createLessonStudentsBulk.mutate(bulkDataSets, {
          onSuccess: (successMessage) => {
            console.log(successMessage);
            setIsLoadingModal(false);
            // 성공적으로 추가된 row.id들을 setIsAddedAll로 처리
            setIsAddedAll(true);
            setTimeout(() => {
              setIsAddedAll(false);
            }, 1500);
            queryLessonStudentsData(forWhere);

            message = `${
              addedStudents.length
            }명이 성공적으로 추가되었습니다. <br>- ${addedStudents.join(", ")}`;

            if (duplicateStudents.length > 0) {
              message = `${
                addedStudents.length
              }명이 성공적으로 추가되었습니다. <br>- ${addedStudents.join(
                ", "
              )} <br> 다음 ${
                duplicateStudents.length
              }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateStudents.join(
                ", "
              )}`;
            }
            handleToastCenterTop(message, 4000);
          },
          onError: (error) => {
            setIsLoadingModal(false);
            console.error(error);
            alert("에러 발생: " + error.message);
          },
        });
      } else {
        if (duplicateStudents.length > 0) {
          message = `다음 ${
            duplicateStudents.length
          }명은 중복으로 인해 추가되지 않았습니다. <br> - ${duplicateStudents.join(
            ", "
          )}`;
        }
        handleToastCenterTop(message, 4000);
      }
    } else {
      alert("활동을 먼저 선택해 주세요.");
    }
  };

  // "저장" 버튼 클릭 핸들러
  const saveChanges = (e) => {
    e.preventDefault();
    const dataSet = {
      id: e.target.name,
      tags: selectedOptions,
      schoolid: schoolId,
    };

    setIsLoadingModal(true);
    updateLessonTags.mutate(dataSet, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  // 선택 옵션 변경 핸들러
  const handleSelectChange = (tagName, newValue) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((option) => {
        // Object.keys(option)[0]은 현재 옵션 객체의 키를 가져옵니다.
        // 이 키가 tagName과 일치하면, 새 값을 가진 객체를 반환합니다.

        if (Object.keys(option)[0] === tagName) {
          return { [tagName]: newValue };
        }
        // 일치하지 않는 경우, 원래 객체를 그대로 반환합니다.
        return option;
      })
    );
  };

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <InsertStudentsSidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          handleAddClick={handleAddClick}
          isAdded={isAdded}
          isAddedAll={isAddedAll}
          handleAddAllClick={handleAddAllClick}
          schoolStageId={filters.hierarchyOneId}
          schoolStageOrganPath={filters.organPathForSort}
        />
        <div className="d-flex justify-content-end flex-wrap mb-3 mt-2">
          <div style={{ width: "95px" }} className="me-1">
            <DropdownSchoolYear
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYearId={filters.schoolYearId}
              allYears={false}
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownHierarchyOne
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={filters.schoolYear}
              hierarchyOneId={filters.hierarchyOneId}
              hierarchyOneTitle="통합"
              extraOption={true}
              extraOptionTitle="과정"
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownHierarchyTwo
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={filters.schoolYear}
              hierarchyOneId={filters.hierarchyOneId}
              hierarchyTwoId={filters.hierarchyTwoId}
              hierarchyTwoTitle="통합"
              extraOption={true}
              extraOptionTitle="학년"
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownHierarchyThree
              settingData={settingData}
              handleDropdownChange={handleDropdownChange}
              schoolYear={filters.schoolYear}
              hierarchyTwoId={filters.hierarchyTwoId}
              hierarchyThreeId={filters.hierarchyThreeId}
              hierarchyThreeTitle="통합"
              extraOption={true}
              extraOptionTitle="반"
            />
          </div>
          <div style={{ width: "95px" }} className="me-1">
            <DropdownTermDate
              termPeriodData={termPeriodData}
              handleDropdownChange={handleDropdownChange}
              termId={termId}
              schoolYearId={filters.schoolYearId}
              hierarchyOneId={filters.hierarchyOneId}
              hierarchyTwoId={filters.hierarchyTwoId}
            />
          </div>
          <div style={{ width: "95px" }}>
            <DropdownUniqueUsersInDisplayData
              displayData={userData}
              displayKey={"fullname"}
              valueKey={"username"}
              uniqueKey={"username"}
              sortKey={"fullname"}
              isDesc={false}
              handleDropdownChange={handleDropdownChange}
              selectedUser={filters.selectedTeacherId}
              selectName="teachers"
              dropdownTitle="교사선택"
            />
          </div>

          <div style={{ width: "95px" }}>
            <DropdownUniqueItems
              displayData={lessonList}
              displayKey={"subject_name"}
              valueKey={"id"}
              uniqueKey={"id"}
              sortKey={"subject_name"}
              isDesc={false}
              handleDropdownChange={handleDropdownChange}
              selectedItem={filters.selectedLessonId}
              selectName="lessons"
              dropdownTitle="수업선택"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <h3 className="fw-bold">
              학생배정 및 성적기록
              <HelpHyperLink link={"https://youtu.be/ONyCumtZEv0"} />
            </h3>
          </div>
          <div className="col-auto">
            <button
              className="btn btn-sm btn-secondary mt-2"
              onClick={toggleSidebar}
            >
              배정
            </button>
          </div>
        </div>

        <table className="table text-center align-middle">
          <tbody>
            <tr>
              <th style={{ width: "10%" }}>학기</th>
              <th style={{ width: "8%" }}>과정</th>
              <th style={{ width: "8%" }}>학년</th>
              <th style={{ width: "8%" }}>반</th>
              <th style={{ width: "25%" }}>과목명</th>
              <th style={{ width: "10%" }}>교사</th>
              {lessonInfo?.evaluation_type === "100점제" ? (
                <>
                  <th style={{ width: "11%", fontSize: "smaller" }}>
                    평가제
                    <br />
                    {
                      lessonSetting?.find((r) => r.item_code === "evalArea100")
                        ?.saved_setting
                    }
                  </th>
                </>
              ) : (
                <th style={{ width: "11%" }}>평가제</th>
              )}
              {lessonInfo?.evaluation_type === "100점제" &&
                lessonSetting
                  ?.find((r) => r.item_code === "evalArea100")
                  ?.detailed_setting?.map((setting, index) => (
                    <th key={index} style={{ fontSize: "smaller" }}>
                      가중
                      <br />
                      {setting}
                    </th>
                  ))}
            </tr>
            <tr>
              <td>
                {lessonInfo &&
                  lessonInfo.school_year &&
                  lessonInfo.termName &&
                  lessonInfo.school_year + "-" + lessonInfo.termName}
              </td>
              <td>{lessonInfo && lessonInfo.hierarchyOne}</td>
              <td>{lessonInfo && lessonInfo.hierarchyTwo}</td>
              <td>{lessonInfo && lessonInfo.hierarchyThree}</td>
              <td>{lessonInfo && lessonInfo.subject_name}</td>
              <td>
                {lessonInfo && Array.isArray(lessonInfo?.teachers)
                  ? lessonInfo?.teachers
                      ?.map((user) => user.tname.toString())
                      .join(",")
                  : ""}
              </td>
              <td>{lessonInfo && lessonInfo.evaluation_type}</td>
              {lessonInfo?.evaluation_type === "100점제" &&
                lessonSetting
                  ?.find((r) => r.item_code === "evalArea100")
                  ?.detailed_setting?.map((setting, index) => (
                    <td key={index}>{lessonInfo.eval_area_weights[setting]}</td>
                  ))}
            </tr>
          </tbody>
        </table>

        <form>
          <div className="d-flex" style={{ fontSize: "14px" }}>
            <div className="me-2 ms-2">
              <div
                className="text-center"
                style={{ fontWeight: "bold", marginBottom: "6px" }}
              >
                태그
              </div>
              <button
                onClick={saveChanges}
                className="btn btn-sm btn-primary"
                name={lessonInfo?.id}
                style={{ fontSize: "14px" }}
              >
                저장
              </button>
            </div>
            <div
              style={{ display: "flex", alignItems: "flex-end", gap: "5px" }}
            >
              {lessonInfo?.tags?.map((tag, index) => {
                const tagName = Object.keys(tag)[0];
                const optionValue =
                  selectedOptions.find(
                    (option) => Object.keys(option)[0] === tagName
                  )?.[tagName] ?? "";

                return (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className="text-center"
                      style={{ marginBottom: "5px" }}
                    >
                      {tagName}
                    </div>
                    <select
                      className="form-control p-1"
                      value={optionValue}
                      onChange={(e) =>
                        handleSelectChange(tagName, e.target.value)
                      }
                    >
                      <option value="1">매우약함</option>
                      <option value="2">약함</option>
                      <option value="3">보통</option>
                      <option value="4">강함</option>
                      <option value="5">매우강함</option>
                    </select>
                  </div>
                );
              })}
            </div>
          </div>
        </form>

        <UniDivider />
        {lessonInfo && lessonInfo.evaluation_type && (
          <RenderComponent
            evaluationType={lessonInfo.evaluation_type}
            lessonStudentsData={lessonStudentsData}
            setLessonStudentsData={setLessonStudentsData}
            lessonSetting={lessonSetting}
            updateLessonStudents={updateLessonStudents}
            updateLessonStudentsBulk={updateLessonStudentsBulk}
            deleteLessonStudents={deleteLessonStudents}
            lessonInfo={lessonInfo}
            evalItemsData={evalItemsData}
            schoolId={schoolId}
          />
        )}
      </div>
    </div>
  );
}

function RenderComponent({
  evaluationType,
  lessonStudentsData,
  lessonSetting,
  lessonInfo,
  setLessonStudentsData,
  updateLessonStudents,
  updateLessonStudentsBulk,
  deleteLessonStudents,
  evalItemsData,
  schoolId,
}) {
  switch (evaluationType) {
    case "평가항목별":
      return (
        <LessonStudentsItemsTable
          lessonStudentsData={lessonStudentsData}
          setLessonStudentsData={setLessonStudentsData}
          evaluationType={evaluationType}
          lessonSetting={lessonSetting}
          updateLessonStudents={updateLessonStudents}
          updateLessonStudentsBulk={updateLessonStudentsBulk}
          deleteLessonStudents={deleteLessonStudents}
          lessonInfo={lessonInfo}
          evalItemsData={evalItemsData}
        />
      );
    case "커스텀":
      return (
        <CustomTableSwitch
          lessonStudentsData={lessonStudentsData}
          setLessonStudentsData={setLessonStudentsData}
          evaluationType={evaluationType}
          lessonSetting={lessonSetting}
          updateLessonStudents={updateLessonStudents}
          updateLessonStudentsBulk={updateLessonStudentsBulk}
          deleteLessonStudents={deleteLessonStudents}
          lessonInfo={lessonInfo}
          evalItemsData={evalItemsData}
          schoolId={schoolId}
        />
      );
    default:
      return (
        <LessonStudentsTable
          lessonStudentsData={lessonStudentsData}
          setLessonStudentsData={setLessonStudentsData}
          evaluationType={evaluationType}
          lessonSetting={lessonSetting}
          updateLessonStudents={updateLessonStudents}
          updateLessonStudentsBulk={updateLessonStudentsBulk}
          deleteLessonStudents={deleteLessonStudents}
          lessonInfo={lessonInfo}
        />
      );
  }
}
