import React, { useEffect, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import useSettingEvalItems from "../../../../hooks/useSettingEvalItems";
import { useQueryClient } from "@tanstack/react-query";
import EmptyEvalItemTr from "./EmptyEvalItemTr";
import { validateObjectByFields } from "../../../../util/validate";
import { useUIContext } from "../../../../context/UIContext";
import { arrangeDataByHierarchy } from "../../../../util/specialCase";

export default function EvalItemTr({
  item,
  lessonSetting,
  addNewRow,
  setAddNewRow,
  hideAddBtn,
  setHideAddBtn,
  evalItemsData,
  setRows,
  setEvalItemsData,
  getEvalItemsByLessonId,
}) {
  const queryClient = useQueryClient();

  const { updateEvalItems, deleteEvalItems } = useSettingEvalItems();

  const { handleToastCenterTop } = useUIContext();

  const [updateRow, setUpdateRow] = useState(item);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [validateStyles, setValidateStyles] = useState({});

  useEffect(() => {
    if (!item.evaluation_type && lessonSetting) {
      const thisSetting = lessonSetting.find(
        (r) => r.item_code === "evalMethod"
      );
      const firstOption = thisSetting.detailed_setting[0]; // 첫 번째 설정
      setUpdateRow((prevLesson) => ({
        ...prevLesson,
        evaluation_type: firstOption,
      }));
    }
  }, [item.evaluation_type, lessonSetting]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setUpdateRow((updateRow) => ({ ...updateRow, [name]: value }));

    // 입력 요소가 textarea일 경우 높이 자동 조정
    if (type === "textarea") {
      const textarea = e.target;
      textarea.style.height = "auto"; // 높이를 자동으로 재설정
      textarea.style.height = `${textarea.scrollHeight}px`; // 스크롤 높이로 높이 설정
    }
  };

  const handleAddTr = () => {
    setHideAddBtn(true);
    setIsAddingRow(true);
    setAddNewRow((prev) => ({
      ...prev,
      hierarchy_level: updateRow.hierarchy_level + 1,
      content: "",
      evaluation_type: updateRow.evaluation_type,
      order: "",
      parent_id: updateRow.id,
    }));
  };

  const handleSave = () => {
    const fieldsToValidate = [
      { key: "content", type: "string", required: true, name: "평가내용" },
      {
        key: "evaluation_type",
        type: "string",
        required: true,
        name: "평가제",
      },
      { key: "order", type: "number", required: true, name: "순서" },
    ];

    setValidateStyles({});

    const errorObject = validateObjectByFields(updateRow, fieldsToValidate);
    const typeErrors = Object.values(errorObject.type || {});
    const requiredErrors = Object.values(errorObject.required || {});
    const allErrors = typeErrors.concat(requiredErrors);
    const errorMessageString = allErrors.join("\n");

    if (allErrors.length > 0) {
      const newStyles = {};
      Object.keys(errorObject.type).forEach((key) => (newStyles[key] = true));
      Object.keys(errorObject.required).forEach(
        (key) => (newStyles[key] = true)
      );
      setValidateStyles(newStyles);

      handleToastCenterTop(errorMessageString);
      return;
    }
    setIsSaving(true);

    const forWhere = {
      schoolid: updateRow.schoolid,
      lesson_id: updateRow.lesson_id,
    };

    updateEvalItems.mutate(updateRow, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsSaving(false);

        getEvalItemsByLessonId.mutate(forWhere, {
          onSuccess: (data) => {
            const processedData = arrangeDataByHierarchy([...data]);
            setEvalItemsData(processedData);
          },
          onError: (error) => {
            console.error(error); // 일단 콘솔에 에러를 출력합니다.
            console.error("위와 같은 에러가 났으니 고치삼");
            alert(
              "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                error.message
            );
          },
        });
      },
      onError: (error) => {
        setIsSaving(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleDelete = (e) => {
    const { name } = e.target;
    const { id } = updateRow;

    if (name === "delete") {
      setConfirmDelete(true);
    } else {
      const hasChild = evalItemsData.some((item) => item.parent_id === id);

      console.log("hasChild", hasChild);
      console.log("evalItemsData", evalItemsData);
      console.log("id", id);

      if (hasChild) {
        handleToastCenterTop("하위에 속한 그룹이 없어야 삭제할 수 있습니다.");
        return;
      }

      const forWhere = {
        schoolid: updateRow.schoolid,
        lesson_id: updateRow.lesson_id,
      };

      setIsDeleting(true);
      deleteEvalItems.mutate(id, {
        onSuccess: (successMessage) => {
          setIsDeleting(false);
          setConfirmDelete(false);
          queryClient.invalidateQueries(["evalItems"]);
          setRows((prevRows) => {
            // deletedId와 일치하지 않는 항목만 필터링
            const updatedRows = prevRows.filter((row) => row.id !== id);
            return updatedRows;
          });
          getEvalItemsByLessonId.mutate(forWhere, {
            onSuccess: (data) => {
              const processedData = arrangeDataByHierarchy([...data]);
              setEvalItemsData(processedData);
            },
            onError: (error) => {
              console.error(error); // 일단 콘솔에 에러를 출력합니다.
              console.error("위와 같은 에러가 났으니 고치삼");
              alert(
                "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
                  error.message
              );
            },
          });
        },
        onError: (error) => {
          setIsDeleting(false);
          setConfirmDelete(false);
          console.error(error); // 일단 콘솔에 에러를 출력합니다.
          console.error("위와 같은 에러가 났으니 고치삼");
          alert(error.message);
        },
      });
    }
  };

  return (
    <React.Fragment>
      <tr>
        <th scope="row" className="text-center">
          {updateRow?.hierarchy_level || ""}
        </th>
        <td
          className="changePaddings d-flex"
          style={{ paddingLeft: `${(updateRow?.hierarchy_level - 1) * 25}px` }}
        >
          <textarea
            className="form-control"
            name="content"
            value={updateRow?.content || ""}
            onChange={handleChange}
            rows={1}
            required
            style={{
              backgroundColor: validateStyles?.content ? "pink" : undefined,
            }}
          />
        </td>
        <td>
          <select
            className="select-like-span ms-2"
            value={updateRow?.evaluation_type || ""}
            name="evaluation_type"
            onChange={handleChange}
            required
            style={{
              backgroundColor: validateStyles?.evaluation_type
                ? "pink"
                : undefined,
            }}
          >
            {lessonSetting?.filter((r) => r.item_code === "evalMethod").length >
              0 &&
              lessonSetting
                ?.filter((r) => r.item_code === "evalMethod")[0]
                .detailed_setting.map((setting, index) => {
                  if (
                    setting !== "평가항목별" &&
                    setting !== "서술형" &&
                    setting !== "커스텀"
                  ) {
                    return (
                      <option key={index} value={setting}>
                        {setting}
                      </option>
                    );
                  }
                  return null;
                })}
          </select>
        </td>
        <td>
          <input
            type="number"
            className="input-like-span ms-3 ps-2"
            value={updateRow?.order || ""}
            name="order"
            onChange={handleChange}
            required
            style={{
              backgroundColor: validateStyles?.order ? "pink" : undefined,
            }}
          />
        </td>
        {updateRow?.hierarchy_level < 3 ? (
          <td className="text-center align-middle p-0 m-0">
            {!hideAddBtn && (
              <MdAddCircleOutline
                className="p-0 m-0"
                role="button"
                size={25}
                color="blue"
                onClick={(updateRow) => handleAddTr(updateRow)}
              />
            )}
          </td>
        ) : (
          <td className="text-center align-middle p-0 m-0"></td>
        )}
        <td>
          {isDeleting ? (
            <button type="button" className="btn btn-sm btn-danger" disabled>
              삭제중..
            </button>
          ) : confirmDelete ? (
            <React.Fragment>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                name="confirm"
                onClick={handleDelete}
              >
                실행
              </button>
              <button
                type="button"
                onClick={() => setConfirmDelete(false)}
                className="btn btn-sm btn-secondary ms-1"
              >
                취소
              </button>
            </React.Fragment>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-warning"
              name="delete"
              onClick={handleDelete}
            >
              삭제
            </button>
          )}
          {isSaving ? (
            <button className="btn btn-sm btn-primary ms-1" disabled>
              저장중
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSave}
              className="btn btn-sm btn-primary ms-1"
            >
              저장
            </button>
          )}
        </td>
      </tr>
      {isAddingRow && (
        <EmptyEvalItemTr
          setHideAddBtn={setHideAddBtn}
          setIsAddingRow={setIsAddingRow}
          addNewRow={addNewRow}
          lessonSetting={lessonSetting}
          setAddNewRow={setAddNewRow}
          setEvalItemsData={setEvalItemsData}
          getEvalItemsByLessonId={getEvalItemsByLessonId}
        />
      )}
    </React.Fragment>
  );
}
