import React, { useEffect, useState } from "react";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import useRegistrationInfo from "../../hooks/useRegistrationInfo";
import { useLocation } from "react-router-dom";
import { generateRegistrationRows } from "../../util/dealingArrObj";

export default function RegistrationVariousRecordInputTable({
  organSettingInfo,
  inputData,
  studentInputData = [],
  handleSaveState,
  handleSaveClick,
  handleSaveAll,
  savedRows,
  savedBulk,
  addfontSize = "",
  addMinWidth = "",
}) {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
  };

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo(conditions);

  //필터된 학생들 정보를 나오게 하려고
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (organSettingInfo.schoolYear) {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("settingData", settingData);

        //사실 Mui dataGrid row용으로 만든 건데, 유용해서 계속 쓰자.
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          organSettingInfo.schoolYear,
          organSettingInfo.organPathForSort
        );
        // console.log("sortedRows", sortedRows);
        setRows(sortedRows);
      }
    }

    //여기 dependency가 있어서 schoolYear와 organPathForSort 필터에 맞게 필터되어서 배포된다.
  }, [
    settingData,
    organSettingInfo.schoolYear,
    registrationInfoData,
    organSettingInfo.organPathForSort,
  ]);

  const calculateStyle = (baseStyle, addValue) => {
    const baseSize = parseFloat(baseStyle) || 0;
    const addSize = parseFloat(addValue) || 0;
    return `${baseSize + addSize}px`;
  };

  function renderInput(r, row, value, handleSaveState) {
    switch (r.inputs) {
      case "select":
        return (
          <select
            className="select-like-span"
            onChange={(e) => handleSaveState(e, row.username, r.id)}
            value={value}
            name={r.name}
          >
            {r.options &&
              r.options.map((option, index) => (
                <option key={`${index}-${row.id}`} value={option?.value || ""}>
                  {option?.label || ""}
                </option>
              ))}
          </select>
        );
      case "textarea":
        return (
          <textarea
            className="input-like-span"
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, r.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
            rows={r?.options[0]?.row || 5}
            style={{ width: `${r?.options[0]?.width || 200}px` }}
          />
        );
      case "empty":
        return value ? value : "";
      case "text":
      default:
        return (
          <input
            className="input-like-span"
            type={r.inputs || "text"}
            placeholder={r.placeholder || ""}
            required={r.required || false}
            onChange={(e) => handleSaveState(e, row.username, r.id)}
            value={value}
            name={r.name}
            disabled={r.disabled}
          />
        );
    }
  }

  return (
    <table
      className="table table-bordered text-center"
      style={{ fontSize: "13px" }}
    >
      <thead>
        <tr className="table-secondary p-1">
          <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
            과정
          </th>
          <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
            학년
          </th>
          <th className="p-1 align-middle" style={{ minWidth: "50px" }}>
            반
          </th>
          <th className="p-1 align-middle" style={{ minWidth: "60px" }}>
            이름
          </th>
          {inputData &&
            inputData.map((r) => (
              <th
                className="align-middle p-0"
                style={{
                  minWidth: calculateStyle("60px", addMinWidth),
                  fontSize: calculateStyle("11px", addfontSize),
                }}
                key={`th-${r.id}`}
              >
                {r.title} <br />
                {r.description ? `(${r.description})` : ""}
              </th>
            ))}
          <th className="align-middle p-1" style={{ minWidth: "72px" }}>
            <button
              className="btn btn-sm btn-primary p-0 pe-1 ps-1"
              style={{ fontSize: "13px" }}
              onClick={() => {
                handleSaveAll();
              }}
            >
              {savedBulk ? "완료" : "모두저장"}
            </button>
          </th>
        </tr>
      </thead>
      <tbody style={{ fontSize: "13px" }} className="align-middle">
        {rows?.map((row) => (
          <tr key={row.id}>
            <td className="p-1">{row[1]}</td>
            <td className="p-1">{row[2]}</td>
            <td className="p-1">{row[3]}</td>
            <td className="p-1">{row.fullname}</td>
            {inputData &&
              inputData.map((r) => {
                const studentData = studentInputData.find(
                  (s) => s.username === row.username
                );
                const value = studentData?.value_object?.[r.name] ?? "";

                return (
                  <td key={`th-${r.id}-${row.id}`} className="p-1">
                    {renderInput(r, row, value, handleSaveState)}
                  </td>
                );
              })}
            <td className="p-1">
              <button
                className="btn btn-sm p-0 pe-1 ps-1 btn-primary"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  handleSaveClick(row);
                }}
              >
                {savedRows.includes(row.username) ? "완료" : "저장"}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
