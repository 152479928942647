// UIContext.js
import React, { createContext, useContext, useState } from "react";

const UIContext = createContext();

export const UIContextProvider = ({ children }) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [toastCenterTop, setToastCenterTop] = useState({
    visible: false,
    message: "",
    duration: 3000,
  });

  const [alertModalState, setAlertModalState] = useState({
    visible: false,
    message: "",
  });

  // 토스트 메시지를 표시하거나 숨기는 함수
  const handleToastCenterTop = (message, duration = 3000) => {
    setToastCenterTop({ visible: !!message, message, duration });
    if (message) {
      setTimeout(() => {
        setToastCenterTop({ visible: false, message: "", duration: 3000 });
      }, duration);
    }
  };

  return (
    <UIContext.Provider
      value={{
        isLoadingModal,
        setIsLoadingModal,
        toastCenterTop,
        handleToastCenterTop,
        alertModalState,
        setAlertModalState,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => useContext(UIContext);
