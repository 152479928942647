import React from "react";
import { MdHelpOutline } from "react-icons/md";

export default function HelpHyperLink({ link, size = 21 }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <MdHelpOutline size={size} color="#008080" title="사용법 영상 연결" />
    </a>
  );
}
