import React from "react";
import UnitTr from "./UnitTr";
import { MdAdd } from "react-icons/md";

/*
이 페이지 초기에 데이터 가져온 것으로 바로 나옴
CreateNewYear에 학년도 선택과 이전 설정 불러오기 내용에 따라 데이터가 달라지니까 그에 맞춰서 여기도 변함
단계, 분류그룹, 단계이름, 상위단계, 순서, 설명을 모두 수정하여 저장 또는 삭제 가능
*/

function OrganizationTable({
  settingData,
  setIsUpLoading,
  isModalOpen,
  setModalOpen,
  setUnitInfo,
  schoolYear,
  hideIcons,
  setHrTeacherModalOpen,
}) {
  const handleModalClick = () => {
    setUnitInfo(settingData[0]);
    setModalOpen(true);
  };

  return (
    <div className="mt-3 table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ width: "8%" }}>
              단계
            </th>
            <th scope="col" style={{ width: "35%" }}>
              분류 그룹 + 단계 이름
            </th>
            <th scope="col" style={{ width: "10%" }}>
              하위추가
            </th>
            <th scope="col" style={{ width: "7%" }}>
              순서
            </th>
            <th scope="col" style={{ width: "19%" }}>
              담임교사
            </th>
            <th scope="col" style={{ width: "9%" }}></th>
            <th scope="col" style={{ width: "12%" }}>
              저장/삭제
            </th>
          </tr>
        </thead>
        <tbody id="registrationSettingTbody">
          <tr className="resultRow">
            <th scope="row">
              <select
                className="hierarchyLevel select-like-span"
                defaultValue="0"
                disabled={true}
              >
                <option value="0">0</option>
              </select>
            </th>
            <td className="changePaddings ps-0">
              <span className="group_name">
                {settingData && settingData[0]?.group_name}
              </span>
            </td>
            <td className="text-center align-middle p-0 m-0">
              {!hideIcons && (
                <MdAdd
                  className="p-0 m-0"
                  role="button"
                  size={25}
                  onClick={handleModalClick}
                />
              )}
            </td>
            <td></td>
            <td>
              <input
                type="text"
                disabled
                value={schoolYear + "학년도"}
                className="input-like-span memo"
              />
            </td>
            <td></td>
            <td></td>
          </tr>
          {settingData?.length > 1 &&
            settingData.map(
              (unit) =>
                unit.hierarchy_level !== 0 && (
                  <UnitTr
                    key={unit.id}
                    unit={unit}
                    setIsUpLoading={setIsUpLoading}
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    setUnitInfo={setUnitInfo}
                    settingData={settingData}
                    hideIcons={hideIcons}
                    setHrTeacherModalOpen={setHrTeacherModalOpen}
                  />
                )
            )}
        </tbody>
      </table>
    </div>
  );
}

export default OrganizationTable;
