import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HttpClient from "../network/http";
import { fetchCsrfToken } from "../context/AuthContext";
import VariousStudentRecordsService from "../service/variousStudentRecords";

const baseURL = process.env.REACT_APP_BASE_URL;
const httpClient = new HttpClient(baseURL, () => fetchCsrfToken());
const variousStudentRecordsService = new VariousStudentRecordsService(
  httpClient
);

export default function useVariousStudentRecords(conditions) {
  const queryClient = useQueryClient();
  const variousStudentRecordsQuery = useQuery(
    ["variousStudentRecords", conditions],
    () => variousStudentRecordsService.findAllVariousStudentRecords(conditions),
    {
      enabled:
        !!conditions?.schoolid &&
        !!conditions?.school_year_id &&
        !!conditions?.record_list_id,
      staleTime: 1000 * 60 * 1, //1분,
      // onSuccess: (data) => {
      //   console.log("variousStudentRecords Query Data:", data);
      // },
    }
  );

  const getVariousStudentDataWithRegi = useMutation((forWhere) =>
    variousStudentRecordsService.getVariousStudentDataWithRegi(forWhere)
  );

  const getVariousStudentRecordByUserId = useMutation((forWhere) =>
    variousStudentRecordsService.getVariousStudentRecordByUserId(forWhere)
  );

  const getAllVariousStudentRecordByUserId = useMutation((forWhere) =>
    variousStudentRecordsService.getAllVariousStudentRecordByUserId(forWhere)
  );

  const createOrUpdateVariousStudentRecords = useMutation(
    (data) =>
      variousStudentRecordsService.createOrUpdateVariousStudentRecords(data),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["variousStudentRecords", conditions]),
      onError: (error) => {
        console.error("Error creating:", error);
      },
    }
  );

  const createOrUpdateVariousStudentRecordsBulk = useMutation(
    (data) =>
      variousStudentRecordsService.createOrUpdateVariousStudentRecordsBulk(
        data
      ),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["variousStudentRecords", conditions]),
      onError: (error) => {
        console.error("Error creatingBulk:", error);
      },
    }
  );

  const deleteVariousStudentRecords = useMutation(
    (data) => variousStudentRecordsService.deleteVariousStudentRecords(data),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["variousStudentRecords", conditions]);
      },
    }
  );

  const deleteVariousStudentRecordOne = useMutation(
    (id) => variousStudentRecordsService.deleteVariousStudentRecordOne(id),
    {
      onSuccess: () => {
        // Refetch the query first
        queryClient.invalidateQueries(["variousStudentRecords", conditions]);
      },
    }
  );

  return {
    variousStudentRecordsQuery,
    getVariousStudentDataWithRegi,
    getVariousStudentRecordByUserId,
    getAllVariousStudentRecordByUserId,
    createOrUpdateVariousStudentRecords,
    createOrUpdateVariousStudentRecordsBulk,
    deleteVariousStudentRecords,
    deleteVariousStudentRecordOne,
  };
}
