// AlertModal.js
import React from "react";
import styles from "./CSS/AlertModal.module.css";
import { useUIContext } from "../context/UIContext";

const AlertModal = () => {
  const { alertModalState, setAlertModalState } = useUIContext();

  if (!alertModalState.visible) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <p>{alertModalState.message}</p>
          <button
            onClick={() =>
              setAlertModalState({
                visible: false,
                message: "",
              })
            }
            className={styles.closeButton}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
