/**
 * 글자수 세기 다
 * calculateTextNumber - Calculates the byte length of a given text,
 * considering the byte size of each character (e.g., 2 bytes for Korean characters),
 * and estimates the character count from the byte length.
 * @param {string} text - The text to calculate the byte length and estimate the character count of.
 * @returns {number} - The estimated character count.
 */
export function calculateTextNumber(text) {
  let byteLength = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    if (char.charCodeAt(0) > 0x7f) {
      byteLength += 2; // 한글 등 2 Byte 문자
    } else {
      byteLength += 1; // 영문 등 1 Byte 문자
    }
  }

  return Math.ceil(byteLength / 2); // 바이트 길이를 2로 나누어 대략적인 문자 수를 반환
}
