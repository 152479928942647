export default class VariousRecordListsService {
  constructor(http) {
    this.http = http;
  }

  async findAllVariousRecordLists(conditions = {}) {
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    return this.http.fetch(`/api/variousrecordlists/readall?${queryParams}`, {
      method: "GET",
    });
  }

  // async createVariousRecordLists(data) {
  //   return this.http.fetch(`/api/variousrecordlists/create`, {
  //     method: "POST",
  //     body: JSON.stringify(data),
  //   });
  // }

  // async updateVariousRecordLists(data) {
  //   return this.http.fetch(`/api/variousrecordlists/update`, {
  //     method: "PUT",
  //     body: JSON.stringify(data),
  //   });
  // }

  // async deleteVariousRecordLists(data) {
  //   return this.http.fetch(`/api/variousrecordlists/delete`, {
  //     method: "DELETE",
  //     body: JSON.stringify(data),
  //   });
  // }
}
