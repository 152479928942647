import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//페이지 연결
import Dashboard from "./pages/dashboard/Dashboard";
import Developer from "./pages/developer/Developer";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";
import DevSchools from "./pages/developer/components/DevSchools";
import BasicInfo from "./pages/basicInfo/BasicInfo";
import NotLogin from "./pages/developer/components/NotLogin";
import AuthorityMaking from "./pages/developer/subpages/AuthorityMaking";
import TeacherInfo from "./pages/teacherinfo/TeacherInfo";
import MyInfo from "./pages/myInfo/MyInfo";
import OrganizationSetting from "./pages/organizationSetting/OrganizationSetting";
import RegistrationInfo from "./pages/registrationInfo/RegistrationInfo";
import Attendance from "./pages/attendance/Attendance";
import StudentInfo from "./pages/studentInfo/StudentInfo";
import Counseling from "./pages/counseling/Counseling";
import CreateActivities from "./pages/createActivities/CreateActivities";
import TagSetting from "./pages/tagSetting/TagSetting";
import DoActivities from "./pages/doActivities/DoActivities";
import PeriodSetting from "./pages/periodSetting/PeriodSetting";
import AttendanceStatistics from "./pages/dataAndStatistics/attendanceStatistics/AttendanceStatistics";
import LessonSetting from "./pages/lessonRelated/lessonSetting/LessonSetting";
import CreateLessons from "./pages/lessonRelated/createLessons/CreateLessons";
import DoLessons from "./pages/lessonRelated/doLessons/DoLessons";
import VariousRecords from "./pages/variousRecords/VariousRecords";
import SchoolLifeRecords from "./pages/schoolLifeRecords/SchoolLifeRecords";
import ReportCard from "./pages/printingRelated/reportCard/ReportCard";
import SchoolLifeRecordForm from "./pages/printingRelated/schoolLifeRecordForm/SchoolLifeRecordForm";
import OfficialDocuments from "./pages/printingRelated/officialDocuments/OfficialDocuments";
import FormsAndTemplates from "./pages/printingRelated/formsAndTemplates/FormsAndTemplates";
import SelfReflection from "./pages/studentPages/selfReflection/SelfReflection";
import StudentSelfReflection from "./pages/teacherPages/studentSelfReflection/StudentSelfReflection";
import Groups from "./pages/adminRelated/groups/Groups";
import GroupsAuthorities from "./pages/adminRelated/groupsAuthorities/GroupsAuthorities";
import DataMigration from "./pages/developer/subpages/DataMigration/DataMigration";

const queryClient = new QueryClient();
const router = createBrowserRouter([
  {
    path: "/developer",
    element: <Developer />,
    errorElement: <NotFound />,
    children: [
      { path: "/developer", element: <NotLogin /> },
      { path: "/developer/devschools", element: <DevSchools /> },
      { path: "/developer/authoritymaking", element: <AuthorityMaking /> },
      { path: "/developer/data-migration", element: <DataMigration /> },
    ],
  },
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { path: "/login", element: <Login /> },
      { path: "/:schoolid/:userrole/dashboard", element: <Dashboard /> },
      { path: "/:schoolid/teacher/basicinfo", element: <BasicInfo /> },
      { path: "/:schoolid/teacher/teacherinfo", element: <TeacherInfo /> },
      { path: "/:schoolid/:userrole/myinfo", element: <MyInfo /> },
      {
        path: "/:schoolid/teacher/organizationsetting",
        element: <OrganizationSetting />,
      },
      {
        path: "/:schoolid/teacher/registrationinfo",
        element: <RegistrationInfo />,
      },
      {
        path: "/:schoolid/teacher/attendance",
        element: <Attendance />,
      },
      {
        path: "/:schoolid/teacher/studentinfo",
        element: <StudentInfo />,
      },
      {
        path: "/:schoolid/teacher/counseling",
        element: <Counseling />,
      },
      {
        path: "/:schoolid/teacher/createactivities",
        element: <CreateActivities />,
      },
      {
        path: "/:schoolid/teacher/tagsetting",
        element: <TagSetting />,
      },
      {
        path: "/:schoolid/teacher/doactivities",
        element: <DoActivities />,
      },
      {
        path: "/:schoolid/teacher/periodsetting",
        element: <PeriodSetting />,
      },
      {
        path: "/:schoolid/teacher/attendancestatistics",
        element: <AttendanceStatistics />,
      },
      {
        path: "/:schoolid/teacher/lessonsetting",
        element: <LessonSetting />,
      },
      {
        path: "/:schoolid/teacher/createlessons",
        element: <CreateLessons />,
      },
      {
        path: "/:schoolid/teacher/doLessons",
        element: <DoLessons />,
      },
      {
        path: "/:schoolid/teacher/variousrecords",
        element: <VariousRecords />,
      },
      {
        path: "/:schoolid/teacher/schoolliferecords",
        element: <SchoolLifeRecords />,
      },
      {
        path: "/:schoolid/teacher/reportcard",
        element: <ReportCard />,
      },
      {
        path: "/:schoolid/teacher/schoolliferecord-form",
        element: <SchoolLifeRecordForm />,
      },
      {
        path: "/:schoolid/teacher/official-documents",
        element: <OfficialDocuments />,
      },
      {
        path: "/:schoolid/teacher/forms-and-templates",
        element: <FormsAndTemplates />,
      },
      {
        path: "/:schoolid/teacher/student-self-reflection",
        element: <StudentSelfReflection />,
      },
      {
        path: "/:schoolid/student/self-reflection",
        element: <SelfReflection />,
      },
      {
        path: "/:schoolid/teacher/groups",
        element: <Groups />,
      },
      {
        path: "/:schoolid/teacher/groups-authorities",
        element: <GroupsAuthorities />,
      },
    ],
  },
]);

const handleWheel = (event) => {
  if (document.activeElement.type === "number") {
    event.preventDefault();
  }
};

window.addEventListener("wheel", handleWheel, { passive: false });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      {process.env.REACT_APP_BASE_URL === "http://localhost:8080/" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </React.StrictMode>
);

// 서비스 워커 등록
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      registration.waiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          if (window.confirm("새로운 버전이 있습니다. 새로고침하시겠습니까?")) {
            window.location.reload();
          }
        }
      });
    }
  },
});

// Cleanup event listener on unmount
window.addEventListener("beforeunload", () => {
  window.removeEventListener("wheel", handleWheel);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
