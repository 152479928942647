export default class DeveloperService {
  constructor(http) {
    this.http = http;
  }

  async login(adminid, password) {
    return this.http.fetch("/api/developer/login", {
      method: "POST",
      body: JSON.stringify({ adminid, password }),
    });
  }

  async me() {
    return this.http.fetch("/api/developer/me", {
      method: "GET",
    });
  }

  async logout() {
    return this.http.fetch("/api/developer/logout", {
      method: "POST",
    });
  }

  async csrfToken() {
    const resp = await this.http.fetch("/api/developer/csrf-token", {
      method: "GET",
    });
    return resp.csrfToken;
  }

  async readall() {
    return this.http.fetch(`/api/developer/readall`, {
      method: "GET",
    });
  }

  async findByConditions(conditions = {}) {
    // console.log("find dev conditions", conditions);
    const queryParams = Object.entries(conditions)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
    // console.log("queryParams", queryParams);
    return this.http.fetch(`/api/developer/readone?${queryParams}`, {
      method: "GET",
    });
  }

  async registerSchool(info) {
    return this.http.fetch(`/api/developer/register`, {
      method: "POST",

      body: JSON.stringify({
        schoolid: info.schoolid,
        schoolname: info.schoolname,
        schooladmin: info.schooladmin,
        adminid: info.adminid,
        password: info.password,
        status: info.status,
        usergroup: info.usergroup,
        startdate: info.startdate,
      }),
    });
  }

  async deleteSchool(id) {
    return this.http.fetch(`/api/developer/delete`, {
      method: "DELETE",

      body: JSON.stringify({ id }),
    });
  }

  async updateSchool(info) {
    return this.http.fetch(`/api/developer/update`, {
      method: "PUT",

      body: JSON.stringify({
        id: info.id,
        schoolname: info.schoolname,
        schooladmin: info.schooladmin,
        password: info.password,
        status: info.status,
        usergroup: info.usergroup,
        startdate: info.startdate,
      }),
    });
  }

  //권한 만들기 authoritymaking 페이지 함수들

  async readAllAuthorities() {
    return this.http.fetch(`/api/authoritymaking/readall`, {
      method: "GET",
    });
  }

  async createAuthorities(authorityInfo) {
    return this.http.fetch(`/api/authoritymaking/create`, {
      method: "POST",

      body: JSON.stringify(authorityInfo),
    });
  }
}
