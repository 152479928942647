import React, { useState } from "react";
import { findDuplicateData } from "../../../util/dealingArrObj";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";

export default function UpdateRegiModal({
  setModalOpen,
  updateRegi,
  setUpdateRegi,
  idsForKey,
  filteredSetting,
  filterModal,
  setFilterModal,
  setIsUpLoading,
}) {
  const [isSaved, setIsSaved] = useState(false);

  const { updateRegistrationInfo } = useRegistrationInfo();

  const handleModalChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    // const selectedOptionText = e.target.selectedOptions[0].text;

    const keys = Object.keys(idsForKey);
    const maxKey = Math.max(...keys.map(Number));

    if (maxKey.toString() === name.toString()) {
      const organPath =
        e.target.selectedOptions[0].getAttribute("data-name") + "/" + value;

      setUpdateRegi((updateRegi) => ({
        ...updateRegi,
        organization_path: organPath,
      }));
    } else {
      setUpdateRegi((updateRegi) => ({ ...updateRegi, [name]: value }));
    }

    if (!isNaN(Number(name))) {
      setFilterModal((prevFilter) => {
        const newFilter = { ...prevFilter, [name]: value };
        //   console.log("newFilter", newFilter);
        Object.keys(newFilter).forEach((key) => {
          if (Number(key) > Number(name)) {
            delete newFilter[key];
          }
        });
        // console.log("newFilter", newFilter);
        return newFilter;
      });
    }

    // console.log("updateRegi", updateRegi);
    // console.log("filterModal", filterModal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsUpLoading(true);

    const isDuplicate = findDuplicateData(filteredSetting, updateRegi, [
      "school_year",
      "parent_id",
      "group_name",
    ]);

    if (isDuplicate) {
      alert("같은 분류그룹 내에서 같은 그룹이름으로는 생성할 수 없습니다.");
      return;
    }

    updateRegistrationInfo.mutate(updateRegi, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
          setModalOpen(false);
        }, 1000);
        setIsUpLoading(false);
      },
      onError: (error) => {
        setIsUpLoading(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <>
      <div className="modal-container">
        <div
          className="modalForUnit"
          style={{ width: "600px", maxHeight: "700px", overflowY: "auto" }}
        >
          <div className="modal-header">
            <h3>재적정보수정</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-content">
              {filteredSetting?.length > 0 &&
                Object.keys(idsForKey).map((item, index) => {
                  //   console.log(item);
                  if (item === "0") return null;
                  return (
                    <div className="row mb-1" key={item}>
                      <div className="col-3">
                        <label htmlFor="group_name" className="col-form-label">
                          {idsForKey[item][0]}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          key={item}
                          name={item}
                          className="form-control"
                          onChange={handleModalChange}
                          defaultValue={filterModal[item]}
                        >
                          <option value="" data-name="">
                            {idsForKey[item][0]}
                          </option>
                          {filteredSetting
                            .filter(
                              (r) =>
                                r.parent_id ===
                                Number(
                                  filterModal[(Number(item) - 1).toString()]
                                )
                            )
                            .sort((a, b) => {
                              const aValue = a["order"];
                              const bValue = b["order"];
                              if (
                                typeof aValue === "number" &&
                                typeof bValue === "number"
                              ) {
                                // 숫자인 경우
                                return aValue - bValue;
                              } else {
                                // 문자열인 경우
                                return aValue.localeCompare(bValue);
                              }
                            })
                            .map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                data-name={option.organization_path}
                              >
                                {option.group_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  );
                })}

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="fullname" className="col-form-label">
                    이름<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="fullname"
                    className="form-control"
                    value={updateRegi?.fullname || ""}
                    disabled
                  />
                </div>
              </div>
              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="username" className="col-form-label">
                    사용자아이디<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="username"
                    className="form-control"
                    value={updateRegi?.username || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="studentno" className="col-form-label">
                    번호
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="studentno"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.studentno}
                  />
                </div>
              </div>

              {/* <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="teacher" className="col-form-label">
                    담임이름
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="teacher"
                    className="form-control"
                    onChange={handleTeacherNameChange}
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label
                    htmlFor="hr_teacher_username"
                    className="col-form-label"
                  >
                    교사아이디<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8">
                  <select
                    required
                    name="hr_teacher_username"
                    className="form-control"
                    onChange={handleModalChange}
                  >
                    <option value="">선택</option>
                    {filteredTeachers.map((user) => (
                      <option key={user.id} value={user.fullname}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="tname" className="col-form-label">
                    배정담임<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-8 border text-nowrap m-1 p-1 ms-3">
                  {updateRegi?.hr_teacher_username?.map((user, index) => (
                    <span key={index}>
                      {user.tname}
                      <MdOutlineCancel
                        role="button"
                        onClick={() => {
                          handleClick(user.username);
                        }}
                      />
                    </span>
                  ))}
                </div>
              </div> */}

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="status" className="col-form-label">
                    상태
                  </label>
                </div>
                <div className="col-8">
                  <select
                    required
                    name="status"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.status}
                  >
                    <option value="재학중">재학중</option>
                    <option value="비재학중">비재학중</option>
                  </select>
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="lastattended" className="col-form-label">
                    마지막등교일
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="date"
                    name="lastattended"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.lastattended}
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="attendance_remark" className="col-form-label">
                    출석특기사항
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="attendance_remark"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.attendance_remark}
                  />
                </div>
              </div>

              <div className="row mb-1">
                <div className="col-3">
                  <label htmlFor="future_plan" className="col-form-label">
                    진로
                  </label>
                </div>
                <div className="col-8">
                  <input
                    type="text"
                    name="future_plan"
                    className="form-control"
                    onChange={handleModalChange}
                    defaultValue={updateRegi?.future_plan}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                취소
              </button>
              {isSaved ? (
                <button type="submit" className="btn btn-primary" disabled>
                  저장완료
                </button>
              ) : (
                <button type="submit" className="btn btn-primary">
                  저장하기
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
