import React from "react";

/*
various_record_inputs 에서 가져오는데 options에서 width가 input의 넓이를 담당한다.

*/

/*
various_record_inputs 받아서 input들을 쭉 늘어두고 (깔끔하게 하자.)
handleCreateInputChange 함수 받아서, 데이터 state에 저장했다가
handleCreateAction 함수 받아서, 누르면 서버로 create하고
일단 이정도면 기본이 될 거다.
*/

export default function AddInputsRow({
  inputData,
  handleCreateInputChange,
  handleCreateAction,
  dataToCreate,
  styleClass = "form-control",
  btnStyleClass = "btn btn-primary",
  entireClass = "col-auto d-flex align-items-center",
}) {
  //라벨을 달아야겠다.

  function renderInput(r, value, handleCreateInputChange) {
    switch (r.inputs) {
      case "select":
        return (
          <div style={{ width: `${r?.options?.[0]?.width || 100}px` }}>
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <select
              className={styleClass}
              onChange={(e) => handleCreateInputChange(e)}
              value={dataToCreate?.r?.name || ""}
              name={r.name || ""}
            >
              {r.options &&
                r.options.map((option, index) => (
                  <option key={`${index}-${r.id}`} value={option?.value || ""}>
                    {option?.label || ""}
                  </option>
                ))}
            </select>
          </div>
        );
      case "textarea":
        return (
          <div>
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <textarea
              className={styleClass}
              placeholder={r.placeholder || ""}
              required={r.required || false}
              onChange={(e) => handleCreateInputChange(e)}
              value={value}
              name={r.name}
              disabled={r.disabled}
              rows={r?.options[0]?.row || 5}
              style={{ width: `${r?.options[0]?.width || 200}px` }}
            />
          </div>
        );
      case "empty":
        return (
          <div
            style={{ width: `${r?.options?.[0]?.width || 100}px` }}
            className="text-center"
          >
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <br />
            <span className="text-center">{value && value}</span>
          </div>
        );
      case "text":
        return (
          <div style={{ width: `${r?.options?.[0]?.width || 100}px` }}>
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <input
              className={styleClass}
              type="text"
              placeholder={r.placeholder || ""}
              required={r.required || false}
              onChange={(e) => handleCreateInputChange(e)}
              value={value}
              name={r.name}
              disabled={r.disabled}
            />
          </div>
        );
      case "number":
        return (
          <div style={{ width: `${r?.options?.[0]?.width || 100}px` }}>
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <input
              className={styleClass}
              type="number"
              placeholder={r.placeholder || ""}
              required={r.required || false}
              onChange={(e) => handleCreateInputChange(e)}
              value={value}
              name={r.name}
              disabled={r.disabled}
            />
          </div>
        );
      case "date":
        return (
          <div style={{ width: `${r?.options?.[0]?.width || 120}px` }}>
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <input
              className={styleClass}
              type="date"
              placeholder={r.placeholder || ""}
              required={r.required || false}
              onChange={(e) => handleCreateInputChange(e)}
              value={value}
              name={r.name}
              disabled={r.disabled}
            />
          </div>
        );
      default:
        return (
          <div style={{ width: `${r?.options?.[0]?.width || 100}px` }}>
            {r.title && (
              <label htmlFor={r.name} className="form-label ms-2 fw-bold">
                {r.title}
              </label>
            )}
            <input
              className={styleClass}
              type="text"
              placeholder={r.placeholder || ""}
              required={r.required || false}
              onChange={(e) => handleCreateInputChange(e)}
              value={value}
              name={r.name}
              disabled={r.disabled}
            />
          </div>
        );
    }
  }

  return (
    <form className="g-2" onSubmit={handleCreateAction}>
      <div className="row">
        {inputData &&
          inputData.map((r, index) => {
            const value = dataToCreate[r.name] || "";
            return (
              <div key={index} className={entireClass}>
                {renderInput(r, value, handleCreateInputChange)}
              </div>
            );
          })}
        <div className="col-auto d-flex align-items-center">
          <button className={btnStyleClass} name="createData">
            추가
          </button>
        </div>
      </div>
    </form>
  );
}
